import { Newsletter } from '@informaplc/gs-ps-eln-fe'
import { FC, useState } from 'react'

import { EventType, GTM, RegistrationStatus } from '@scm/tag-manager'
import { Typography } from '@scm/ui-component-typography'
import { Bem } from '@scm/ui-core'
import { ClassName } from '@scm/ui-tailwind'

import { NewsletterUIProps } from './type'
import { selectors } from '@scm/redux-store'

const newsletter = Bem('SignupNewsletter')

export const NewsletterUI: FC<NewsletterUIProps> = ({
  onClose,
  newsletterId,
  newsletterTag,
  subBrandId,
}) => {
  const {
    user,
    sessionType,
    accessToken,
    authFlow,
    platformServiceBaseUrl,
    clientIdForELN,
  } = selectors.useAuth()
  const sendEvent = GTM.hooks.useSendEvent()
  const {
    newsletterSignupPromo: { elnConfig },
  } = selectors.useConfig()
  const [title, setTitle] = useState(
    elnConfig?.newsletter.subscribeForm.title || 'Subscribe to Our Newsletters',
  )

  const [subtitle, setSubtitle] = useState(
    elnConfig?.newsletter.subscribeForm.subtitle,
  )

  const onSuccessSubscribe = () => {
    sendEvent({
      event: EventType.NEWSLETTER,
      data: {
        status: RegistrationStatus.SUCCESS,
        capture_tool: 'ELN',
        ...(user?.id ? { user_id: user.id } : {}),
      },
      _clear: true,
    })

    if (elnConfig?.newsletter?.subscribeConfirm?.title) {
      setTitle(elnConfig?.newsletter.subscribeConfirm.title)
    }

    if (elnConfig?.newsletter.subscribeConfirm.subtitle) {
      setSubtitle(elnConfig?.newsletter.subscribeConfirm.subtitle)
    }
  }

  return (
    <>
      <div className={newsletter('Header')}>
        <Typography
          variant="h2"
          primary={true}
          className={newsletter('HeaderTitle') as ClassName}
          automationId="newsletter_headerTitle"
        >
          {title}
        </Typography>
        <Typography
          variant="body-small"
          className={newsletter('HeaderSubtitle') as ClassName}
          primary={false}
          automationId="newsletter_headerSubTitle"
        >
          {subtitle}
        </Typography>
      </div>

      {sessionType !== 'unauthenticated' && (
        <Newsletter
          key={accessToken}
          platformServiceBaseUrl={platformServiceBaseUrl}
          newsletterId={newsletterId}
          newsletterTag={newsletterTag}
          authorization={accessToken as string}
          clientId={clientIdForELN}
          context={{
            isUserRegistrationFlow: Boolean(authFlow === 'register'),
          }}
          // type iirisUser conflict in here, so temporarily use any
          iirisUserProfile={user as any}
          returnButtonFunction={onClose}
          subBrandId={subBrandId}
          onSuccessSubscribe={onSuccessSubscribe}
          classNames={{
            base: {
              h1: newsletter('BaseHeader'),
              p: newsletter('BaseText'),
              span: newsletter('BaseSpan'),
              a: newsletter('BaseLink'),
              button: newsletter('BaseButton'),
              form: newsletter('BaseForm'),
              label: newsletter('BaseLabel'),
              input: newsletter('BaseInput'),
              checkbox: newsletter('BaseCheckbox'),
              radio: newsletter('BaseRadio'),
              select: newsletter('BaseSelect'),
              option: newsletter('BaseOption'),
            },
            formLayout: {
              container: newsletter('Content'),
              formContainer: newsletter('FormContainer'),
              formContainerRow: newsletter('FormContainerRow'),
              form: newsletter('Form'),
              submitContainer: newsletter('SubmitContainer'),
              submit: newsletter('Submit'),
              title: newsletter('Title'),
              description: newsletter('Description'),
              checkbox: newsletter('Checkbox'),
              select: newsletter('Select'),
              selectLabelSpan: newsletter('SelectLabelSpan'),
              selectState: newsletter('SelectState'),
              input: newsletter('Input'),
              label: newsletter('Label'),
              textInput: newsletter('TextInput'),
              textInputLabelSpan: newsletter('TextInputLabelSpan'),
              validationErroredField: newsletter('ValidationErroredField'),
              validationHeader: newsletter('ValidationHeader'),
              selectElement: newsletter('SelectElement'),
              selectOptionElement: newsletter('SelectOptionElement'),
              inputElement: newsletter('InputElement'),
              selectMultiple: newsletter('SelectMultiple'),
            },
            consentLayout: {
              container: newsletter('Consent'),
              thirdPartyContainer: newsletter('ConsentWrapper'),
              a: newsletter('ConsentLink'),
              p: newsletter('ConsentText'),
              input: newsletter('ConsentInput'),
              label: newsletter('ConsentLabel'),
            },
            newsletterLayout: {
              container: newsletter('Newsletter'),
              item: newsletter('NewsletterItem'),
              inputContainer: newsletter('NewsletterInputContainer'),
              input: newsletter('NewsletterInput'),
              consentContainer: newsletter('NewsletterConsentContainer'),
              itemTitle: newsletter('NewsletterItemTitle'),
              itemFrequency: newsletter('NewsletterFrequency'),
              itemFrequencyA: newsletter('NewsletterFrequencyLink'),
              itemFrequencyP: newsletter('NewsletterFrequencyText'),
              validationError: newsletter('NewsletterError'),
              success: {
                container: newsletter('NewsletterSuccess'),
                thankYouTitle: newsletter('NewsletterSuccessTitle'),
                plainText: newsletter('NewsletterSuccessText'),
                button: newsletter('NewsletterSuccessButton'),
              },
            },
            errorLayout: {
              container: newsletter('Error'),
              title: newsletter('ErrorTitle'),
              plainText: newsletter('ErrorText'),
              button: newsletter('ErrorButton'),
            },
          }}
        />
      )}
    </>
  )
}
