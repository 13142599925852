import { useEffect, useState } from 'react'

import {
  EventType,
  GTM,
  NewsletterSubscribeButtonLocation,
  RegistrationStatus,
} from '@scm/tag-manager'
import { Button } from '@scm/ui-component-button'
import { Bem, Brand } from '@scm/ui-core'
import { ClassName } from '@scm/ui-tailwind'

import { ModalNewsletter } from './newsletterModal'
import { SubscribeBanner } from './subscribeBanner'
import { SubscribeBannerTopicPage } from './subscribeBannerTopicPage'
import { NewsletterLayoutBannerProps, NewsletterSignupPromoProps } from './type'
import { selectors } from '@scm/redux-store'

const newsletterSignupPromo = Bem('NewsletterSignupPromo')

const MODULE_UID = 'module_newsletter_promo_cta'

// eslint-disable-next-line complexity
export const NewsletterSignupPromo = ({
  buttonText = '',
  newsletterId,
  layoutVariant = 'banner',
  social,
  isOpenNewsletter,
  isOnHomepage = false,
  moduleLocation,
  title,
  ...props
}: NewsletterSignupPromoProps) => {
  const sendEvent = GTM.hooks.useSendEvent()
  const {
    uiBrand,
    newsletterSignupPromo: { unbounceURL },
  } = selectors.useConfig()
  const { elnNewsletter } = selectors.useFeatureToggles()
  const [isOpen, setIsOpen] = useState(false)
  const [isOpenOnRegister, setIsOpenOnRegister] = useState(false)
  const ctaButtonText = useNewsletterCTAText(uiBrand)
  const currentUrl = GTM.utils.getCurrentUrl()

  const isAnchor = !elnNewsletter
  const url = isAnchor && unbounceURL ? unbounceURL : ''

  useEffect(() => {
    if (isOpenNewsletter && elnNewsletter) {
      // Open the newsletter sign up for only one time
      setIsOpenOnRegister(true)
    }
  }, [isOpenNewsletter, elnNewsletter])

  const onHandleSubscribeButtonClick = ({
    eventName,
    isButtonAnchor = false,
  }: {
    eventName: NewsletterSubscribeButtonLocation
    isButtonAnchor?: boolean
  }) => {
    trackNavigation()
    sendEvent({
      data: {
        status: RegistrationStatus.ATTEMPT,
        name: eventName,
      },
      event: EventType.NEWSLETTER,
      _clear: true,
    })
    if (!isButtonAnchor) {
      setIsOpen(true)
    }
  }

  const trackNavigation = () => {
    if (!currentUrl || !moduleLocation) return
    const payload = {
      name: title,
      location: moduleLocation,
      section: MODULE_UID,
      referring_page: currentUrl.pathname,
      destination_page: GTM.utils.prepareDestinationPath(url, currentUrl),
    }
    sendEvent({
      data: payload,
      event: EventType.NAVIGATION,
      _clear: true,
    })
  }

  return (
    <div data-module="newsletter-signup-promo">
      {isOpenOnRegister && (
        <ModalNewsletter
          onClose={() => setIsOpenOnRegister(false)}
          newsletterId={newsletterId}
        />
      )}

      {
        <LayoutBanner
          {...props}
          isOpen={isOpen}
          newsletterId={newsletterId}
          buttonText={buttonText || ctaButtonText}
          setIsOpen={setIsOpen}
          title={title}
          layoutVariant={layoutVariant}
          isButtonAnchor={isAnchor}
          subscribeUrl={url}
          isOnHomepage={isOnHomepage}
          onHandleSubscribeButtonClick={onHandleSubscribeButtonClick}
          moduleLocation={moduleLocation}
        />
      }
      {layoutVariant === 'header' && (
        <>
          <div
            className={newsletterSignupPromo('Button', { small: true }, [
              'NewsletterSignupButton',
            ])}
          >
            <Button
              automationId="headerSmallNewsletterButton"
              leftIcon="Email"
              size="small"
              variant="primary-filled"
              onClick={() =>
                onHandleSubscribeButtonClick({
                  eventName: NewsletterSubscribeButtonLocation.MAIN_MENU,
                  isButtonAnchor: isAnchor,
                })
              }
              url={url}
              buttonLikeAnchor={isAnchor}
              target="_blank"
              dataName="subscribe-nav"
              dataActionType="sign-up"
            >
              {ctaButtonText}
            </Button>
          </div>

          <div
            className={newsletterSignupPromo('Button', { medium: true }, [
              'NewsletterSignupButton',
            ])}
          >
            {social}
            <Button
              automationId="headerMediumNewsletterButton"
              leftIcon="Email"
              size="medium"
              variant="primary-filled"
              onClick={() =>
                onHandleSubscribeButtonClick({
                  eventName: NewsletterSubscribeButtonLocation.MAIN_MENU,
                  isButtonAnchor: isAnchor,
                })
              }
              url={url}
              buttonLikeAnchor={isAnchor}
              target="_blank"
              dataName="subscribe-nav"
              dataActionType="sign-up"
            >
              {ctaButtonText}
            </Button>
          </div>
          {isOpen && (
            <ModalNewsletter
              onClose={() => setIsOpen(false)}
              newsletterId={newsletterId}
            />
          )}
        </>
      )}
      {layoutVariant === 'megaMenu' && (
        <>
          <div className={newsletterSignupPromo('Button', { small: true })}>
            <Button
              automationId="megaMenuSmallNewsletterButton"
              className={newsletterSignupPromo('WrappedButton') as ClassName}
              leftIcon="Email"
              size="large"
              variant="secondary-filled"
              onClick={() =>
                onHandleSubscribeButtonClick({
                  eventName: NewsletterSubscribeButtonLocation.MEGA_MENU,
                  isButtonAnchor: isAnchor,
                })
              }
              url={url}
              buttonLikeAnchor={isAnchor}
              target="_blank"
              dataName="subscribe-hamburger"
              dataActionType="sign-up"
            >
              {ctaButtonText}
            </Button>
          </div>

          <div className={newsletterSignupPromo('Button', { medium: true })}>
            {social}
            <Button
              automationId="megaMenuMediumNewsletterButton"
              leftIcon="Email"
              size="medium"
              variant="secondary-filled"
              onClick={() =>
                onHandleSubscribeButtonClick({
                  eventName: NewsletterSubscribeButtonLocation.MEGA_MENU,
                  isButtonAnchor: isAnchor,
                })
              }
              url={url}
              buttonLikeAnchor={isAnchor}
              target="_blank"
              dataName="subscribe-hamburger"
              dataActionType="sign-up"
            >
              {ctaButtonText}
            </Button>
          </div>
        </>
      )}
      {layoutVariant === 'footer' && (
        <>
          <Button
            automationId="footerNewsletterButton"
            size="medium"
            variant="primary-filled"
            onClick={() =>
              onHandleSubscribeButtonClick({
                eventName: NewsletterSubscribeButtonLocation.FOOTER_MENU,
                isButtonAnchor: isAnchor,
              })
            }
            url={url}
            buttonLikeAnchor={isAnchor}
            target="_blank"
            dataName="subscribe-footer"
            dataActionType="sign-up"
          >
            {buttonText || ctaButtonText}
          </Button>
          {isOpen && (
            <ModalNewsletter
              onClose={() => setIsOpen(false)}
              newsletterId={newsletterId}
            />
          )}
        </>
      )}
    </div>
  )
}

const LayoutBanner = ({
  title,
  message,
  subBrandId,
  className,
  variant = 'vertical',
  mode,
  layoutVariant,
  buttonText,
  isOpen,
  newsletterId,
  setIsOpen,
  isButtonAnchor,
  subscribeUrl,
  isOnHomepage,
  onHandleSubscribeButtonClick,
  moduleLocation,
}: NewsletterLayoutBannerProps) => {
  const subscribeBannerButtonEventType = isOnHomepage
    ? NewsletterSubscribeButtonLocation.HOME_PAGE_MODULE
    : NewsletterSubscribeButtonLocation.NEWSLETTER_MODULE

  if (moduleLocation === 'sidebar' && variant === 'horizontal') {
    variant = `${variant}_${moduleLocation}`
  }

  return (
    <>
      {layoutVariant === 'banner' && (
        <SubscribeBanner
          title={title}
          message={message}
          variant={variant}
          className={className}
          mode={mode}
        >
          <Button
            automationId="bannerNewsletterbutton"
            size="medium"
            variant={mode === 'dark' ? 'secondary-filled' : 'primary-filled'}
            onClick={() =>
              onHandleSubscribeButtonClick({
                eventName: subscribeBannerButtonEventType,
                isButtonAnchor,
              })
            }
            url={subscribeUrl}
            buttonLikeAnchor={isButtonAnchor}
            target="_blank"
            dataName="subscribe-banner"
            dataActionType="sign-up"
          >
            {buttonText}
          </Button>
          {isOpen && (
            <ModalNewsletter
              onClose={() => setIsOpen(false)}
              newsletterId={newsletterId}
              subBrandId={subBrandId}
            />
          )}
        </SubscribeBanner>
      )}
      {layoutVariant === 'bannerTopicPage' && (
        <SubscribeBannerTopicPage
          title={title}
          message={message}
          variant={variant}
          className={className}
          mode={mode}
        >
          <Button
            automationId="bannerTopicPageNewsletterButton"
            size="medium"
            variant={mode == 'dark' ? 'secondary-filled' : 'primary-filled'}
            onClick={() =>
              onHandleSubscribeButtonClick({
                eventName: NewsletterSubscribeButtonLocation.NEWSLETTER_MODULE,
                isButtonAnchor,
              })
            }
            url={subscribeUrl}
            buttonLikeAnchor={isButtonAnchor}
            target="_blank"
            dataName="subscribe-banner"
            dataActionType="sign-up"
          >
            {buttonText}
          </Button>
          {isOpen && (
            <ModalNewsletter
              onClose={() => setIsOpen(false)}
              newsletterId={newsletterId}
              subBrandId={subBrandId}
            />
          )}
        </SubscribeBannerTopicPage>
      )}
    </>
  )
}

// eslint-disable-next-line complexity
const useNewsletterCTAText = (brand: Brand) => {
  switch (brand) {
    case Brand.AI_BUSINESS:
    case Brand.IOT:
      return 'STAY UPDATED'
    case Brand.INFORMATION_WEEK:
    case Brand.DARK_READING:
    case Brand.IT_PRO_TODAY:
    case Brand.DATA_CENTER_KNOWLEDGE:
    case Brand.WARDS_AUTO:
    case Brand.NETWORK_COMPUTING:
    case Brand.AMERICAN_CITY_COUNTY:
    case Brand.URGENT_COMMS:
    case Brand.NO_JITTER:
    case Brand.NATION_S_RESTAURANT_NEWS:
      return 'Newsletter Sign-Up'
    case Brand.BEEF_MAGAZINE:
    case Brand.FEED_STUFFS:
    case Brand.NATIONAL_HOG_FARMER:
    case Brand.NATURAL_PRODUCTS_INSIDER:
    case Brand.FOOD_BEVERAGE_INSIDER:
    case Brand.NEW_HOPE:
    case Brand.BATTERY_TECH:
    case Brand.DESIGN_NEWS:
    case Brand.POWDER_BULK_SOLIDS:
    case Brand.PACKAGING_DIGEST:
    case Brand.PLASTICS_TODAY:
    case Brand.MDDI:
    case Brand.ORGANIC_PRODUCE_NETWORK:
    case Brand.SUPPLY_SIDE_SJ:
    case Brand.SUPPLY_SIDE_FBJ:
      return 'Subscribe Today'
    case Brand.CONSTRUCTIO_NEXT:
    case Brand.INSIDE_SELF_STORAGE:
      return 'Sign Up Today'
    case Brand.GAME_DEVELOPER:
    case Brand.CONNECTING_AFRICA:
    case Brand.BIO_XCONOMY:
      return 'Stay Updated'
    case Brand.BIO_PROCESS_INTERNATIONAL:
      return 'Subscribe'
    case Brand.SUPERMARKET_NEWS:
    case Brand.RESTAURANT_HOSPITALITY:
    case Brand.FOOD_SERVICE_DIRECTOR:
      return 'Sign Up'
    case Brand.SPECIAL_EVENTS:
    case Brand.CATERSOURCE:
      return 'Sign Up Now'
    case Brand.THE_AESTHETIC_GUIDE:
      return 'Sign Me Up'
    default:
      return 'Subscribe'
  }
}
