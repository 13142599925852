/* eslint-disable */
/* tslint:disable */

//////////////////////////////////////////////////////////////////////////////
/// Autogenerated by tailwindcss-classnames CLI. https://git.io/JtsPU
/// DO NOT EDIT THIS FILE DIRECTLY!
//////////////////////////////////////////////////////////////////////////////


import classnamesLib from 'clsx';
import type TCustomClassesFromExternalFile from './styles';

export type TScreenReaders =
  | 'sr-only'
  | 'not-sr-only'

export type TAccessibility =
  | TScreenReaders

export type TBackgroundAttachment =
  | 'bg-fixed'
  | 'bg-local'
  | 'bg-scroll'

export type TBackgroundClip =
  | 'bg-clip-border'
  | 'bg-clip-padding'
  | 'bg-clip-content'
  | 'bg-clip-text'

export type TBackgroundOrigin =
  | 'bg-origin-border'
  | 'bg-origin-padding'
  | 'bg-origin-content'

export type TBackgroundRepeat =
  | 'bg-repeat'
  | 'bg-no-repeat'
  | 'bg-repeat-x'
  | 'bg-repeat-y'
  | 'bg-repeat-round'
  | 'bg-repeat-space'

export type TBackgroundOpacity =
  | 'bg-opacity-0'
  | 'bg-opacity-5'
  | 'bg-opacity-10'
  | 'bg-opacity-20'
  | 'bg-opacity-25'
  | 'bg-opacity-30'
  | 'bg-opacity-40'
  | 'bg-opacity-50'
  | 'bg-opacity-60'
  | 'bg-opacity-70'
  | 'bg-opacity-75'
  | 'bg-opacity-80'
  | 'bg-opacity-90'
  | 'bg-opacity-95'
  | 'bg-opacity-100'

export type TBackgroundColor =
  | 'bg-inherit'
  | 'bg-transparent'
  | 'bg-current'
  | 'bg-black'
  | 'bg-white'
  | 'bg-brand-primary-1'
  | 'bg-brand-primary-2'
  | 'bg-brand-primary-3'
  | 'bg-brand-primary-4'
  | 'bg-brand-primary-5'
  | 'bg-brand-secondary-1'
  | 'bg-brand-secondary-2'
  | 'bg-brand-secondary-3'
  | 'bg-brand-secondary-4'
  | 'bg-brand-secondary-5'
  | 'bg-brand-tertiary-1'
  | 'bg-brand-tertiary-2'
  | 'bg-brand-tertiary-3'
  | 'bg-brand-tertiary-4'
  | 'bg-brand-tertiary-5'
  | 'bg-gray-1'
  | 'bg-gray-2'
  | 'bg-gray-3'
  | 'bg-gray-4'
  | 'bg-gray-5'
  | 'bg-gray-6'
  | 'bg-gray-7'
  | 'bg-gray-8'
  | 'bg-gray-9'
  | 'bg-social-linkedin'
  | 'bg-social-facebook'
  | 'bg-social-twitter'
  | 'bg-social-instagram'
  | 'bg-informa-bar'
  | 'bg-danger'
  | 'bg-error'

export type TBackgroundPosition =
  | 'bg-bottom'
  | 'bg-center'
  | 'bg-left'
  | 'bg-left-bottom'
  | 'bg-left-top'
  | 'bg-right'
  | 'bg-right-bottom'
  | 'bg-right-top'
  | 'bg-top'

export type TBackgroundSize =
  | 'bg-auto'
  | 'bg-cover'
  | 'bg-contain'

export type TBackgroundImage =
  | 'bg-none'
  | 'bg-gradient-to-t'
  | 'bg-gradient-to-tr'
  | 'bg-gradient-to-r'
  | 'bg-gradient-to-br'
  | 'bg-gradient-to-b'
  | 'bg-gradient-to-bl'
  | 'bg-gradient-to-l'
  | 'bg-gradient-to-tl'

export type TGradientColorStops =
  | 'from-inherit'
  | 'via-inherit'
  | 'to-inherit'
  | 'from-transparent'
  | 'via-transparent'
  | 'to-transparent'
  | 'from-current'
  | 'via-current'
  | 'to-current'
  | 'from-black'
  | 'via-black'
  | 'to-black'
  | 'from-white'
  | 'via-white'
  | 'to-white'
  | 'from-brand-primary-1'
  | 'via-brand-primary-1'
  | 'to-brand-primary-1'
  | 'from-brand-primary-2'
  | 'via-brand-primary-2'
  | 'to-brand-primary-2'
  | 'from-brand-primary-3'
  | 'via-brand-primary-3'
  | 'to-brand-primary-3'
  | 'from-brand-primary-4'
  | 'via-brand-primary-4'
  | 'to-brand-primary-4'
  | 'from-brand-primary-5'
  | 'via-brand-primary-5'
  | 'to-brand-primary-5'
  | 'from-brand-secondary-1'
  | 'via-brand-secondary-1'
  | 'to-brand-secondary-1'
  | 'from-brand-secondary-2'
  | 'via-brand-secondary-2'
  | 'to-brand-secondary-2'
  | 'from-brand-secondary-3'
  | 'via-brand-secondary-3'
  | 'to-brand-secondary-3'
  | 'from-brand-secondary-4'
  | 'via-brand-secondary-4'
  | 'to-brand-secondary-4'
  | 'from-brand-secondary-5'
  | 'via-brand-secondary-5'
  | 'to-brand-secondary-5'
  | 'from-brand-tertiary-1'
  | 'via-brand-tertiary-1'
  | 'to-brand-tertiary-1'
  | 'from-brand-tertiary-2'
  | 'via-brand-tertiary-2'
  | 'to-brand-tertiary-2'
  | 'from-brand-tertiary-3'
  | 'via-brand-tertiary-3'
  | 'to-brand-tertiary-3'
  | 'from-brand-tertiary-4'
  | 'via-brand-tertiary-4'
  | 'to-brand-tertiary-4'
  | 'from-brand-tertiary-5'
  | 'via-brand-tertiary-5'
  | 'to-brand-tertiary-5'
  | 'from-gray-1'
  | 'via-gray-1'
  | 'to-gray-1'
  | 'from-gray-2'
  | 'via-gray-2'
  | 'to-gray-2'
  | 'from-gray-3'
  | 'via-gray-3'
  | 'to-gray-3'
  | 'from-gray-4'
  | 'via-gray-4'
  | 'to-gray-4'
  | 'from-gray-5'
  | 'via-gray-5'
  | 'to-gray-5'
  | 'from-gray-6'
  | 'via-gray-6'
  | 'to-gray-6'
  | 'from-gray-7'
  | 'via-gray-7'
  | 'to-gray-7'
  | 'from-gray-8'
  | 'via-gray-8'
  | 'to-gray-8'
  | 'from-gray-9'
  | 'via-gray-9'
  | 'to-gray-9'
  | 'from-social-linkedin'
  | 'via-social-linkedin'
  | 'to-social-linkedin'
  | 'from-social-facebook'
  | 'via-social-facebook'
  | 'to-social-facebook'
  | 'from-social-twitter'
  | 'via-social-twitter'
  | 'to-social-twitter'
  | 'from-social-instagram'
  | 'via-social-instagram'
  | 'to-social-instagram'
  | 'from-informa-bar'
  | 'via-informa-bar'
  | 'to-informa-bar'
  | 'from-danger'
  | 'via-danger'
  | 'to-danger'
  | 'from-error'
  | 'via-error'
  | 'to-error'

export type TBackgrounds =
  | TBackgroundAttachment
  | TBackgroundClip
  | TBackgroundOrigin
  | TBackgroundRepeat
  | TBackgroundOpacity
  | TBackgroundColor
  | TBackgroundPosition
  | TBackgroundSize
  | TBackgroundImage
  | TGradientColorStops

export type TBorderStyle =
  | 'border-solid'
  | 'border-dashed'
  | 'border-dotted'
  | 'border-double'
  | 'border-hidden'
  | 'border-none'

export type TDivideStyle =
  | 'divide-solid'
  | 'divide-dashed'
  | 'divide-dotted'
  | 'divide-double'
  | 'divide-none'

export type TOutlineStyle =
  | 'outline'
  | 'outline-dashed'
  | 'outline-dotted'
  | 'outline-double'
  | 'outline-hidden'
  | 'outline-none'

export type TBorderColor =
  | 'border-inherit'
  | 'border-t-inherit'
  | 'border-r-inherit'
  | 'border-b-inherit'
  | 'border-l-inherit'
  | 'border-transparent'
  | 'border-t-transparent'
  | 'border-r-transparent'
  | 'border-b-transparent'
  | 'border-l-transparent'
  | 'border-current'
  | 'border-t-current'
  | 'border-r-current'
  | 'border-b-current'
  | 'border-l-current'
  | 'border-black'
  | 'border-t-black'
  | 'border-r-black'
  | 'border-b-black'
  | 'border-l-black'
  | 'border-white'
  | 'border-t-white'
  | 'border-r-white'
  | 'border-b-white'
  | 'border-l-white'
  | 'border-brand-primary-1'
  | 'border-t-brand-primary-1'
  | 'border-r-brand-primary-1'
  | 'border-b-brand-primary-1'
  | 'border-l-brand-primary-1'
  | 'border-brand-primary-2'
  | 'border-t-brand-primary-2'
  | 'border-r-brand-primary-2'
  | 'border-b-brand-primary-2'
  | 'border-l-brand-primary-2'
  | 'border-brand-primary-3'
  | 'border-t-brand-primary-3'
  | 'border-r-brand-primary-3'
  | 'border-b-brand-primary-3'
  | 'border-l-brand-primary-3'
  | 'border-brand-primary-4'
  | 'border-t-brand-primary-4'
  | 'border-r-brand-primary-4'
  | 'border-b-brand-primary-4'
  | 'border-l-brand-primary-4'
  | 'border-brand-primary-5'
  | 'border-t-brand-primary-5'
  | 'border-r-brand-primary-5'
  | 'border-b-brand-primary-5'
  | 'border-l-brand-primary-5'
  | 'border-brand-secondary-1'
  | 'border-t-brand-secondary-1'
  | 'border-r-brand-secondary-1'
  | 'border-b-brand-secondary-1'
  | 'border-l-brand-secondary-1'
  | 'border-brand-secondary-2'
  | 'border-t-brand-secondary-2'
  | 'border-r-brand-secondary-2'
  | 'border-b-brand-secondary-2'
  | 'border-l-brand-secondary-2'
  | 'border-brand-secondary-3'
  | 'border-t-brand-secondary-3'
  | 'border-r-brand-secondary-3'
  | 'border-b-brand-secondary-3'
  | 'border-l-brand-secondary-3'
  | 'border-brand-secondary-4'
  | 'border-t-brand-secondary-4'
  | 'border-r-brand-secondary-4'
  | 'border-b-brand-secondary-4'
  | 'border-l-brand-secondary-4'
  | 'border-brand-secondary-5'
  | 'border-t-brand-secondary-5'
  | 'border-r-brand-secondary-5'
  | 'border-b-brand-secondary-5'
  | 'border-l-brand-secondary-5'
  | 'border-brand-tertiary-1'
  | 'border-t-brand-tertiary-1'
  | 'border-r-brand-tertiary-1'
  | 'border-b-brand-tertiary-1'
  | 'border-l-brand-tertiary-1'
  | 'border-brand-tertiary-2'
  | 'border-t-brand-tertiary-2'
  | 'border-r-brand-tertiary-2'
  | 'border-b-brand-tertiary-2'
  | 'border-l-brand-tertiary-2'
  | 'border-brand-tertiary-3'
  | 'border-t-brand-tertiary-3'
  | 'border-r-brand-tertiary-3'
  | 'border-b-brand-tertiary-3'
  | 'border-l-brand-tertiary-3'
  | 'border-brand-tertiary-4'
  | 'border-t-brand-tertiary-4'
  | 'border-r-brand-tertiary-4'
  | 'border-b-brand-tertiary-4'
  | 'border-l-brand-tertiary-4'
  | 'border-brand-tertiary-5'
  | 'border-t-brand-tertiary-5'
  | 'border-r-brand-tertiary-5'
  | 'border-b-brand-tertiary-5'
  | 'border-l-brand-tertiary-5'
  | 'border-gray-1'
  | 'border-t-gray-1'
  | 'border-r-gray-1'
  | 'border-b-gray-1'
  | 'border-l-gray-1'
  | 'border-gray-2'
  | 'border-t-gray-2'
  | 'border-r-gray-2'
  | 'border-b-gray-2'
  | 'border-l-gray-2'
  | 'border-gray-3'
  | 'border-t-gray-3'
  | 'border-r-gray-3'
  | 'border-b-gray-3'
  | 'border-l-gray-3'
  | 'border-gray-4'
  | 'border-t-gray-4'
  | 'border-r-gray-4'
  | 'border-b-gray-4'
  | 'border-l-gray-4'
  | 'border-gray-5'
  | 'border-t-gray-5'
  | 'border-r-gray-5'
  | 'border-b-gray-5'
  | 'border-l-gray-5'
  | 'border-gray-6'
  | 'border-t-gray-6'
  | 'border-r-gray-6'
  | 'border-b-gray-6'
  | 'border-l-gray-6'
  | 'border-gray-7'
  | 'border-t-gray-7'
  | 'border-r-gray-7'
  | 'border-b-gray-7'
  | 'border-l-gray-7'
  | 'border-gray-8'
  | 'border-t-gray-8'
  | 'border-r-gray-8'
  | 'border-b-gray-8'
  | 'border-l-gray-8'
  | 'border-gray-9'
  | 'border-t-gray-9'
  | 'border-r-gray-9'
  | 'border-b-gray-9'
  | 'border-l-gray-9'
  | 'border-social-linkedin'
  | 'border-t-social-linkedin'
  | 'border-r-social-linkedin'
  | 'border-b-social-linkedin'
  | 'border-l-social-linkedin'
  | 'border-social-facebook'
  | 'border-t-social-facebook'
  | 'border-r-social-facebook'
  | 'border-b-social-facebook'
  | 'border-l-social-facebook'
  | 'border-social-twitter'
  | 'border-t-social-twitter'
  | 'border-r-social-twitter'
  | 'border-b-social-twitter'
  | 'border-l-social-twitter'
  | 'border-social-instagram'
  | 'border-t-social-instagram'
  | 'border-r-social-instagram'
  | 'border-b-social-instagram'
  | 'border-l-social-instagram'
  | 'border-informa-bar'
  | 'border-t-informa-bar'
  | 'border-r-informa-bar'
  | 'border-b-informa-bar'
  | 'border-l-informa-bar'
  | 'border-danger'
  | 'border-t-danger'
  | 'border-r-danger'
  | 'border-b-danger'
  | 'border-l-danger'
  | 'border-error'
  | 'border-t-error'
  | 'border-r-error'
  | 'border-b-error'
  | 'border-l-error'

export type TBorderOpacity =
  | 'border-opacity-0'
  | 'border-opacity-5'
  | 'border-opacity-10'
  | 'border-opacity-20'
  | 'border-opacity-25'
  | 'border-opacity-30'
  | 'border-opacity-40'
  | 'border-opacity-50'
  | 'border-opacity-60'
  | 'border-opacity-70'
  | 'border-opacity-75'
  | 'border-opacity-80'
  | 'border-opacity-90'
  | 'border-opacity-95'
  | 'border-opacity-100'

export type TBorderRadius =
  | 'rounded-t-3'
  | 'rounded-r-3'
  | 'rounded-b-3'
  | 'rounded-l-3'
  | 'rounded-tr-3'
  | 'rounded-tl-3'
  | 'rounded-br-3'
  | 'rounded-bl-3'
  | 'rounded-3'
  | 'rounded-t-4'
  | 'rounded-r-4'
  | 'rounded-b-4'
  | 'rounded-l-4'
  | 'rounded-tr-4'
  | 'rounded-tl-4'
  | 'rounded-br-4'
  | 'rounded-bl-4'
  | 'rounded-4'
  | 'rounded-t-48'
  | 'rounded-r-48'
  | 'rounded-b-48'
  | 'rounded-l-48'
  | 'rounded-tr-48'
  | 'rounded-tl-48'
  | 'rounded-br-48'
  | 'rounded-bl-48'
  | 'rounded-48'
  | 'rounded-t-none'
  | 'rounded-r-none'
  | 'rounded-b-none'
  | 'rounded-l-none'
  | 'rounded-tr-none'
  | 'rounded-tl-none'
  | 'rounded-br-none'
  | 'rounded-bl-none'
  | 'rounded-none'
  | 'rounded-t-sm'
  | 'rounded-r-sm'
  | 'rounded-b-sm'
  | 'rounded-l-sm'
  | 'rounded-tr-sm'
  | 'rounded-tl-sm'
  | 'rounded-br-sm'
  | 'rounded-bl-sm'
  | 'rounded-sm'
  | 'rounded-t'
  | 'rounded-r'
  | 'rounded-b'
  | 'rounded-l'
  | 'rounded-tr'
  | 'rounded-tl'
  | 'rounded-br'
  | 'rounded-bl'
  | 'rounded'
  | 'rounded-t-md'
  | 'rounded-r-md'
  | 'rounded-b-md'
  | 'rounded-l-md'
  | 'rounded-tr-md'
  | 'rounded-tl-md'
  | 'rounded-br-md'
  | 'rounded-bl-md'
  | 'rounded-md'
  | 'rounded-t-lg'
  | 'rounded-r-lg'
  | 'rounded-b-lg'
  | 'rounded-l-lg'
  | 'rounded-tr-lg'
  | 'rounded-tl-lg'
  | 'rounded-br-lg'
  | 'rounded-bl-lg'
  | 'rounded-lg'
  | 'rounded-t-xl'
  | 'rounded-r-xl'
  | 'rounded-b-xl'
  | 'rounded-l-xl'
  | 'rounded-tr-xl'
  | 'rounded-tl-xl'
  | 'rounded-br-xl'
  | 'rounded-bl-xl'
  | 'rounded-xl'
  | 'rounded-t-2xl'
  | 'rounded-r-2xl'
  | 'rounded-b-2xl'
  | 'rounded-l-2xl'
  | 'rounded-tr-2xl'
  | 'rounded-tl-2xl'
  | 'rounded-br-2xl'
  | 'rounded-bl-2xl'
  | 'rounded-2xl'
  | 'rounded-t-3xl'
  | 'rounded-r-3xl'
  | 'rounded-b-3xl'
  | 'rounded-l-3xl'
  | 'rounded-tr-3xl'
  | 'rounded-tl-3xl'
  | 'rounded-br-3xl'
  | 'rounded-bl-3xl'
  | 'rounded-3xl'
  | 'rounded-t-full'
  | 'rounded-r-full'
  | 'rounded-b-full'
  | 'rounded-l-full'
  | 'rounded-tr-full'
  | 'rounded-tl-full'
  | 'rounded-br-full'
  | 'rounded-bl-full'
  | 'rounded-full'

export type TBorderWidth =
  | 'border-t-0'
  | 'border-r-0'
  | 'border-b-0'
  | 'border-l-0'
  | 'border-x-0'
  | 'border-y-0'
  | 'border-0'
  | 'border-t-2'
  | 'border-r-2'
  | 'border-b-2'
  | 'border-l-2'
  | 'border-x-2'
  | 'border-y-2'
  | 'border-2'
  | 'border-t-4'
  | 'border-r-4'
  | 'border-b-4'
  | 'border-l-4'
  | 'border-x-4'
  | 'border-y-4'
  | 'border-4'
  | 'border-t-8'
  | 'border-r-8'
  | 'border-b-8'
  | 'border-l-8'
  | 'border-x-8'
  | 'border-y-8'
  | 'border-8'
  | 'border-t'
  | 'border-r'
  | 'border-b'
  | 'border-l'
  | 'border-x'
  | 'border-y'
  | 'border'

export type TDivideColor =
  | 'divide-inherit'
  | 'divide-transparent'
  | 'divide-current'
  | 'divide-black'
  | 'divide-white'
  | 'divide-brand-primary-1'
  | 'divide-brand-primary-2'
  | 'divide-brand-primary-3'
  | 'divide-brand-primary-4'
  | 'divide-brand-primary-5'
  | 'divide-brand-secondary-1'
  | 'divide-brand-secondary-2'
  | 'divide-brand-secondary-3'
  | 'divide-brand-secondary-4'
  | 'divide-brand-secondary-5'
  | 'divide-brand-tertiary-1'
  | 'divide-brand-tertiary-2'
  | 'divide-brand-tertiary-3'
  | 'divide-brand-tertiary-4'
  | 'divide-brand-tertiary-5'
  | 'divide-gray-1'
  | 'divide-gray-2'
  | 'divide-gray-3'
  | 'divide-gray-4'
  | 'divide-gray-5'
  | 'divide-gray-6'
  | 'divide-gray-7'
  | 'divide-gray-8'
  | 'divide-gray-9'
  | 'divide-social-linkedin'
  | 'divide-social-facebook'
  | 'divide-social-twitter'
  | 'divide-social-instagram'
  | 'divide-informa-bar'
  | 'divide-danger'
  | 'divide-error'

export type TDivideOpacity =
  | 'divide-opacity-0'
  | 'divide-opacity-5'
  | 'divide-opacity-10'
  | 'divide-opacity-20'
  | 'divide-opacity-25'
  | 'divide-opacity-30'
  | 'divide-opacity-40'
  | 'divide-opacity-50'
  | 'divide-opacity-60'
  | 'divide-opacity-70'
  | 'divide-opacity-75'
  | 'divide-opacity-80'
  | 'divide-opacity-90'
  | 'divide-opacity-95'
  | 'divide-opacity-100'

export type TDivideWidth =
  | 'divide-x-0'
  | 'divide-y-0'
  | 'divide-x-2'
  | 'divide-y-2'
  | 'divide-x-4'
  | 'divide-y-4'
  | 'divide-x-8'
  | 'divide-y-8'
  | 'divide-x'
  | 'divide-y'
  | 'divide-x-reverse'
  | 'divide-y-reverse'

export type TRingColor =
  | 'ring-inherit'
  | 'ring-transparent'
  | 'ring-current'
  | 'ring-black'
  | 'ring-white'
  | 'ring-brand-primary-1'
  | 'ring-brand-primary-2'
  | 'ring-brand-primary-3'
  | 'ring-brand-primary-4'
  | 'ring-brand-primary-5'
  | 'ring-brand-secondary-1'
  | 'ring-brand-secondary-2'
  | 'ring-brand-secondary-3'
  | 'ring-brand-secondary-4'
  | 'ring-brand-secondary-5'
  | 'ring-brand-tertiary-1'
  | 'ring-brand-tertiary-2'
  | 'ring-brand-tertiary-3'
  | 'ring-brand-tertiary-4'
  | 'ring-brand-tertiary-5'
  | 'ring-gray-1'
  | 'ring-gray-2'
  | 'ring-gray-3'
  | 'ring-gray-4'
  | 'ring-gray-5'
  | 'ring-gray-6'
  | 'ring-gray-7'
  | 'ring-gray-8'
  | 'ring-gray-9'
  | 'ring-social-linkedin'
  | 'ring-social-facebook'
  | 'ring-social-twitter'
  | 'ring-social-instagram'
  | 'ring-informa-bar'
  | 'ring-danger'
  | 'ring-error'

export type TRingWidth =
  | 'ring-0'
  | 'ring-1'
  | 'ring-2'
  | 'ring-4'
  | 'ring-8'
  | 'ring'
  | 'ring-inset'

export type TRingOpacity =
  | 'ring-opacity-0'
  | 'ring-opacity-5'
  | 'ring-opacity-10'
  | 'ring-opacity-20'
  | 'ring-opacity-25'
  | 'ring-opacity-30'
  | 'ring-opacity-40'
  | 'ring-opacity-50'
  | 'ring-opacity-60'
  | 'ring-opacity-70'
  | 'ring-opacity-75'
  | 'ring-opacity-80'
  | 'ring-opacity-90'
  | 'ring-opacity-95'
  | 'ring-opacity-100'
  | 'ring-opacity'

export type TRingOffsetColor =
  | 'ring-offset-inherit'
  | 'ring-offset-transparent'
  | 'ring-offset-current'
  | 'ring-offset-black'
  | 'ring-offset-white'
  | 'ring-offset-brand-primary-1'
  | 'ring-offset-brand-primary-2'
  | 'ring-offset-brand-primary-3'
  | 'ring-offset-brand-primary-4'
  | 'ring-offset-brand-primary-5'
  | 'ring-offset-brand-secondary-1'
  | 'ring-offset-brand-secondary-2'
  | 'ring-offset-brand-secondary-3'
  | 'ring-offset-brand-secondary-4'
  | 'ring-offset-brand-secondary-5'
  | 'ring-offset-brand-tertiary-1'
  | 'ring-offset-brand-tertiary-2'
  | 'ring-offset-brand-tertiary-3'
  | 'ring-offset-brand-tertiary-4'
  | 'ring-offset-brand-tertiary-5'
  | 'ring-offset-gray-1'
  | 'ring-offset-gray-2'
  | 'ring-offset-gray-3'
  | 'ring-offset-gray-4'
  | 'ring-offset-gray-5'
  | 'ring-offset-gray-6'
  | 'ring-offset-gray-7'
  | 'ring-offset-gray-8'
  | 'ring-offset-gray-9'
  | 'ring-offset-social-linkedin'
  | 'ring-offset-social-facebook'
  | 'ring-offset-social-twitter'
  | 'ring-offset-social-instagram'
  | 'ring-offset-informa-bar'
  | 'ring-offset-danger'
  | 'ring-offset-error'

export type TRingOffsetWidth =
  | 'ring-offset-0'
  | 'ring-offset-1'
  | 'ring-offset-2'
  | 'ring-offset-4'
  | 'ring-offset-8'

export type TOutlineOffset =
  | 'outline-0'
  | 'outline-1'
  | 'outline-2'
  | 'outline-4'
  | 'outline-8'

export type TOutlineWidth =
  | 'outline-0'
  | 'outline-1'
  | 'outline-2'
  | 'outline-4'
  | 'outline-8'

export type TOutlineColor =
  | 'outline-inherit'
  | 'outline-transparent'
  | 'outline-current'
  | 'outline-black'
  | 'outline-white'
  | 'outline-brand-primary-1'
  | 'outline-brand-primary-2'
  | 'outline-brand-primary-3'
  | 'outline-brand-primary-4'
  | 'outline-brand-primary-5'
  | 'outline-brand-secondary-1'
  | 'outline-brand-secondary-2'
  | 'outline-brand-secondary-3'
  | 'outline-brand-secondary-4'
  | 'outline-brand-secondary-5'
  | 'outline-brand-tertiary-1'
  | 'outline-brand-tertiary-2'
  | 'outline-brand-tertiary-3'
  | 'outline-brand-tertiary-4'
  | 'outline-brand-tertiary-5'
  | 'outline-gray-1'
  | 'outline-gray-2'
  | 'outline-gray-3'
  | 'outline-gray-4'
  | 'outline-gray-5'
  | 'outline-gray-6'
  | 'outline-gray-7'
  | 'outline-gray-8'
  | 'outline-gray-9'
  | 'outline-social-linkedin'
  | 'outline-social-facebook'
  | 'outline-social-twitter'
  | 'outline-social-instagram'
  | 'outline-informa-bar'
  | 'outline-danger'
  | 'outline-error'

export type TBorders =
  | TBorderStyle
  | TDivideStyle
  | TOutlineStyle
  | TBorderColor
  | TBorderOpacity
  | TBorderRadius
  | TBorderWidth
  | TDivideColor
  | TDivideOpacity
  | TDivideWidth
  | TRingColor
  | TRingWidth
  | TRingOpacity
  | TRingOffsetColor
  | TRingOffsetWidth
  | TOutlineOffset
  | TOutlineWidth
  | TOutlineColor

export type TBorderCollapse =
  | 'border-collapse'
  | 'border-separate'

export type TTableLayout =
  | 'table-auto'
  | 'table-fixed'

export type TBorderSpacing =
  | 'border-spacing-0'
  | 'border-spacing-1'
  | 'border-spacing-2'
  | 'border-spacing-3'
  | 'border-spacing-4'
  | 'border-spacing-5'
  | 'border-spacing-6'
  | 'border-spacing-7'
  | 'border-spacing-8'
  | 'border-spacing-9'
  | 'border-spacing-10'
  | 'border-spacing-11'
  | 'border-spacing-12'
  | 'border-spacing-13'
  | 'border-spacing-14'
  | 'border-spacing-15'
  | 'border-spacing-16'
  | 'border-spacing-17'
  | 'border-spacing-18'
  | 'border-spacing-19'
  | 'border-spacing-20'
  | 'border-spacing-21'
  | 'border-spacing-22'
  | 'border-spacing-23'
  | 'border-spacing-24'
  | 'border-spacing-25'
  | 'border-spacing-26'
  | 'border-spacing-27'
  | 'border-spacing-28'
  | 'border-spacing-29'
  | 'border-spacing-30'
  | 'border-spacing-32'
  | 'border-spacing-33'
  | 'border-spacing-34'
  | 'border-spacing-35'
  | 'border-spacing-36'
  | 'border-spacing-37'
  | 'border-spacing-38'
  | 'border-spacing-40'
  | 'border-spacing-41'
  | 'border-spacing-42'
  | 'border-spacing-44'
  | 'border-spacing-45'
  | 'border-spacing-46'
  | 'border-spacing-48'
  | 'border-spacing-50'
  | 'border-spacing-51'
  | 'border-spacing-52'
  | 'border-spacing-53'
  | 'border-spacing-56'
  | 'border-spacing-58'
  | 'border-spacing-60'
  | 'border-spacing-61'
  | 'border-spacing-64'
  | 'border-spacing-66'
  | 'border-spacing-70'
  | 'border-spacing-71'
  | 'border-spacing-72'
  | 'border-spacing-77'
  | 'border-spacing-80'
  | 'border-spacing-82'
  | 'border-spacing-85'
  | 'border-spacing-87'
  | 'border-spacing-88'
  | 'border-spacing-90'
  | 'border-spacing-95'
  | 'border-spacing-96'
  | 'border-spacing-100'
  | 'border-spacing-110'
  | 'border-spacing-112'
  | 'border-spacing-116'
  | 'border-spacing-120'
  | 'border-spacing-124'
  | 'border-spacing-126'
  | 'border-spacing-130'
  | 'border-spacing-132'
  | 'border-spacing-144'
  | 'border-spacing-150'
  | 'border-spacing-156'
  | 'border-spacing-160'
  | 'border-spacing-164'
  | 'border-spacing-170'
  | 'border-spacing-172'
  | 'border-spacing-178'
  | 'border-spacing-180'
  | 'border-spacing-182'
  | 'border-spacing-186'
  | 'border-spacing-190'
  | 'border-spacing-196'
  | 'border-spacing-200'
  | 'border-spacing-202'
  | 'border-spacing-216'
  | 'border-spacing-220'
  | 'border-spacing-222'
  | 'border-spacing-235'
  | 'border-spacing-248'
  | 'border-spacing-255'
  | 'border-spacing-258'
  | 'border-spacing-264'
  | 'border-spacing-270'
  | 'border-spacing-275'
  | 'border-spacing-280'
  | 'border-spacing-296'
  | 'border-spacing-300'
  | 'border-spacing-304'
  | 'border-spacing-310'
  | 'border-spacing-320'
  | 'border-spacing-324'
  | 'border-spacing-330'
  | 'border-spacing-336'
  | 'border-spacing-340'
  | 'border-spacing-350'
  | 'border-spacing-360'
  | 'border-spacing-370'
  | 'border-spacing-380'
  | 'border-spacing-390'
  | 'border-spacing-632'
  | 'border-spacing-680'
  | 'border-spacing-720'
  | 'border-spacing-fit'
  | 'border-spacing-max'
  | 'border-spacing-x-0'
  | 'border-spacing-x-1'
  | 'border-spacing-x-2'
  | 'border-spacing-x-3'
  | 'border-spacing-x-4'
  | 'border-spacing-x-5'
  | 'border-spacing-x-6'
  | 'border-spacing-x-7'
  | 'border-spacing-x-8'
  | 'border-spacing-x-9'
  | 'border-spacing-x-10'
  | 'border-spacing-x-11'
  | 'border-spacing-x-12'
  | 'border-spacing-x-13'
  | 'border-spacing-x-14'
  | 'border-spacing-x-15'
  | 'border-spacing-x-16'
  | 'border-spacing-x-17'
  | 'border-spacing-x-18'
  | 'border-spacing-x-19'
  | 'border-spacing-x-20'
  | 'border-spacing-x-21'
  | 'border-spacing-x-22'
  | 'border-spacing-x-23'
  | 'border-spacing-x-24'
  | 'border-spacing-x-25'
  | 'border-spacing-x-26'
  | 'border-spacing-x-27'
  | 'border-spacing-x-28'
  | 'border-spacing-x-29'
  | 'border-spacing-x-30'
  | 'border-spacing-x-32'
  | 'border-spacing-x-33'
  | 'border-spacing-x-34'
  | 'border-spacing-x-35'
  | 'border-spacing-x-36'
  | 'border-spacing-x-37'
  | 'border-spacing-x-38'
  | 'border-spacing-x-40'
  | 'border-spacing-x-41'
  | 'border-spacing-x-42'
  | 'border-spacing-x-44'
  | 'border-spacing-x-45'
  | 'border-spacing-x-46'
  | 'border-spacing-x-48'
  | 'border-spacing-x-50'
  | 'border-spacing-x-51'
  | 'border-spacing-x-52'
  | 'border-spacing-x-53'
  | 'border-spacing-x-56'
  | 'border-spacing-x-58'
  | 'border-spacing-x-60'
  | 'border-spacing-x-61'
  | 'border-spacing-x-64'
  | 'border-spacing-x-66'
  | 'border-spacing-x-70'
  | 'border-spacing-x-71'
  | 'border-spacing-x-72'
  | 'border-spacing-x-77'
  | 'border-spacing-x-80'
  | 'border-spacing-x-82'
  | 'border-spacing-x-85'
  | 'border-spacing-x-87'
  | 'border-spacing-x-88'
  | 'border-spacing-x-90'
  | 'border-spacing-x-95'
  | 'border-spacing-x-96'
  | 'border-spacing-x-100'
  | 'border-spacing-x-110'
  | 'border-spacing-x-112'
  | 'border-spacing-x-116'
  | 'border-spacing-x-120'
  | 'border-spacing-x-124'
  | 'border-spacing-x-126'
  | 'border-spacing-x-130'
  | 'border-spacing-x-132'
  | 'border-spacing-x-144'
  | 'border-spacing-x-150'
  | 'border-spacing-x-156'
  | 'border-spacing-x-160'
  | 'border-spacing-x-164'
  | 'border-spacing-x-170'
  | 'border-spacing-x-172'
  | 'border-spacing-x-178'
  | 'border-spacing-x-180'
  | 'border-spacing-x-182'
  | 'border-spacing-x-186'
  | 'border-spacing-x-190'
  | 'border-spacing-x-196'
  | 'border-spacing-x-200'
  | 'border-spacing-x-202'
  | 'border-spacing-x-216'
  | 'border-spacing-x-220'
  | 'border-spacing-x-222'
  | 'border-spacing-x-235'
  | 'border-spacing-x-248'
  | 'border-spacing-x-255'
  | 'border-spacing-x-258'
  | 'border-spacing-x-264'
  | 'border-spacing-x-270'
  | 'border-spacing-x-275'
  | 'border-spacing-x-280'
  | 'border-spacing-x-296'
  | 'border-spacing-x-300'
  | 'border-spacing-x-304'
  | 'border-spacing-x-310'
  | 'border-spacing-x-320'
  | 'border-spacing-x-324'
  | 'border-spacing-x-330'
  | 'border-spacing-x-336'
  | 'border-spacing-x-340'
  | 'border-spacing-x-350'
  | 'border-spacing-x-360'
  | 'border-spacing-x-370'
  | 'border-spacing-x-380'
  | 'border-spacing-x-390'
  | 'border-spacing-x-632'
  | 'border-spacing-x-680'
  | 'border-spacing-x-720'
  | 'border-spacing-x-fit'
  | 'border-spacing-x-max'
  | 'border-spacing-y-0'
  | 'border-spacing-y-1'
  | 'border-spacing-y-2'
  | 'border-spacing-y-3'
  | 'border-spacing-y-4'
  | 'border-spacing-y-5'
  | 'border-spacing-y-6'
  | 'border-spacing-y-7'
  | 'border-spacing-y-8'
  | 'border-spacing-y-9'
  | 'border-spacing-y-10'
  | 'border-spacing-y-11'
  | 'border-spacing-y-12'
  | 'border-spacing-y-13'
  | 'border-spacing-y-14'
  | 'border-spacing-y-15'
  | 'border-spacing-y-16'
  | 'border-spacing-y-17'
  | 'border-spacing-y-18'
  | 'border-spacing-y-19'
  | 'border-spacing-y-20'
  | 'border-spacing-y-21'
  | 'border-spacing-y-22'
  | 'border-spacing-y-23'
  | 'border-spacing-y-24'
  | 'border-spacing-y-25'
  | 'border-spacing-y-26'
  | 'border-spacing-y-27'
  | 'border-spacing-y-28'
  | 'border-spacing-y-29'
  | 'border-spacing-y-30'
  | 'border-spacing-y-32'
  | 'border-spacing-y-33'
  | 'border-spacing-y-34'
  | 'border-spacing-y-35'
  | 'border-spacing-y-36'
  | 'border-spacing-y-37'
  | 'border-spacing-y-38'
  | 'border-spacing-y-40'
  | 'border-spacing-y-41'
  | 'border-spacing-y-42'
  | 'border-spacing-y-44'
  | 'border-spacing-y-45'
  | 'border-spacing-y-46'
  | 'border-spacing-y-48'
  | 'border-spacing-y-50'
  | 'border-spacing-y-51'
  | 'border-spacing-y-52'
  | 'border-spacing-y-53'
  | 'border-spacing-y-56'
  | 'border-spacing-y-58'
  | 'border-spacing-y-60'
  | 'border-spacing-y-61'
  | 'border-spacing-y-64'
  | 'border-spacing-y-66'
  | 'border-spacing-y-70'
  | 'border-spacing-y-71'
  | 'border-spacing-y-72'
  | 'border-spacing-y-77'
  | 'border-spacing-y-80'
  | 'border-spacing-y-82'
  | 'border-spacing-y-85'
  | 'border-spacing-y-87'
  | 'border-spacing-y-88'
  | 'border-spacing-y-90'
  | 'border-spacing-y-95'
  | 'border-spacing-y-96'
  | 'border-spacing-y-100'
  | 'border-spacing-y-110'
  | 'border-spacing-y-112'
  | 'border-spacing-y-116'
  | 'border-spacing-y-120'
  | 'border-spacing-y-124'
  | 'border-spacing-y-126'
  | 'border-spacing-y-130'
  | 'border-spacing-y-132'
  | 'border-spacing-y-144'
  | 'border-spacing-y-150'
  | 'border-spacing-y-156'
  | 'border-spacing-y-160'
  | 'border-spacing-y-164'
  | 'border-spacing-y-170'
  | 'border-spacing-y-172'
  | 'border-spacing-y-178'
  | 'border-spacing-y-180'
  | 'border-spacing-y-182'
  | 'border-spacing-y-186'
  | 'border-spacing-y-190'
  | 'border-spacing-y-196'
  | 'border-spacing-y-200'
  | 'border-spacing-y-202'
  | 'border-spacing-y-216'
  | 'border-spacing-y-220'
  | 'border-spacing-y-222'
  | 'border-spacing-y-235'
  | 'border-spacing-y-248'
  | 'border-spacing-y-255'
  | 'border-spacing-y-258'
  | 'border-spacing-y-264'
  | 'border-spacing-y-270'
  | 'border-spacing-y-275'
  | 'border-spacing-y-280'
  | 'border-spacing-y-296'
  | 'border-spacing-y-300'
  | 'border-spacing-y-304'
  | 'border-spacing-y-310'
  | 'border-spacing-y-320'
  | 'border-spacing-y-324'
  | 'border-spacing-y-330'
  | 'border-spacing-y-336'
  | 'border-spacing-y-340'
  | 'border-spacing-y-350'
  | 'border-spacing-y-360'
  | 'border-spacing-y-370'
  | 'border-spacing-y-380'
  | 'border-spacing-y-390'
  | 'border-spacing-y-632'
  | 'border-spacing-y-680'
  | 'border-spacing-y-720'
  | 'border-spacing-y-fit'
  | 'border-spacing-y-max'

export type TTables =
  | TBorderCollapse
  | TTableLayout
  | TBorderSpacing

export type TMixBlendMode =
  | 'mix-blend-normal'
  | 'mix-blend-multiply'
  | 'mix-blend-screen'
  | 'mix-blend-overlay'
  | 'mix-blend-darken'
  | 'mix-blend-lighten'
  | 'mix-blend-color-dodge'
  | 'mix-blend-color-burn'
  | 'mix-blend-hard-light'
  | 'mix-blend-soft-light'
  | 'mix-blend-difference'
  | 'mix-blend-exclusion'
  | 'mix-blend-hue'
  | 'mix-blend-saturation'
  | 'mix-blend-color'
  | 'mix-blend-luminosity'
  | 'mix-blend-plus-lighter'

export type TBackgroundBlendMode =
  | 'bg-blend-normal'
  | 'bg-blend-multiply'
  | 'bg-blend-screen'
  | 'bg-blend-overlay'
  | 'bg-blend-darken'
  | 'bg-blend-lighten'
  | 'bg-blend-color-dodge'
  | 'bg-blend-color-burn'
  | 'bg-blend-hard-light'
  | 'bg-blend-soft-light'
  | 'bg-blend-difference'
  | 'bg-blend-exclusion'
  | 'bg-blend-hue'
  | 'bg-blend-saturation'
  | 'bg-blend-color'
  | 'bg-blend-luminosity'

export type TBoxShadow =
  | 'shadow-sm'
  | 'shadow'
  | 'shadow-md'
  | 'shadow-md-top'
  | 'shadow-lg'
  | 'shadow-xl'
  | 'shadow-dropdown'
  | 'shadow-none'
  | 'shadow-mdRotate'
  | 'shadow-brand-primary-1'
  | 'shadow-brand-primary-2'
  | 'shadow-brand-primary-3'
  | 'shadow-brand-primary-4'
  | 'shadow-brand-primary-5'
  | 'shadow-brand-secondary-1'
  | 'shadow-brand-secondary-5'
  | 'shadow-brand-tertiary-1'
  | 'shadow-brand-tertiary-2'
  | 'shadow-brand-tertiary-3'
  | 'shadow-brand-tertiary-4'
  | 'shadow-brand-tertiary-5'
  | 'shadow-dark-gray'
  | 'shadow-module'

export type TBoxShadowColor =
  | 'shadow-inherit'
  | 'shadow-transparent'
  | 'shadow-current'
  | 'shadow-black'
  | 'shadow-white'
  | 'shadow-brand-primary-1'
  | 'shadow-brand-primary-2'
  | 'shadow-brand-primary-3'
  | 'shadow-brand-primary-4'
  | 'shadow-brand-primary-5'
  | 'shadow-brand-secondary-1'
  | 'shadow-brand-secondary-2'
  | 'shadow-brand-secondary-3'
  | 'shadow-brand-secondary-4'
  | 'shadow-brand-secondary-5'
  | 'shadow-brand-tertiary-1'
  | 'shadow-brand-tertiary-2'
  | 'shadow-brand-tertiary-3'
  | 'shadow-brand-tertiary-4'
  | 'shadow-brand-tertiary-5'
  | 'shadow-gray-1'
  | 'shadow-gray-2'
  | 'shadow-gray-3'
  | 'shadow-gray-4'
  | 'shadow-gray-5'
  | 'shadow-gray-6'
  | 'shadow-gray-7'
  | 'shadow-gray-8'
  | 'shadow-gray-9'
  | 'shadow-social-linkedin'
  | 'shadow-social-facebook'
  | 'shadow-social-twitter'
  | 'shadow-social-instagram'
  | 'shadow-informa-bar'
  | 'shadow-danger'
  | 'shadow-error'

export type TOpacity =
  | 'opacity-0'
  | 'opacity-5'
  | 'opacity-10'
  | 'opacity-20'
  | 'opacity-25'
  | 'opacity-30'
  | 'opacity-40'
  | 'opacity-50'
  | 'opacity-60'
  | 'opacity-70'
  | 'opacity-75'
  | 'opacity-80'
  | 'opacity-90'
  | 'opacity-95'
  | 'opacity-100'

export type TEffects =
  | TMixBlendMode
  | TBackgroundBlendMode
  | TBoxShadow
  | TBoxShadowColor
  | TOpacity

export type TTransitionProperty =
  | 'transition-none'
  | 'transition-all'
  | 'transition'
  | 'transition-colors'
  | 'transition-opacity'
  | 'transition-shadow'
  | 'transition-transform'

export type TTransitionDuration =
  | 'duration-75'
  | 'duration-100'
  | 'duration-150'
  | 'duration-200'
  | 'duration-300'
  | 'duration-500'
  | 'duration-700'
  | 'duration-1000'
  | 'duration'

export type TTransitionTimingFunction =
  | 'ease-linear'
  | 'ease-in'
  | 'ease-out'
  | 'ease-in-out'

export type TTransitionDelay =
  | 'delay-75'
  | 'delay-100'
  | 'delay-150'
  | 'delay-200'
  | 'delay-300'
  | 'delay-500'
  | 'delay-700'
  | 'delay-1000'

export type TAnimation =
  | 'animate-fadeIn'

export type TTransitionsAndAnimations =
  | TTransitionProperty
  | TTransitionDuration
  | TTransitionTimingFunction
  | TTransitionDelay
  | TAnimation

export type TFilter =
  | 'filter'
  | 'filter-none'

export type TBackdropFilter =
  | 'backdrop-filter'
  | 'backdrop-filter-none'

export type TBlur =
  | 'blur-0'
  | 'blur-none'
  | 'blur-sm'
  | 'blur'
  | 'blur-md'
  | 'blur-lg'
  | 'blur-xl'
  | 'blur-2xl'
  | 'blur-3xl'

export type TBrightness =
  | 'brightness-0'
  | 'brightness-50'
  | 'brightness-75'
  | 'brightness-90'
  | 'brightness-95'
  | 'brightness-100'
  | 'brightness-105'
  | 'brightness-110'
  | 'brightness-125'
  | 'brightness-150'
  | 'brightness-200'

export type TContrast =
  | 'contrast-0'
  | 'contrast-50'
  | 'contrast-75'
  | 'contrast-100'
  | 'contrast-125'
  | 'contrast-150'
  | 'contrast-200'

export type TDropShadow =
  | 'drop-shadow-sm'
  | 'drop-shadow'
  | 'drop-shadow-md'
  | 'drop-shadow-lg'
  | 'drop-shadow-xl'
  | 'drop-shadow-2xl'
  | 'drop-shadow-none'

export type TGrayscale =
  | 'grayscale-0'
  | 'grayscale'

export type THueRotate =
  | 'hue-rotate-0'
  | 'hue-rotate-15'
  | 'hue-rotate-30'
  | 'hue-rotate-60'
  | 'hue-rotate-90'
  | 'hue-rotate-180'

export type TInvert =
  | 'invert-0'
  | 'invert'

export type TSaturate =
  | 'saturate-0'
  | 'saturate-50'
  | 'saturate-100'
  | 'saturate-150'
  | 'saturate-200'

export type TSepia =
  | 'sepia-0'
  | 'sepia'

export type TBackdropBlur =
  | 'backdrop-blur-0'
  | 'backdrop-blur-none'
  | 'backdrop-blur-sm'
  | 'backdrop-blur'
  | 'backdrop-blur-md'
  | 'backdrop-blur-lg'
  | 'backdrop-blur-xl'
  | 'backdrop-blur-2xl'
  | 'backdrop-blur-3xl'

export type TBackdropBrightness =
  | 'backdrop-brightness-0'
  | 'backdrop-brightness-50'
  | 'backdrop-brightness-75'
  | 'backdrop-brightness-90'
  | 'backdrop-brightness-95'
  | 'backdrop-brightness-100'
  | 'backdrop-brightness-105'
  | 'backdrop-brightness-110'
  | 'backdrop-brightness-125'
  | 'backdrop-brightness-150'
  | 'backdrop-brightness-200'

export type TBackdropContrast =
  | 'backdrop-contrast-0'
  | 'backdrop-contrast-50'
  | 'backdrop-contrast-75'
  | 'backdrop-contrast-100'
  | 'backdrop-contrast-125'
  | 'backdrop-contrast-150'
  | 'backdrop-contrast-200'

export type TBackdropGrayscale =
  | 'backdrop-grayscale-0'
  | 'backdrop-grayscale'

export type TBackdropHueRotate =
  | 'backdrop-hue-rotate-0'
  | 'backdrop-hue-rotate-15'
  | 'backdrop-hue-rotate-30'
  | 'backdrop-hue-rotate-60'
  | 'backdrop-hue-rotate-90'
  | 'backdrop-hue-rotate-180'

export type TBackdropInvert =
  | 'backdrop-invert-0'
  | 'backdrop-invert'

export type TBackdropOpacity =
  | 'backdrop-opacity-0'
  | 'backdrop-opacity-5'
  | 'backdrop-opacity-10'
  | 'backdrop-opacity-20'
  | 'backdrop-opacity-25'
  | 'backdrop-opacity-30'
  | 'backdrop-opacity-40'
  | 'backdrop-opacity-50'
  | 'backdrop-opacity-60'
  | 'backdrop-opacity-70'
  | 'backdrop-opacity-75'
  | 'backdrop-opacity-80'
  | 'backdrop-opacity-90'
  | 'backdrop-opacity-95'
  | 'backdrop-opacity-100'

export type TBackdropSaturate =
  | 'backdrop-saturate-0'
  | 'backdrop-saturate-50'
  | 'backdrop-saturate-100'
  | 'backdrop-saturate-150'
  | 'backdrop-saturate-200'

export type TBackdropSepia =
  | 'backdrop-sepia-0'
  | 'backdrop-sepia'

export type TFilters =
  | TFilter
  | TBackdropFilter
  | TBlur
  | TBrightness
  | TContrast
  | TDropShadow
  | TGrayscale
  | THueRotate
  | TInvert
  | TSaturate
  | TSepia
  | TBackdropBlur
  | TBackdropBrightness
  | TBackdropContrast
  | TBackdropGrayscale
  | TBackdropHueRotate
  | TBackdropInvert
  | TBackdropOpacity
  | TBackdropSaturate
  | TBackdropSepia

export type TFlexDirection =
  | 'flex-row'
  | 'flex-row-reverse'
  | 'flex-col'
  | 'flex-col-reverse'

export type TFlexWrap =
  | 'flex-nowrap'
  | 'flex-wrap'
  | 'flex-wrap-reverse'

export type TAlignItems =
  | 'items-stretch'
  | 'items-start'
  | 'items-center'
  | 'items-end'
  | 'items-baseline'

export type TAlignContent =
  | 'content-start'
  | 'content-center'
  | 'content-end'
  | 'content-between'
  | 'content-around'

export type TAlignSelf =
  | 'self-auto'
  | 'self-start'
  | 'self-center'
  | 'self-end'
  | 'self-stretch'
  | 'self-baseline'

export type TPlaceContent =
  | 'place-content-start'
  | 'place-content-center'
  | 'place-content-end'
  | 'place-content-between'
  | 'place-content-around'
  | 'place-content-evenly'
  | 'place-content-stretch'

export type TPlaceItems =
  | 'place-items-auto'
  | 'place-items-start'
  | 'place-items-center'
  | 'place-items-end'
  | 'place-items-stretch'

export type TPlaceSelf =
  | 'place-self-auto'
  | 'place-self-start'
  | 'place-self-center'
  | 'place-self-end'
  | 'place-self-stretch'

export type TJustifyContent =
  | 'justify-start'
  | 'justify-center'
  | 'justify-end'
  | 'justify-between'
  | 'justify-around'
  | 'justify-evenly'

export type TJustifyItems =
  | 'justify-items-auto'
  | 'justify-items-start'
  | 'justify-items-center'
  | 'justify-items-end'
  | 'justify-items-stretch'

export type TJustifySelf =
  | 'justify-self-auto'
  | 'justify-self-start'
  | 'justify-self-center'
  | 'justify-self-end'
  | 'justify-self-stretch'

export type TFlex =
  | 'flex-initial'
  | 'flex-1'
  | 'flex-auto'
  | 'flex-none'

export type TFlexBasis =
  | 'basis-0'
  | 'basis-1'
  | 'basis-2'
  | 'basis-3'
  | 'basis-4'
  | 'basis-5'
  | 'basis-6'
  | 'basis-7'
  | 'basis-8'
  | 'basis-9'
  | 'basis-10'
  | 'basis-11'
  | 'basis-12'
  | 'basis-13'
  | 'basis-14'
  | 'basis-15'
  | 'basis-16'
  | 'basis-17'
  | 'basis-18'
  | 'basis-19'
  | 'basis-20'
  | 'basis-21'
  | 'basis-22'
  | 'basis-23'
  | 'basis-24'
  | 'basis-25'
  | 'basis-26'
  | 'basis-27'
  | 'basis-28'
  | 'basis-29'
  | 'basis-30'
  | 'basis-32'
  | 'basis-33'
  | 'basis-34'
  | 'basis-35'
  | 'basis-36'
  | 'basis-37'
  | 'basis-38'
  | 'basis-40'
  | 'basis-41'
  | 'basis-42'
  | 'basis-44'
  | 'basis-45'
  | 'basis-46'
  | 'basis-48'
  | 'basis-50'
  | 'basis-51'
  | 'basis-52'
  | 'basis-53'
  | 'basis-56'
  | 'basis-58'
  | 'basis-60'
  | 'basis-61'
  | 'basis-64'
  | 'basis-66'
  | 'basis-70'
  | 'basis-71'
  | 'basis-72'
  | 'basis-77'
  | 'basis-80'
  | 'basis-82'
  | 'basis-85'
  | 'basis-87'
  | 'basis-88'
  | 'basis-90'
  | 'basis-95'
  | 'basis-96'
  | 'basis-100'
  | 'basis-110'
  | 'basis-112'
  | 'basis-116'
  | 'basis-120'
  | 'basis-124'
  | 'basis-126'
  | 'basis-130'
  | 'basis-132'
  | 'basis-144'
  | 'basis-150'
  | 'basis-156'
  | 'basis-160'
  | 'basis-164'
  | 'basis-170'
  | 'basis-172'
  | 'basis-178'
  | 'basis-180'
  | 'basis-182'
  | 'basis-186'
  | 'basis-190'
  | 'basis-196'
  | 'basis-200'
  | 'basis-202'
  | 'basis-216'
  | 'basis-220'
  | 'basis-222'
  | 'basis-235'
  | 'basis-248'
  | 'basis-255'
  | 'basis-258'
  | 'basis-264'
  | 'basis-270'
  | 'basis-275'
  | 'basis-280'
  | 'basis-296'
  | 'basis-300'
  | 'basis-304'
  | 'basis-310'
  | 'basis-320'
  | 'basis-324'
  | 'basis-330'
  | 'basis-336'
  | 'basis-340'
  | 'basis-350'
  | 'basis-360'
  | 'basis-370'
  | 'basis-380'
  | 'basis-390'
  | 'basis-632'
  | 'basis-680'
  | 'basis-720'
  | 'basis-auto'
  | 'basis-fit'
  | 'basis-max'
  | 'basis-1/2'
  | 'basis-1/3'
  | 'basis-2/3'
  | 'basis-1/4'
  | 'basis-2/4'
  | 'basis-3/4'
  | 'basis-1/5'
  | 'basis-2/5'
  | 'basis-3/5'
  | 'basis-4/5'
  | 'basis-1/6'
  | 'basis-2/6'
  | 'basis-3/6'
  | 'basis-4/6'
  | 'basis-5/6'
  | 'basis-1/12'
  | 'basis-2/12'
  | 'basis-3/12'
  | 'basis-4/12'
  | 'basis-5/12'
  | 'basis-6/12'
  | 'basis-7/12'
  | 'basis-8/12'
  | 'basis-9/12'
  | 'basis-10/12'
  | 'basis-11/12'
  | 'basis-full'

export type TFlexGrow =
  | 'grow-0'
  | 'grow'

export type TFlexShrink =
  | 'shrink-0'
  | 'shrink'

export type TOrder =
  | 'order-1'
  | 'order-2'
  | 'order-3'
  | 'order-4'
  | 'order-5'
  | 'order-6'
  | 'order-7'
  | 'order-8'
  | 'order-9'
  | 'order-10'
  | 'order-11'
  | 'order-12'
  | 'order-first'
  | 'order-last'
  | 'order-none'

export type TFlexBox =
  | TFlexDirection
  | TFlexWrap
  | TAlignItems
  | TAlignContent
  | TAlignSelf
  | TPlaceContent
  | TPlaceItems
  | TPlaceSelf
  | TJustifyContent
  | TJustifyItems
  | TJustifySelf
  | TFlex
  | TFlexBasis
  | TFlexGrow
  | TFlexShrink
  | TOrder

export type TGridAutoFlow =
  | 'grid-flow-row'
  | 'grid-flow-col'
  | 'grid-flow-dense'
  | 'grid-flow-row-dense'
  | 'grid-flow-col-dense'

export type TGridTemplateColumns =
  | 'grid-cols-1'
  | 'grid-cols-2'
  | 'grid-cols-3'
  | 'grid-cols-4'
  | 'grid-cols-5'
  | 'grid-cols-6'
  | 'grid-cols-7'
  | 'grid-cols-8'
  | 'grid-cols-9'
  | 'grid-cols-10'
  | 'grid-cols-11'
  | 'grid-cols-12'
  | 'grid-cols-none'

export type TGridAutoColumns =
  | 'auto-cols-auto'
  | 'auto-cols-min'
  | 'auto-cols-max'
  | 'auto-cols-fr'

export type TGridColumn =
  | 'col-auto'
  | 'col-span-1'
  | 'col-span-2'
  | 'col-span-3'
  | 'col-span-4'
  | 'col-span-5'
  | 'col-span-6'
  | 'col-span-7'
  | 'col-span-8'
  | 'col-span-9'
  | 'col-span-10'
  | 'col-span-11'
  | 'col-span-12'
  | 'col-span-full'

export type TGridColumnStart =
  | 'col-start-1'
  | 'col-start-2'
  | 'col-start-3'
  | 'col-start-4'
  | 'col-start-5'
  | 'col-start-6'
  | 'col-start-7'
  | 'col-start-8'
  | 'col-start-9'
  | 'col-start-10'
  | 'col-start-11'
  | 'col-start-12'
  | 'col-start-13'
  | 'col-start-auto'

export type TGridColumnEnd =
  | 'col-end-1'
  | 'col-end-2'
  | 'col-end-3'
  | 'col-end-4'
  | 'col-end-5'
  | 'col-end-6'
  | 'col-end-7'
  | 'col-end-8'
  | 'col-end-9'
  | 'col-end-10'
  | 'col-end-11'
  | 'col-end-12'
  | 'col-end-13'
  | 'col-end-auto'

export type TGridTemplateRows =
  | 'grid-rows-1'
  | 'grid-rows-2'
  | 'grid-rows-3'
  | 'grid-rows-4'
  | 'grid-rows-5'
  | 'grid-rows-6'
  | 'grid-rows-none'

export type TGridAutoRows =
  | 'auto-rows-auto'
  | 'auto-rows-min'
  | 'auto-rows-max'
  | 'auto-rows-fr'

export type TGridRow =
  | 'row-auto'
  | 'row-span-1'
  | 'row-span-2'
  | 'row-span-3'
  | 'row-span-4'
  | 'row-span-5'
  | 'row-span-6'
  | 'row-span-full'

export type TGridRowStart =
  | 'row-start-1'
  | 'row-start-2'
  | 'row-start-3'
  | 'row-start-4'
  | 'row-start-5'
  | 'row-start-6'
  | 'row-start-7'
  | 'row-start-auto'

export type TGridRowEnd =
  | 'row-end-1'
  | 'row-end-2'
  | 'row-end-3'
  | 'row-end-4'
  | 'row-end-5'
  | 'row-end-6'
  | 'row-end-7'
  | 'row-end-auto'

export type TGap =
  | 'gap-0'
  | 'gap-1'
  | 'gap-2'
  | 'gap-3'
  | 'gap-4'
  | 'gap-5'
  | 'gap-6'
  | 'gap-7'
  | 'gap-8'
  | 'gap-9'
  | 'gap-10'
  | 'gap-11'
  | 'gap-12'
  | 'gap-13'
  | 'gap-14'
  | 'gap-15'
  | 'gap-16'
  | 'gap-17'
  | 'gap-18'
  | 'gap-19'
  | 'gap-20'
  | 'gap-21'
  | 'gap-22'
  | 'gap-23'
  | 'gap-24'
  | 'gap-25'
  | 'gap-26'
  | 'gap-27'
  | 'gap-28'
  | 'gap-29'
  | 'gap-30'
  | 'gap-32'
  | 'gap-33'
  | 'gap-34'
  | 'gap-35'
  | 'gap-36'
  | 'gap-37'
  | 'gap-38'
  | 'gap-40'
  | 'gap-41'
  | 'gap-42'
  | 'gap-44'
  | 'gap-45'
  | 'gap-46'
  | 'gap-48'
  | 'gap-50'
  | 'gap-51'
  | 'gap-52'
  | 'gap-53'
  | 'gap-56'
  | 'gap-58'
  | 'gap-60'
  | 'gap-61'
  | 'gap-64'
  | 'gap-66'
  | 'gap-70'
  | 'gap-71'
  | 'gap-72'
  | 'gap-77'
  | 'gap-80'
  | 'gap-82'
  | 'gap-85'
  | 'gap-87'
  | 'gap-88'
  | 'gap-90'
  | 'gap-95'
  | 'gap-96'
  | 'gap-100'
  | 'gap-110'
  | 'gap-112'
  | 'gap-116'
  | 'gap-120'
  | 'gap-124'
  | 'gap-126'
  | 'gap-130'
  | 'gap-132'
  | 'gap-144'
  | 'gap-150'
  | 'gap-156'
  | 'gap-160'
  | 'gap-164'
  | 'gap-170'
  | 'gap-172'
  | 'gap-178'
  | 'gap-180'
  | 'gap-182'
  | 'gap-186'
  | 'gap-190'
  | 'gap-196'
  | 'gap-200'
  | 'gap-202'
  | 'gap-216'
  | 'gap-220'
  | 'gap-222'
  | 'gap-235'
  | 'gap-248'
  | 'gap-255'
  | 'gap-258'
  | 'gap-264'
  | 'gap-270'
  | 'gap-275'
  | 'gap-280'
  | 'gap-296'
  | 'gap-300'
  | 'gap-304'
  | 'gap-310'
  | 'gap-320'
  | 'gap-324'
  | 'gap-330'
  | 'gap-336'
  | 'gap-340'
  | 'gap-350'
  | 'gap-360'
  | 'gap-370'
  | 'gap-380'
  | 'gap-390'
  | 'gap-632'
  | 'gap-680'
  | 'gap-720'
  | 'gap-fit'
  | 'gap-max'
  | 'gap-y-0'
  | 'gap-y-1'
  | 'gap-y-2'
  | 'gap-y-3'
  | 'gap-y-4'
  | 'gap-y-5'
  | 'gap-y-6'
  | 'gap-y-7'
  | 'gap-y-8'
  | 'gap-y-9'
  | 'gap-y-10'
  | 'gap-y-11'
  | 'gap-y-12'
  | 'gap-y-13'
  | 'gap-y-14'
  | 'gap-y-15'
  | 'gap-y-16'
  | 'gap-y-17'
  | 'gap-y-18'
  | 'gap-y-19'
  | 'gap-y-20'
  | 'gap-y-21'
  | 'gap-y-22'
  | 'gap-y-23'
  | 'gap-y-24'
  | 'gap-y-25'
  | 'gap-y-26'
  | 'gap-y-27'
  | 'gap-y-28'
  | 'gap-y-29'
  | 'gap-y-30'
  | 'gap-y-32'
  | 'gap-y-33'
  | 'gap-y-34'
  | 'gap-y-35'
  | 'gap-y-36'
  | 'gap-y-37'
  | 'gap-y-38'
  | 'gap-y-40'
  | 'gap-y-41'
  | 'gap-y-42'
  | 'gap-y-44'
  | 'gap-y-45'
  | 'gap-y-46'
  | 'gap-y-48'
  | 'gap-y-50'
  | 'gap-y-51'
  | 'gap-y-52'
  | 'gap-y-53'
  | 'gap-y-56'
  | 'gap-y-58'
  | 'gap-y-60'
  | 'gap-y-61'
  | 'gap-y-64'
  | 'gap-y-66'
  | 'gap-y-70'
  | 'gap-y-71'
  | 'gap-y-72'
  | 'gap-y-77'
  | 'gap-y-80'
  | 'gap-y-82'
  | 'gap-y-85'
  | 'gap-y-87'
  | 'gap-y-88'
  | 'gap-y-90'
  | 'gap-y-95'
  | 'gap-y-96'
  | 'gap-y-100'
  | 'gap-y-110'
  | 'gap-y-112'
  | 'gap-y-116'
  | 'gap-y-120'
  | 'gap-y-124'
  | 'gap-y-126'
  | 'gap-y-130'
  | 'gap-y-132'
  | 'gap-y-144'
  | 'gap-y-150'
  | 'gap-y-156'
  | 'gap-y-160'
  | 'gap-y-164'
  | 'gap-y-170'
  | 'gap-y-172'
  | 'gap-y-178'
  | 'gap-y-180'
  | 'gap-y-182'
  | 'gap-y-186'
  | 'gap-y-190'
  | 'gap-y-196'
  | 'gap-y-200'
  | 'gap-y-202'
  | 'gap-y-216'
  | 'gap-y-220'
  | 'gap-y-222'
  | 'gap-y-235'
  | 'gap-y-248'
  | 'gap-y-255'
  | 'gap-y-258'
  | 'gap-y-264'
  | 'gap-y-270'
  | 'gap-y-275'
  | 'gap-y-280'
  | 'gap-y-296'
  | 'gap-y-300'
  | 'gap-y-304'
  | 'gap-y-310'
  | 'gap-y-320'
  | 'gap-y-324'
  | 'gap-y-330'
  | 'gap-y-336'
  | 'gap-y-340'
  | 'gap-y-350'
  | 'gap-y-360'
  | 'gap-y-370'
  | 'gap-y-380'
  | 'gap-y-390'
  | 'gap-y-632'
  | 'gap-y-680'
  | 'gap-y-720'
  | 'gap-y-fit'
  | 'gap-y-max'
  | 'gap-x-0'
  | 'gap-x-1'
  | 'gap-x-2'
  | 'gap-x-3'
  | 'gap-x-4'
  | 'gap-x-5'
  | 'gap-x-6'
  | 'gap-x-7'
  | 'gap-x-8'
  | 'gap-x-9'
  | 'gap-x-10'
  | 'gap-x-11'
  | 'gap-x-12'
  | 'gap-x-13'
  | 'gap-x-14'
  | 'gap-x-15'
  | 'gap-x-16'
  | 'gap-x-17'
  | 'gap-x-18'
  | 'gap-x-19'
  | 'gap-x-20'
  | 'gap-x-21'
  | 'gap-x-22'
  | 'gap-x-23'
  | 'gap-x-24'
  | 'gap-x-25'
  | 'gap-x-26'
  | 'gap-x-27'
  | 'gap-x-28'
  | 'gap-x-29'
  | 'gap-x-30'
  | 'gap-x-32'
  | 'gap-x-33'
  | 'gap-x-34'
  | 'gap-x-35'
  | 'gap-x-36'
  | 'gap-x-37'
  | 'gap-x-38'
  | 'gap-x-40'
  | 'gap-x-41'
  | 'gap-x-42'
  | 'gap-x-44'
  | 'gap-x-45'
  | 'gap-x-46'
  | 'gap-x-48'
  | 'gap-x-50'
  | 'gap-x-51'
  | 'gap-x-52'
  | 'gap-x-53'
  | 'gap-x-56'
  | 'gap-x-58'
  | 'gap-x-60'
  | 'gap-x-61'
  | 'gap-x-64'
  | 'gap-x-66'
  | 'gap-x-70'
  | 'gap-x-71'
  | 'gap-x-72'
  | 'gap-x-77'
  | 'gap-x-80'
  | 'gap-x-82'
  | 'gap-x-85'
  | 'gap-x-87'
  | 'gap-x-88'
  | 'gap-x-90'
  | 'gap-x-95'
  | 'gap-x-96'
  | 'gap-x-100'
  | 'gap-x-110'
  | 'gap-x-112'
  | 'gap-x-116'
  | 'gap-x-120'
  | 'gap-x-124'
  | 'gap-x-126'
  | 'gap-x-130'
  | 'gap-x-132'
  | 'gap-x-144'
  | 'gap-x-150'
  | 'gap-x-156'
  | 'gap-x-160'
  | 'gap-x-164'
  | 'gap-x-170'
  | 'gap-x-172'
  | 'gap-x-178'
  | 'gap-x-180'
  | 'gap-x-182'
  | 'gap-x-186'
  | 'gap-x-190'
  | 'gap-x-196'
  | 'gap-x-200'
  | 'gap-x-202'
  | 'gap-x-216'
  | 'gap-x-220'
  | 'gap-x-222'
  | 'gap-x-235'
  | 'gap-x-248'
  | 'gap-x-255'
  | 'gap-x-258'
  | 'gap-x-264'
  | 'gap-x-270'
  | 'gap-x-275'
  | 'gap-x-280'
  | 'gap-x-296'
  | 'gap-x-300'
  | 'gap-x-304'
  | 'gap-x-310'
  | 'gap-x-320'
  | 'gap-x-324'
  | 'gap-x-330'
  | 'gap-x-336'
  | 'gap-x-340'
  | 'gap-x-350'
  | 'gap-x-360'
  | 'gap-x-370'
  | 'gap-x-380'
  | 'gap-x-390'
  | 'gap-x-632'
  | 'gap-x-680'
  | 'gap-x-720'
  | 'gap-x-fit'
  | 'gap-x-max'

export type TGrid =
  | TGridAutoFlow
  | TGridTemplateColumns
  | TGridAutoColumns
  | TGridColumn
  | TGridColumnStart
  | TGridColumnEnd
  | TGridTemplateRows
  | TGridAutoRows
  | TGridRow
  | TGridRowStart
  | TGridRowEnd
  | TGap

export type TGroup =
  | 'group'

export type TPeer =
  | 'peer'

export type TGrouping =
  | TGroup
  | TPeer

export type TPadding =
  | 'p-0'
  | 'p-1'
  | 'p-2'
  | 'p-3'
  | 'p-4'
  | 'p-5'
  | 'p-6'
  | 'p-7'
  | 'p-8'
  | 'p-9'
  | 'p-10'
  | 'p-11'
  | 'p-12'
  | 'p-13'
  | 'p-14'
  | 'p-15'
  | 'p-16'
  | 'p-17'
  | 'p-18'
  | 'p-19'
  | 'p-20'
  | 'p-21'
  | 'p-22'
  | 'p-23'
  | 'p-24'
  | 'p-25'
  | 'p-26'
  | 'p-27'
  | 'p-28'
  | 'p-29'
  | 'p-30'
  | 'p-32'
  | 'p-33'
  | 'p-34'
  | 'p-35'
  | 'p-36'
  | 'p-37'
  | 'p-38'
  | 'p-40'
  | 'p-41'
  | 'p-42'
  | 'p-44'
  | 'p-45'
  | 'p-46'
  | 'p-48'
  | 'p-50'
  | 'p-51'
  | 'p-52'
  | 'p-53'
  | 'p-56'
  | 'p-58'
  | 'p-60'
  | 'p-61'
  | 'p-64'
  | 'p-66'
  | 'p-70'
  | 'p-71'
  | 'p-72'
  | 'p-77'
  | 'p-80'
  | 'p-82'
  | 'p-85'
  | 'p-87'
  | 'p-88'
  | 'p-90'
  | 'p-95'
  | 'p-96'
  | 'p-100'
  | 'p-110'
  | 'p-112'
  | 'p-116'
  | 'p-120'
  | 'p-124'
  | 'p-126'
  | 'p-130'
  | 'p-132'
  | 'p-144'
  | 'p-150'
  | 'p-156'
  | 'p-160'
  | 'p-164'
  | 'p-170'
  | 'p-172'
  | 'p-178'
  | 'p-180'
  | 'p-182'
  | 'p-186'
  | 'p-190'
  | 'p-196'
  | 'p-200'
  | 'p-202'
  | 'p-216'
  | 'p-220'
  | 'p-222'
  | 'p-235'
  | 'p-248'
  | 'p-255'
  | 'p-258'
  | 'p-264'
  | 'p-270'
  | 'p-275'
  | 'p-280'
  | 'p-296'
  | 'p-300'
  | 'p-304'
  | 'p-310'
  | 'p-320'
  | 'p-324'
  | 'p-330'
  | 'p-336'
  | 'p-340'
  | 'p-350'
  | 'p-360'
  | 'p-370'
  | 'p-380'
  | 'p-390'
  | 'p-632'
  | 'p-680'
  | 'p-720'
  | 'p-fit'
  | 'p-max'
  | 'py-0'
  | 'py-1'
  | 'py-2'
  | 'py-3'
  | 'py-4'
  | 'py-5'
  | 'py-6'
  | 'py-7'
  | 'py-8'
  | 'py-9'
  | 'py-10'
  | 'py-11'
  | 'py-12'
  | 'py-13'
  | 'py-14'
  | 'py-15'
  | 'py-16'
  | 'py-17'
  | 'py-18'
  | 'py-19'
  | 'py-20'
  | 'py-21'
  | 'py-22'
  | 'py-23'
  | 'py-24'
  | 'py-25'
  | 'py-26'
  | 'py-27'
  | 'py-28'
  | 'py-29'
  | 'py-30'
  | 'py-32'
  | 'py-33'
  | 'py-34'
  | 'py-35'
  | 'py-36'
  | 'py-37'
  | 'py-38'
  | 'py-40'
  | 'py-41'
  | 'py-42'
  | 'py-44'
  | 'py-45'
  | 'py-46'
  | 'py-48'
  | 'py-50'
  | 'py-51'
  | 'py-52'
  | 'py-53'
  | 'py-56'
  | 'py-58'
  | 'py-60'
  | 'py-61'
  | 'py-64'
  | 'py-66'
  | 'py-70'
  | 'py-71'
  | 'py-72'
  | 'py-77'
  | 'py-80'
  | 'py-82'
  | 'py-85'
  | 'py-87'
  | 'py-88'
  | 'py-90'
  | 'py-95'
  | 'py-96'
  | 'py-100'
  | 'py-110'
  | 'py-112'
  | 'py-116'
  | 'py-120'
  | 'py-124'
  | 'py-126'
  | 'py-130'
  | 'py-132'
  | 'py-144'
  | 'py-150'
  | 'py-156'
  | 'py-160'
  | 'py-164'
  | 'py-170'
  | 'py-172'
  | 'py-178'
  | 'py-180'
  | 'py-182'
  | 'py-186'
  | 'py-190'
  | 'py-196'
  | 'py-200'
  | 'py-202'
  | 'py-216'
  | 'py-220'
  | 'py-222'
  | 'py-235'
  | 'py-248'
  | 'py-255'
  | 'py-258'
  | 'py-264'
  | 'py-270'
  | 'py-275'
  | 'py-280'
  | 'py-296'
  | 'py-300'
  | 'py-304'
  | 'py-310'
  | 'py-320'
  | 'py-324'
  | 'py-330'
  | 'py-336'
  | 'py-340'
  | 'py-350'
  | 'py-360'
  | 'py-370'
  | 'py-380'
  | 'py-390'
  | 'py-632'
  | 'py-680'
  | 'py-720'
  | 'py-fit'
  | 'py-max'
  | 'px-0'
  | 'px-1'
  | 'px-2'
  | 'px-3'
  | 'px-4'
  | 'px-5'
  | 'px-6'
  | 'px-7'
  | 'px-8'
  | 'px-9'
  | 'px-10'
  | 'px-11'
  | 'px-12'
  | 'px-13'
  | 'px-14'
  | 'px-15'
  | 'px-16'
  | 'px-17'
  | 'px-18'
  | 'px-19'
  | 'px-20'
  | 'px-21'
  | 'px-22'
  | 'px-23'
  | 'px-24'
  | 'px-25'
  | 'px-26'
  | 'px-27'
  | 'px-28'
  | 'px-29'
  | 'px-30'
  | 'px-32'
  | 'px-33'
  | 'px-34'
  | 'px-35'
  | 'px-36'
  | 'px-37'
  | 'px-38'
  | 'px-40'
  | 'px-41'
  | 'px-42'
  | 'px-44'
  | 'px-45'
  | 'px-46'
  | 'px-48'
  | 'px-50'
  | 'px-51'
  | 'px-52'
  | 'px-53'
  | 'px-56'
  | 'px-58'
  | 'px-60'
  | 'px-61'
  | 'px-64'
  | 'px-66'
  | 'px-70'
  | 'px-71'
  | 'px-72'
  | 'px-77'
  | 'px-80'
  | 'px-82'
  | 'px-85'
  | 'px-87'
  | 'px-88'
  | 'px-90'
  | 'px-95'
  | 'px-96'
  | 'px-100'
  | 'px-110'
  | 'px-112'
  | 'px-116'
  | 'px-120'
  | 'px-124'
  | 'px-126'
  | 'px-130'
  | 'px-132'
  | 'px-144'
  | 'px-150'
  | 'px-156'
  | 'px-160'
  | 'px-164'
  | 'px-170'
  | 'px-172'
  | 'px-178'
  | 'px-180'
  | 'px-182'
  | 'px-186'
  | 'px-190'
  | 'px-196'
  | 'px-200'
  | 'px-202'
  | 'px-216'
  | 'px-220'
  | 'px-222'
  | 'px-235'
  | 'px-248'
  | 'px-255'
  | 'px-258'
  | 'px-264'
  | 'px-270'
  | 'px-275'
  | 'px-280'
  | 'px-296'
  | 'px-300'
  | 'px-304'
  | 'px-310'
  | 'px-320'
  | 'px-324'
  | 'px-330'
  | 'px-336'
  | 'px-340'
  | 'px-350'
  | 'px-360'
  | 'px-370'
  | 'px-380'
  | 'px-390'
  | 'px-632'
  | 'px-680'
  | 'px-720'
  | 'px-fit'
  | 'px-max'
  | 'pt-0'
  | 'pt-1'
  | 'pt-2'
  | 'pt-3'
  | 'pt-4'
  | 'pt-5'
  | 'pt-6'
  | 'pt-7'
  | 'pt-8'
  | 'pt-9'
  | 'pt-10'
  | 'pt-11'
  | 'pt-12'
  | 'pt-13'
  | 'pt-14'
  | 'pt-15'
  | 'pt-16'
  | 'pt-17'
  | 'pt-18'
  | 'pt-19'
  | 'pt-20'
  | 'pt-21'
  | 'pt-22'
  | 'pt-23'
  | 'pt-24'
  | 'pt-25'
  | 'pt-26'
  | 'pt-27'
  | 'pt-28'
  | 'pt-29'
  | 'pt-30'
  | 'pt-32'
  | 'pt-33'
  | 'pt-34'
  | 'pt-35'
  | 'pt-36'
  | 'pt-37'
  | 'pt-38'
  | 'pt-40'
  | 'pt-41'
  | 'pt-42'
  | 'pt-44'
  | 'pt-45'
  | 'pt-46'
  | 'pt-48'
  | 'pt-50'
  | 'pt-51'
  | 'pt-52'
  | 'pt-53'
  | 'pt-56'
  | 'pt-58'
  | 'pt-60'
  | 'pt-61'
  | 'pt-64'
  | 'pt-66'
  | 'pt-70'
  | 'pt-71'
  | 'pt-72'
  | 'pt-77'
  | 'pt-80'
  | 'pt-82'
  | 'pt-85'
  | 'pt-87'
  | 'pt-88'
  | 'pt-90'
  | 'pt-95'
  | 'pt-96'
  | 'pt-100'
  | 'pt-110'
  | 'pt-112'
  | 'pt-116'
  | 'pt-120'
  | 'pt-124'
  | 'pt-126'
  | 'pt-130'
  | 'pt-132'
  | 'pt-144'
  | 'pt-150'
  | 'pt-156'
  | 'pt-160'
  | 'pt-164'
  | 'pt-170'
  | 'pt-172'
  | 'pt-178'
  | 'pt-180'
  | 'pt-182'
  | 'pt-186'
  | 'pt-190'
  | 'pt-196'
  | 'pt-200'
  | 'pt-202'
  | 'pt-216'
  | 'pt-220'
  | 'pt-222'
  | 'pt-235'
  | 'pt-248'
  | 'pt-255'
  | 'pt-258'
  | 'pt-264'
  | 'pt-270'
  | 'pt-275'
  | 'pt-280'
  | 'pt-296'
  | 'pt-300'
  | 'pt-304'
  | 'pt-310'
  | 'pt-320'
  | 'pt-324'
  | 'pt-330'
  | 'pt-336'
  | 'pt-340'
  | 'pt-350'
  | 'pt-360'
  | 'pt-370'
  | 'pt-380'
  | 'pt-390'
  | 'pt-632'
  | 'pt-680'
  | 'pt-720'
  | 'pt-fit'
  | 'pt-max'
  | 'pr-0'
  | 'pr-1'
  | 'pr-2'
  | 'pr-3'
  | 'pr-4'
  | 'pr-5'
  | 'pr-6'
  | 'pr-7'
  | 'pr-8'
  | 'pr-9'
  | 'pr-10'
  | 'pr-11'
  | 'pr-12'
  | 'pr-13'
  | 'pr-14'
  | 'pr-15'
  | 'pr-16'
  | 'pr-17'
  | 'pr-18'
  | 'pr-19'
  | 'pr-20'
  | 'pr-21'
  | 'pr-22'
  | 'pr-23'
  | 'pr-24'
  | 'pr-25'
  | 'pr-26'
  | 'pr-27'
  | 'pr-28'
  | 'pr-29'
  | 'pr-30'
  | 'pr-32'
  | 'pr-33'
  | 'pr-34'
  | 'pr-35'
  | 'pr-36'
  | 'pr-37'
  | 'pr-38'
  | 'pr-40'
  | 'pr-41'
  | 'pr-42'
  | 'pr-44'
  | 'pr-45'
  | 'pr-46'
  | 'pr-48'
  | 'pr-50'
  | 'pr-51'
  | 'pr-52'
  | 'pr-53'
  | 'pr-56'
  | 'pr-58'
  | 'pr-60'
  | 'pr-61'
  | 'pr-64'
  | 'pr-66'
  | 'pr-70'
  | 'pr-71'
  | 'pr-72'
  | 'pr-77'
  | 'pr-80'
  | 'pr-82'
  | 'pr-85'
  | 'pr-87'
  | 'pr-88'
  | 'pr-90'
  | 'pr-95'
  | 'pr-96'
  | 'pr-100'
  | 'pr-110'
  | 'pr-112'
  | 'pr-116'
  | 'pr-120'
  | 'pr-124'
  | 'pr-126'
  | 'pr-130'
  | 'pr-132'
  | 'pr-144'
  | 'pr-150'
  | 'pr-156'
  | 'pr-160'
  | 'pr-164'
  | 'pr-170'
  | 'pr-172'
  | 'pr-178'
  | 'pr-180'
  | 'pr-182'
  | 'pr-186'
  | 'pr-190'
  | 'pr-196'
  | 'pr-200'
  | 'pr-202'
  | 'pr-216'
  | 'pr-220'
  | 'pr-222'
  | 'pr-235'
  | 'pr-248'
  | 'pr-255'
  | 'pr-258'
  | 'pr-264'
  | 'pr-270'
  | 'pr-275'
  | 'pr-280'
  | 'pr-296'
  | 'pr-300'
  | 'pr-304'
  | 'pr-310'
  | 'pr-320'
  | 'pr-324'
  | 'pr-330'
  | 'pr-336'
  | 'pr-340'
  | 'pr-350'
  | 'pr-360'
  | 'pr-370'
  | 'pr-380'
  | 'pr-390'
  | 'pr-632'
  | 'pr-680'
  | 'pr-720'
  | 'pr-fit'
  | 'pr-max'
  | 'pb-0'
  | 'pb-1'
  | 'pb-2'
  | 'pb-3'
  | 'pb-4'
  | 'pb-5'
  | 'pb-6'
  | 'pb-7'
  | 'pb-8'
  | 'pb-9'
  | 'pb-10'
  | 'pb-11'
  | 'pb-12'
  | 'pb-13'
  | 'pb-14'
  | 'pb-15'
  | 'pb-16'
  | 'pb-17'
  | 'pb-18'
  | 'pb-19'
  | 'pb-20'
  | 'pb-21'
  | 'pb-22'
  | 'pb-23'
  | 'pb-24'
  | 'pb-25'
  | 'pb-26'
  | 'pb-27'
  | 'pb-28'
  | 'pb-29'
  | 'pb-30'
  | 'pb-32'
  | 'pb-33'
  | 'pb-34'
  | 'pb-35'
  | 'pb-36'
  | 'pb-37'
  | 'pb-38'
  | 'pb-40'
  | 'pb-41'
  | 'pb-42'
  | 'pb-44'
  | 'pb-45'
  | 'pb-46'
  | 'pb-48'
  | 'pb-50'
  | 'pb-51'
  | 'pb-52'
  | 'pb-53'
  | 'pb-56'
  | 'pb-58'
  | 'pb-60'
  | 'pb-61'
  | 'pb-64'
  | 'pb-66'
  | 'pb-70'
  | 'pb-71'
  | 'pb-72'
  | 'pb-77'
  | 'pb-80'
  | 'pb-82'
  | 'pb-85'
  | 'pb-87'
  | 'pb-88'
  | 'pb-90'
  | 'pb-95'
  | 'pb-96'
  | 'pb-100'
  | 'pb-110'
  | 'pb-112'
  | 'pb-116'
  | 'pb-120'
  | 'pb-124'
  | 'pb-126'
  | 'pb-130'
  | 'pb-132'
  | 'pb-144'
  | 'pb-150'
  | 'pb-156'
  | 'pb-160'
  | 'pb-164'
  | 'pb-170'
  | 'pb-172'
  | 'pb-178'
  | 'pb-180'
  | 'pb-182'
  | 'pb-186'
  | 'pb-190'
  | 'pb-196'
  | 'pb-200'
  | 'pb-202'
  | 'pb-216'
  | 'pb-220'
  | 'pb-222'
  | 'pb-235'
  | 'pb-248'
  | 'pb-255'
  | 'pb-258'
  | 'pb-264'
  | 'pb-270'
  | 'pb-275'
  | 'pb-280'
  | 'pb-296'
  | 'pb-300'
  | 'pb-304'
  | 'pb-310'
  | 'pb-320'
  | 'pb-324'
  | 'pb-330'
  | 'pb-336'
  | 'pb-340'
  | 'pb-350'
  | 'pb-360'
  | 'pb-370'
  | 'pb-380'
  | 'pb-390'
  | 'pb-632'
  | 'pb-680'
  | 'pb-720'
  | 'pb-fit'
  | 'pb-max'
  | 'pl-0'
  | 'pl-1'
  | 'pl-2'
  | 'pl-3'
  | 'pl-4'
  | 'pl-5'
  | 'pl-6'
  | 'pl-7'
  | 'pl-8'
  | 'pl-9'
  | 'pl-10'
  | 'pl-11'
  | 'pl-12'
  | 'pl-13'
  | 'pl-14'
  | 'pl-15'
  | 'pl-16'
  | 'pl-17'
  | 'pl-18'
  | 'pl-19'
  | 'pl-20'
  | 'pl-21'
  | 'pl-22'
  | 'pl-23'
  | 'pl-24'
  | 'pl-25'
  | 'pl-26'
  | 'pl-27'
  | 'pl-28'
  | 'pl-29'
  | 'pl-30'
  | 'pl-32'
  | 'pl-33'
  | 'pl-34'
  | 'pl-35'
  | 'pl-36'
  | 'pl-37'
  | 'pl-38'
  | 'pl-40'
  | 'pl-41'
  | 'pl-42'
  | 'pl-44'
  | 'pl-45'
  | 'pl-46'
  | 'pl-48'
  | 'pl-50'
  | 'pl-51'
  | 'pl-52'
  | 'pl-53'
  | 'pl-56'
  | 'pl-58'
  | 'pl-60'
  | 'pl-61'
  | 'pl-64'
  | 'pl-66'
  | 'pl-70'
  | 'pl-71'
  | 'pl-72'
  | 'pl-77'
  | 'pl-80'
  | 'pl-82'
  | 'pl-85'
  | 'pl-87'
  | 'pl-88'
  | 'pl-90'
  | 'pl-95'
  | 'pl-96'
  | 'pl-100'
  | 'pl-110'
  | 'pl-112'
  | 'pl-116'
  | 'pl-120'
  | 'pl-124'
  | 'pl-126'
  | 'pl-130'
  | 'pl-132'
  | 'pl-144'
  | 'pl-150'
  | 'pl-156'
  | 'pl-160'
  | 'pl-164'
  | 'pl-170'
  | 'pl-172'
  | 'pl-178'
  | 'pl-180'
  | 'pl-182'
  | 'pl-186'
  | 'pl-190'
  | 'pl-196'
  | 'pl-200'
  | 'pl-202'
  | 'pl-216'
  | 'pl-220'
  | 'pl-222'
  | 'pl-235'
  | 'pl-248'
  | 'pl-255'
  | 'pl-258'
  | 'pl-264'
  | 'pl-270'
  | 'pl-275'
  | 'pl-280'
  | 'pl-296'
  | 'pl-300'
  | 'pl-304'
  | 'pl-310'
  | 'pl-320'
  | 'pl-324'
  | 'pl-330'
  | 'pl-336'
  | 'pl-340'
  | 'pl-350'
  | 'pl-360'
  | 'pl-370'
  | 'pl-380'
  | 'pl-390'
  | 'pl-632'
  | 'pl-680'
  | 'pl-720'
  | 'pl-fit'
  | 'pl-max'

export type TMargin =
  | 'm-0'
  | 'm-1'
  | 'm-2'
  | 'm-3'
  | 'm-4'
  | 'm-5'
  | 'm-6'
  | 'm-7'
  | 'm-8'
  | 'm-9'
  | 'm-10'
  | 'm-11'
  | 'm-12'
  | 'm-13'
  | 'm-14'
  | 'm-15'
  | 'm-16'
  | 'm-17'
  | 'm-18'
  | 'm-19'
  | 'm-20'
  | 'm-21'
  | 'm-22'
  | 'm-23'
  | 'm-24'
  | 'm-25'
  | 'm-26'
  | 'm-27'
  | 'm-28'
  | 'm-29'
  | 'm-30'
  | 'm-32'
  | 'm-33'
  | 'm-34'
  | 'm-35'
  | 'm-36'
  | 'm-37'
  | 'm-38'
  | 'm-40'
  | 'm-41'
  | 'm-42'
  | 'm-44'
  | 'm-45'
  | 'm-46'
  | 'm-48'
  | 'm-50'
  | 'm-51'
  | 'm-52'
  | 'm-53'
  | 'm-56'
  | 'm-58'
  | 'm-60'
  | 'm-61'
  | 'm-64'
  | 'm-66'
  | 'm-70'
  | 'm-71'
  | 'm-72'
  | 'm-77'
  | 'm-80'
  | 'm-82'
  | 'm-85'
  | 'm-87'
  | 'm-88'
  | 'm-90'
  | 'm-95'
  | 'm-96'
  | 'm-100'
  | 'm-110'
  | 'm-112'
  | 'm-116'
  | 'm-120'
  | 'm-124'
  | 'm-126'
  | 'm-130'
  | 'm-132'
  | 'm-144'
  | 'm-150'
  | 'm-156'
  | 'm-160'
  | 'm-164'
  | 'm-170'
  | 'm-172'
  | 'm-178'
  | 'm-180'
  | 'm-182'
  | 'm-186'
  | 'm-190'
  | 'm-196'
  | 'm-200'
  | 'm-202'
  | 'm-216'
  | 'm-220'
  | 'm-222'
  | 'm-235'
  | 'm-248'
  | 'm-255'
  | 'm-258'
  | 'm-264'
  | 'm-270'
  | 'm-275'
  | 'm-280'
  | 'm-296'
  | 'm-300'
  | 'm-304'
  | 'm-310'
  | 'm-320'
  | 'm-324'
  | 'm-330'
  | 'm-336'
  | 'm-340'
  | 'm-350'
  | 'm-360'
  | 'm-370'
  | 'm-380'
  | 'm-390'
  | 'm-632'
  | 'm-680'
  | 'm-720'
  | 'm-auto'
  | 'm-fit'
  | 'm-max'
  | 'my-0'
  | 'my-1'
  | 'my-2'
  | 'my-3'
  | 'my-4'
  | 'my-5'
  | 'my-6'
  | 'my-7'
  | 'my-8'
  | 'my-9'
  | 'my-10'
  | 'my-11'
  | 'my-12'
  | 'my-13'
  | 'my-14'
  | 'my-15'
  | 'my-16'
  | 'my-17'
  | 'my-18'
  | 'my-19'
  | 'my-20'
  | 'my-21'
  | 'my-22'
  | 'my-23'
  | 'my-24'
  | 'my-25'
  | 'my-26'
  | 'my-27'
  | 'my-28'
  | 'my-29'
  | 'my-30'
  | 'my-32'
  | 'my-33'
  | 'my-34'
  | 'my-35'
  | 'my-36'
  | 'my-37'
  | 'my-38'
  | 'my-40'
  | 'my-41'
  | 'my-42'
  | 'my-44'
  | 'my-45'
  | 'my-46'
  | 'my-48'
  | 'my-50'
  | 'my-51'
  | 'my-52'
  | 'my-53'
  | 'my-56'
  | 'my-58'
  | 'my-60'
  | 'my-61'
  | 'my-64'
  | 'my-66'
  | 'my-70'
  | 'my-71'
  | 'my-72'
  | 'my-77'
  | 'my-80'
  | 'my-82'
  | 'my-85'
  | 'my-87'
  | 'my-88'
  | 'my-90'
  | 'my-95'
  | 'my-96'
  | 'my-100'
  | 'my-110'
  | 'my-112'
  | 'my-116'
  | 'my-120'
  | 'my-124'
  | 'my-126'
  | 'my-130'
  | 'my-132'
  | 'my-144'
  | 'my-150'
  | 'my-156'
  | 'my-160'
  | 'my-164'
  | 'my-170'
  | 'my-172'
  | 'my-178'
  | 'my-180'
  | 'my-182'
  | 'my-186'
  | 'my-190'
  | 'my-196'
  | 'my-200'
  | 'my-202'
  | 'my-216'
  | 'my-220'
  | 'my-222'
  | 'my-235'
  | 'my-248'
  | 'my-255'
  | 'my-258'
  | 'my-264'
  | 'my-270'
  | 'my-275'
  | 'my-280'
  | 'my-296'
  | 'my-300'
  | 'my-304'
  | 'my-310'
  | 'my-320'
  | 'my-324'
  | 'my-330'
  | 'my-336'
  | 'my-340'
  | 'my-350'
  | 'my-360'
  | 'my-370'
  | 'my-380'
  | 'my-390'
  | 'my-632'
  | 'my-680'
  | 'my-720'
  | 'my-auto'
  | 'my-fit'
  | 'my-max'
  | 'mx-0'
  | 'mx-1'
  | 'mx-2'
  | 'mx-3'
  | 'mx-4'
  | 'mx-5'
  | 'mx-6'
  | 'mx-7'
  | 'mx-8'
  | 'mx-9'
  | 'mx-10'
  | 'mx-11'
  | 'mx-12'
  | 'mx-13'
  | 'mx-14'
  | 'mx-15'
  | 'mx-16'
  | 'mx-17'
  | 'mx-18'
  | 'mx-19'
  | 'mx-20'
  | 'mx-21'
  | 'mx-22'
  | 'mx-23'
  | 'mx-24'
  | 'mx-25'
  | 'mx-26'
  | 'mx-27'
  | 'mx-28'
  | 'mx-29'
  | 'mx-30'
  | 'mx-32'
  | 'mx-33'
  | 'mx-34'
  | 'mx-35'
  | 'mx-36'
  | 'mx-37'
  | 'mx-38'
  | 'mx-40'
  | 'mx-41'
  | 'mx-42'
  | 'mx-44'
  | 'mx-45'
  | 'mx-46'
  | 'mx-48'
  | 'mx-50'
  | 'mx-51'
  | 'mx-52'
  | 'mx-53'
  | 'mx-56'
  | 'mx-58'
  | 'mx-60'
  | 'mx-61'
  | 'mx-64'
  | 'mx-66'
  | 'mx-70'
  | 'mx-71'
  | 'mx-72'
  | 'mx-77'
  | 'mx-80'
  | 'mx-82'
  | 'mx-85'
  | 'mx-87'
  | 'mx-88'
  | 'mx-90'
  | 'mx-95'
  | 'mx-96'
  | 'mx-100'
  | 'mx-110'
  | 'mx-112'
  | 'mx-116'
  | 'mx-120'
  | 'mx-124'
  | 'mx-126'
  | 'mx-130'
  | 'mx-132'
  | 'mx-144'
  | 'mx-150'
  | 'mx-156'
  | 'mx-160'
  | 'mx-164'
  | 'mx-170'
  | 'mx-172'
  | 'mx-178'
  | 'mx-180'
  | 'mx-182'
  | 'mx-186'
  | 'mx-190'
  | 'mx-196'
  | 'mx-200'
  | 'mx-202'
  | 'mx-216'
  | 'mx-220'
  | 'mx-222'
  | 'mx-235'
  | 'mx-248'
  | 'mx-255'
  | 'mx-258'
  | 'mx-264'
  | 'mx-270'
  | 'mx-275'
  | 'mx-280'
  | 'mx-296'
  | 'mx-300'
  | 'mx-304'
  | 'mx-310'
  | 'mx-320'
  | 'mx-324'
  | 'mx-330'
  | 'mx-336'
  | 'mx-340'
  | 'mx-350'
  | 'mx-360'
  | 'mx-370'
  | 'mx-380'
  | 'mx-390'
  | 'mx-632'
  | 'mx-680'
  | 'mx-720'
  | 'mx-auto'
  | 'mx-fit'
  | 'mx-max'
  | 'mt-0'
  | 'mt-1'
  | 'mt-2'
  | 'mt-3'
  | 'mt-4'
  | 'mt-5'
  | 'mt-6'
  | 'mt-7'
  | 'mt-8'
  | 'mt-9'
  | 'mt-10'
  | 'mt-11'
  | 'mt-12'
  | 'mt-13'
  | 'mt-14'
  | 'mt-15'
  | 'mt-16'
  | 'mt-17'
  | 'mt-18'
  | 'mt-19'
  | 'mt-20'
  | 'mt-21'
  | 'mt-22'
  | 'mt-23'
  | 'mt-24'
  | 'mt-25'
  | 'mt-26'
  | 'mt-27'
  | 'mt-28'
  | 'mt-29'
  | 'mt-30'
  | 'mt-32'
  | 'mt-33'
  | 'mt-34'
  | 'mt-35'
  | 'mt-36'
  | 'mt-37'
  | 'mt-38'
  | 'mt-40'
  | 'mt-41'
  | 'mt-42'
  | 'mt-44'
  | 'mt-45'
  | 'mt-46'
  | 'mt-48'
  | 'mt-50'
  | 'mt-51'
  | 'mt-52'
  | 'mt-53'
  | 'mt-56'
  | 'mt-58'
  | 'mt-60'
  | 'mt-61'
  | 'mt-64'
  | 'mt-66'
  | 'mt-70'
  | 'mt-71'
  | 'mt-72'
  | 'mt-77'
  | 'mt-80'
  | 'mt-82'
  | 'mt-85'
  | 'mt-87'
  | 'mt-88'
  | 'mt-90'
  | 'mt-95'
  | 'mt-96'
  | 'mt-100'
  | 'mt-110'
  | 'mt-112'
  | 'mt-116'
  | 'mt-120'
  | 'mt-124'
  | 'mt-126'
  | 'mt-130'
  | 'mt-132'
  | 'mt-144'
  | 'mt-150'
  | 'mt-156'
  | 'mt-160'
  | 'mt-164'
  | 'mt-170'
  | 'mt-172'
  | 'mt-178'
  | 'mt-180'
  | 'mt-182'
  | 'mt-186'
  | 'mt-190'
  | 'mt-196'
  | 'mt-200'
  | 'mt-202'
  | 'mt-216'
  | 'mt-220'
  | 'mt-222'
  | 'mt-235'
  | 'mt-248'
  | 'mt-255'
  | 'mt-258'
  | 'mt-264'
  | 'mt-270'
  | 'mt-275'
  | 'mt-280'
  | 'mt-296'
  | 'mt-300'
  | 'mt-304'
  | 'mt-310'
  | 'mt-320'
  | 'mt-324'
  | 'mt-330'
  | 'mt-336'
  | 'mt-340'
  | 'mt-350'
  | 'mt-360'
  | 'mt-370'
  | 'mt-380'
  | 'mt-390'
  | 'mt-632'
  | 'mt-680'
  | 'mt-720'
  | 'mt-auto'
  | 'mt-fit'
  | 'mt-max'
  | 'mr-0'
  | 'mr-1'
  | 'mr-2'
  | 'mr-3'
  | 'mr-4'
  | 'mr-5'
  | 'mr-6'
  | 'mr-7'
  | 'mr-8'
  | 'mr-9'
  | 'mr-10'
  | 'mr-11'
  | 'mr-12'
  | 'mr-13'
  | 'mr-14'
  | 'mr-15'
  | 'mr-16'
  | 'mr-17'
  | 'mr-18'
  | 'mr-19'
  | 'mr-20'
  | 'mr-21'
  | 'mr-22'
  | 'mr-23'
  | 'mr-24'
  | 'mr-25'
  | 'mr-26'
  | 'mr-27'
  | 'mr-28'
  | 'mr-29'
  | 'mr-30'
  | 'mr-32'
  | 'mr-33'
  | 'mr-34'
  | 'mr-35'
  | 'mr-36'
  | 'mr-37'
  | 'mr-38'
  | 'mr-40'
  | 'mr-41'
  | 'mr-42'
  | 'mr-44'
  | 'mr-45'
  | 'mr-46'
  | 'mr-48'
  | 'mr-50'
  | 'mr-51'
  | 'mr-52'
  | 'mr-53'
  | 'mr-56'
  | 'mr-58'
  | 'mr-60'
  | 'mr-61'
  | 'mr-64'
  | 'mr-66'
  | 'mr-70'
  | 'mr-71'
  | 'mr-72'
  | 'mr-77'
  | 'mr-80'
  | 'mr-82'
  | 'mr-85'
  | 'mr-87'
  | 'mr-88'
  | 'mr-90'
  | 'mr-95'
  | 'mr-96'
  | 'mr-100'
  | 'mr-110'
  | 'mr-112'
  | 'mr-116'
  | 'mr-120'
  | 'mr-124'
  | 'mr-126'
  | 'mr-130'
  | 'mr-132'
  | 'mr-144'
  | 'mr-150'
  | 'mr-156'
  | 'mr-160'
  | 'mr-164'
  | 'mr-170'
  | 'mr-172'
  | 'mr-178'
  | 'mr-180'
  | 'mr-182'
  | 'mr-186'
  | 'mr-190'
  | 'mr-196'
  | 'mr-200'
  | 'mr-202'
  | 'mr-216'
  | 'mr-220'
  | 'mr-222'
  | 'mr-235'
  | 'mr-248'
  | 'mr-255'
  | 'mr-258'
  | 'mr-264'
  | 'mr-270'
  | 'mr-275'
  | 'mr-280'
  | 'mr-296'
  | 'mr-300'
  | 'mr-304'
  | 'mr-310'
  | 'mr-320'
  | 'mr-324'
  | 'mr-330'
  | 'mr-336'
  | 'mr-340'
  | 'mr-350'
  | 'mr-360'
  | 'mr-370'
  | 'mr-380'
  | 'mr-390'
  | 'mr-632'
  | 'mr-680'
  | 'mr-720'
  | 'mr-auto'
  | 'mr-fit'
  | 'mr-max'
  | 'mb-0'
  | 'mb-1'
  | 'mb-2'
  | 'mb-3'
  | 'mb-4'
  | 'mb-5'
  | 'mb-6'
  | 'mb-7'
  | 'mb-8'
  | 'mb-9'
  | 'mb-10'
  | 'mb-11'
  | 'mb-12'
  | 'mb-13'
  | 'mb-14'
  | 'mb-15'
  | 'mb-16'
  | 'mb-17'
  | 'mb-18'
  | 'mb-19'
  | 'mb-20'
  | 'mb-21'
  | 'mb-22'
  | 'mb-23'
  | 'mb-24'
  | 'mb-25'
  | 'mb-26'
  | 'mb-27'
  | 'mb-28'
  | 'mb-29'
  | 'mb-30'
  | 'mb-32'
  | 'mb-33'
  | 'mb-34'
  | 'mb-35'
  | 'mb-36'
  | 'mb-37'
  | 'mb-38'
  | 'mb-40'
  | 'mb-41'
  | 'mb-42'
  | 'mb-44'
  | 'mb-45'
  | 'mb-46'
  | 'mb-48'
  | 'mb-50'
  | 'mb-51'
  | 'mb-52'
  | 'mb-53'
  | 'mb-56'
  | 'mb-58'
  | 'mb-60'
  | 'mb-61'
  | 'mb-64'
  | 'mb-66'
  | 'mb-70'
  | 'mb-71'
  | 'mb-72'
  | 'mb-77'
  | 'mb-80'
  | 'mb-82'
  | 'mb-85'
  | 'mb-87'
  | 'mb-88'
  | 'mb-90'
  | 'mb-95'
  | 'mb-96'
  | 'mb-100'
  | 'mb-110'
  | 'mb-112'
  | 'mb-116'
  | 'mb-120'
  | 'mb-124'
  | 'mb-126'
  | 'mb-130'
  | 'mb-132'
  | 'mb-144'
  | 'mb-150'
  | 'mb-156'
  | 'mb-160'
  | 'mb-164'
  | 'mb-170'
  | 'mb-172'
  | 'mb-178'
  | 'mb-180'
  | 'mb-182'
  | 'mb-186'
  | 'mb-190'
  | 'mb-196'
  | 'mb-200'
  | 'mb-202'
  | 'mb-216'
  | 'mb-220'
  | 'mb-222'
  | 'mb-235'
  | 'mb-248'
  | 'mb-255'
  | 'mb-258'
  | 'mb-264'
  | 'mb-270'
  | 'mb-275'
  | 'mb-280'
  | 'mb-296'
  | 'mb-300'
  | 'mb-304'
  | 'mb-310'
  | 'mb-320'
  | 'mb-324'
  | 'mb-330'
  | 'mb-336'
  | 'mb-340'
  | 'mb-350'
  | 'mb-360'
  | 'mb-370'
  | 'mb-380'
  | 'mb-390'
  | 'mb-632'
  | 'mb-680'
  | 'mb-720'
  | 'mb-auto'
  | 'mb-fit'
  | 'mb-max'
  | 'ml-0'
  | 'ml-1'
  | 'ml-2'
  | 'ml-3'
  | 'ml-4'
  | 'ml-5'
  | 'ml-6'
  | 'ml-7'
  | 'ml-8'
  | 'ml-9'
  | 'ml-10'
  | 'ml-11'
  | 'ml-12'
  | 'ml-13'
  | 'ml-14'
  | 'ml-15'
  | 'ml-16'
  | 'ml-17'
  | 'ml-18'
  | 'ml-19'
  | 'ml-20'
  | 'ml-21'
  | 'ml-22'
  | 'ml-23'
  | 'ml-24'
  | 'ml-25'
  | 'ml-26'
  | 'ml-27'
  | 'ml-28'
  | 'ml-29'
  | 'ml-30'
  | 'ml-32'
  | 'ml-33'
  | 'ml-34'
  | 'ml-35'
  | 'ml-36'
  | 'ml-37'
  | 'ml-38'
  | 'ml-40'
  | 'ml-41'
  | 'ml-42'
  | 'ml-44'
  | 'ml-45'
  | 'ml-46'
  | 'ml-48'
  | 'ml-50'
  | 'ml-51'
  | 'ml-52'
  | 'ml-53'
  | 'ml-56'
  | 'ml-58'
  | 'ml-60'
  | 'ml-61'
  | 'ml-64'
  | 'ml-66'
  | 'ml-70'
  | 'ml-71'
  | 'ml-72'
  | 'ml-77'
  | 'ml-80'
  | 'ml-82'
  | 'ml-85'
  | 'ml-87'
  | 'ml-88'
  | 'ml-90'
  | 'ml-95'
  | 'ml-96'
  | 'ml-100'
  | 'ml-110'
  | 'ml-112'
  | 'ml-116'
  | 'ml-120'
  | 'ml-124'
  | 'ml-126'
  | 'ml-130'
  | 'ml-132'
  | 'ml-144'
  | 'ml-150'
  | 'ml-156'
  | 'ml-160'
  | 'ml-164'
  | 'ml-170'
  | 'ml-172'
  | 'ml-178'
  | 'ml-180'
  | 'ml-182'
  | 'ml-186'
  | 'ml-190'
  | 'ml-196'
  | 'ml-200'
  | 'ml-202'
  | 'ml-216'
  | 'ml-220'
  | 'ml-222'
  | 'ml-235'
  | 'ml-248'
  | 'ml-255'
  | 'ml-258'
  | 'ml-264'
  | 'ml-270'
  | 'ml-275'
  | 'ml-280'
  | 'ml-296'
  | 'ml-300'
  | 'ml-304'
  | 'ml-310'
  | 'ml-320'
  | 'ml-324'
  | 'ml-330'
  | 'ml-336'
  | 'ml-340'
  | 'ml-350'
  | 'ml-360'
  | 'ml-370'
  | 'ml-380'
  | 'ml-390'
  | 'ml-632'
  | 'ml-680'
  | 'ml-720'
  | 'ml-auto'
  | 'ml-fit'
  | 'ml-max'

export type TSpace =
  | 'space-x-0'
  | 'space-x-1'
  | 'space-x-2'
  | 'space-x-3'
  | 'space-x-4'
  | 'space-x-5'
  | 'space-x-6'
  | 'space-x-7'
  | 'space-x-8'
  | 'space-x-9'
  | 'space-x-10'
  | 'space-x-11'
  | 'space-x-12'
  | 'space-x-13'
  | 'space-x-14'
  | 'space-x-15'
  | 'space-x-16'
  | 'space-x-17'
  | 'space-x-18'
  | 'space-x-19'
  | 'space-x-20'
  | 'space-x-21'
  | 'space-x-22'
  | 'space-x-23'
  | 'space-x-24'
  | 'space-x-25'
  | 'space-x-26'
  | 'space-x-27'
  | 'space-x-28'
  | 'space-x-29'
  | 'space-x-30'
  | 'space-x-32'
  | 'space-x-33'
  | 'space-x-34'
  | 'space-x-35'
  | 'space-x-36'
  | 'space-x-37'
  | 'space-x-38'
  | 'space-x-40'
  | 'space-x-41'
  | 'space-x-42'
  | 'space-x-44'
  | 'space-x-45'
  | 'space-x-46'
  | 'space-x-48'
  | 'space-x-50'
  | 'space-x-51'
  | 'space-x-52'
  | 'space-x-53'
  | 'space-x-56'
  | 'space-x-58'
  | 'space-x-60'
  | 'space-x-61'
  | 'space-x-64'
  | 'space-x-66'
  | 'space-x-70'
  | 'space-x-71'
  | 'space-x-72'
  | 'space-x-77'
  | 'space-x-80'
  | 'space-x-82'
  | 'space-x-85'
  | 'space-x-87'
  | 'space-x-88'
  | 'space-x-90'
  | 'space-x-95'
  | 'space-x-96'
  | 'space-x-100'
  | 'space-x-110'
  | 'space-x-112'
  | 'space-x-116'
  | 'space-x-120'
  | 'space-x-124'
  | 'space-x-126'
  | 'space-x-130'
  | 'space-x-132'
  | 'space-x-144'
  | 'space-x-150'
  | 'space-x-156'
  | 'space-x-160'
  | 'space-x-164'
  | 'space-x-170'
  | 'space-x-172'
  | 'space-x-178'
  | 'space-x-180'
  | 'space-x-182'
  | 'space-x-186'
  | 'space-x-190'
  | 'space-x-196'
  | 'space-x-200'
  | 'space-x-202'
  | 'space-x-216'
  | 'space-x-220'
  | 'space-x-222'
  | 'space-x-235'
  | 'space-x-248'
  | 'space-x-255'
  | 'space-x-258'
  | 'space-x-264'
  | 'space-x-270'
  | 'space-x-275'
  | 'space-x-280'
  | 'space-x-296'
  | 'space-x-300'
  | 'space-x-304'
  | 'space-x-310'
  | 'space-x-320'
  | 'space-x-324'
  | 'space-x-330'
  | 'space-x-336'
  | 'space-x-340'
  | 'space-x-350'
  | 'space-x-360'
  | 'space-x-370'
  | 'space-x-380'
  | 'space-x-390'
  | 'space-x-632'
  | 'space-x-680'
  | 'space-x-720'
  | 'space-x-fit'
  | 'space-x-max'
  | 'space-x-reverse'
  | 'space-y-0'
  | 'space-y-1'
  | 'space-y-2'
  | 'space-y-3'
  | 'space-y-4'
  | 'space-y-5'
  | 'space-y-6'
  | 'space-y-7'
  | 'space-y-8'
  | 'space-y-9'
  | 'space-y-10'
  | 'space-y-11'
  | 'space-y-12'
  | 'space-y-13'
  | 'space-y-14'
  | 'space-y-15'
  | 'space-y-16'
  | 'space-y-17'
  | 'space-y-18'
  | 'space-y-19'
  | 'space-y-20'
  | 'space-y-21'
  | 'space-y-22'
  | 'space-y-23'
  | 'space-y-24'
  | 'space-y-25'
  | 'space-y-26'
  | 'space-y-27'
  | 'space-y-28'
  | 'space-y-29'
  | 'space-y-30'
  | 'space-y-32'
  | 'space-y-33'
  | 'space-y-34'
  | 'space-y-35'
  | 'space-y-36'
  | 'space-y-37'
  | 'space-y-38'
  | 'space-y-40'
  | 'space-y-41'
  | 'space-y-42'
  | 'space-y-44'
  | 'space-y-45'
  | 'space-y-46'
  | 'space-y-48'
  | 'space-y-50'
  | 'space-y-51'
  | 'space-y-52'
  | 'space-y-53'
  | 'space-y-56'
  | 'space-y-58'
  | 'space-y-60'
  | 'space-y-61'
  | 'space-y-64'
  | 'space-y-66'
  | 'space-y-70'
  | 'space-y-71'
  | 'space-y-72'
  | 'space-y-77'
  | 'space-y-80'
  | 'space-y-82'
  | 'space-y-85'
  | 'space-y-87'
  | 'space-y-88'
  | 'space-y-90'
  | 'space-y-95'
  | 'space-y-96'
  | 'space-y-100'
  | 'space-y-110'
  | 'space-y-112'
  | 'space-y-116'
  | 'space-y-120'
  | 'space-y-124'
  | 'space-y-126'
  | 'space-y-130'
  | 'space-y-132'
  | 'space-y-144'
  | 'space-y-150'
  | 'space-y-156'
  | 'space-y-160'
  | 'space-y-164'
  | 'space-y-170'
  | 'space-y-172'
  | 'space-y-178'
  | 'space-y-180'
  | 'space-y-182'
  | 'space-y-186'
  | 'space-y-190'
  | 'space-y-196'
  | 'space-y-200'
  | 'space-y-202'
  | 'space-y-216'
  | 'space-y-220'
  | 'space-y-222'
  | 'space-y-235'
  | 'space-y-248'
  | 'space-y-255'
  | 'space-y-258'
  | 'space-y-264'
  | 'space-y-270'
  | 'space-y-275'
  | 'space-y-280'
  | 'space-y-296'
  | 'space-y-300'
  | 'space-y-304'
  | 'space-y-310'
  | 'space-y-320'
  | 'space-y-324'
  | 'space-y-330'
  | 'space-y-336'
  | 'space-y-340'
  | 'space-y-350'
  | 'space-y-360'
  | 'space-y-370'
  | 'space-y-380'
  | 'space-y-390'
  | 'space-y-632'
  | 'space-y-680'
  | 'space-y-720'
  | 'space-y-fit'
  | 'space-y-max'
  | 'space-y-reverse'

export type TSpacing =
  | TPadding
  | TMargin
  | TSpace

export type TAppearance =
  | 'appearance-none'

export type TPointerEvents =
  | 'pointer-events-none'
  | 'pointer-events-auto'

export type TResize =
  | 'resize-none'
  | 'resize'
  | 'resize-y'
  | 'resize-x'

export type TUserSelect =
  | 'select-none'
  | 'select-text'
  | 'select-all'
  | 'select-auto'

export type TScrollSnap =
  | 'snap-start'
  | 'snap-end'
  | 'snap-center'
  | 'snap-align-none'
  | 'snap-normal'
  | 'snap-always'
  | 'snap-none'
  | 'snap-x'
  | 'snap-y'
  | 'snap-both'
  | 'snap-mandatory'
  | 'snap-proximity'

export type TScrollBehavior =
  | 'scroll-auto'
  | 'scroll-smooth'

export type TTouchAction =
  | 'touch-auto'
  | 'touch-none'
  | 'touch-pan-x'
  | 'touch-pan-left'
  | 'touch-pan-right'
  | 'touch-pan-y'
  | 'touch-pan-up'
  | 'touch-pan-down'
  | 'touch-pinch-zoom'
  | 'touch-manipulation'

export type TCursor =
  | 'cursor-auto'
  | 'cursor-default'
  | 'cursor-pointer'
  | 'cursor-wait'
  | 'cursor-text'
  | 'cursor-move'
  | 'cursor-help'
  | 'cursor-not-allowed'
  | 'cursor-none'
  | 'cursor-context-menu'
  | 'cursor-progress'
  | 'cursor-cell'
  | 'cursor-crosshair'
  | 'cursor-vertical-text'
  | 'cursor-alias'
  | 'cursor-copy'
  | 'cursor-no-drop'
  | 'cursor-grab'
  | 'cursor-grabbing'
  | 'cursor-all-scroll'
  | 'cursor-col-resize'
  | 'cursor-row-resize'
  | 'cursor-n-resize'
  | 'cursor-e-resize'
  | 'cursor-s-resize'
  | 'cursor-w-resize'
  | 'cursor-ne-resize'
  | 'cursor-nw-resize'
  | 'cursor-se-resize'
  | 'cursor-sw-resize'
  | 'cursor-ew-resize'
  | 'cursor-ns-resize'
  | 'cursor-nesw-resize'
  | 'cursor-nwse-resize'
  | 'cursor-zoom-in'
  | 'cursor-zoom-out'

export type TCaretColor =
  | 'caret-inherit'
  | 'caret-transparent'
  | 'caret-current'
  | 'caret-black'
  | 'caret-white'
  | 'caret-brand-primary-1'
  | 'caret-brand-primary-2'
  | 'caret-brand-primary-3'
  | 'caret-brand-primary-4'
  | 'caret-brand-primary-5'
  | 'caret-brand-secondary-1'
  | 'caret-brand-secondary-2'
  | 'caret-brand-secondary-3'
  | 'caret-brand-secondary-4'
  | 'caret-brand-secondary-5'
  | 'caret-brand-tertiary-1'
  | 'caret-brand-tertiary-2'
  | 'caret-brand-tertiary-3'
  | 'caret-brand-tertiary-4'
  | 'caret-brand-tertiary-5'
  | 'caret-gray-1'
  | 'caret-gray-2'
  | 'caret-gray-3'
  | 'caret-gray-4'
  | 'caret-gray-5'
  | 'caret-gray-6'
  | 'caret-gray-7'
  | 'caret-gray-8'
  | 'caret-gray-9'
  | 'caret-social-linkedin'
  | 'caret-social-facebook'
  | 'caret-social-twitter'
  | 'caret-social-instagram'
  | 'caret-informa-bar'
  | 'caret-danger'
  | 'caret-error'

export type TWillChange =
  | 'will-change-auto'
  | 'will-change-scroll'
  | 'will-change-contents'
  | 'will-change-transform'

export type TAccentColor =
  | 'accent-inherit'
  | 'accent-transparent'
  | 'accent-current'
  | 'accent-black'
  | 'accent-white'
  | 'accent-brand-primary-1'
  | 'accent-brand-primary-2'
  | 'accent-brand-primary-3'
  | 'accent-brand-primary-4'
  | 'accent-brand-primary-5'
  | 'accent-brand-secondary-1'
  | 'accent-brand-secondary-2'
  | 'accent-brand-secondary-3'
  | 'accent-brand-secondary-4'
  | 'accent-brand-secondary-5'
  | 'accent-brand-tertiary-1'
  | 'accent-brand-tertiary-2'
  | 'accent-brand-tertiary-3'
  | 'accent-brand-tertiary-4'
  | 'accent-brand-tertiary-5'
  | 'accent-gray-1'
  | 'accent-gray-2'
  | 'accent-gray-3'
  | 'accent-gray-4'
  | 'accent-gray-5'
  | 'accent-gray-6'
  | 'accent-gray-7'
  | 'accent-gray-8'
  | 'accent-gray-9'
  | 'accent-social-linkedin'
  | 'accent-social-facebook'
  | 'accent-social-twitter'
  | 'accent-social-instagram'
  | 'accent-informa-bar'
  | 'accent-danger'
  | 'accent-error'
  | 'accent-auto'

export type TScrollPadding =
  | 'scroll-p-0'
  | 'scroll-p-1'
  | 'scroll-p-2'
  | 'scroll-p-3'
  | 'scroll-p-4'
  | 'scroll-p-5'
  | 'scroll-p-6'
  | 'scroll-p-7'
  | 'scroll-p-8'
  | 'scroll-p-9'
  | 'scroll-p-10'
  | 'scroll-p-11'
  | 'scroll-p-12'
  | 'scroll-p-13'
  | 'scroll-p-14'
  | 'scroll-p-15'
  | 'scroll-p-16'
  | 'scroll-p-17'
  | 'scroll-p-18'
  | 'scroll-p-19'
  | 'scroll-p-20'
  | 'scroll-p-21'
  | 'scroll-p-22'
  | 'scroll-p-23'
  | 'scroll-p-24'
  | 'scroll-p-25'
  | 'scroll-p-26'
  | 'scroll-p-27'
  | 'scroll-p-28'
  | 'scroll-p-29'
  | 'scroll-p-30'
  | 'scroll-p-32'
  | 'scroll-p-33'
  | 'scroll-p-34'
  | 'scroll-p-35'
  | 'scroll-p-36'
  | 'scroll-p-37'
  | 'scroll-p-38'
  | 'scroll-p-40'
  | 'scroll-p-41'
  | 'scroll-p-42'
  | 'scroll-p-44'
  | 'scroll-p-45'
  | 'scroll-p-46'
  | 'scroll-p-48'
  | 'scroll-p-50'
  | 'scroll-p-51'
  | 'scroll-p-52'
  | 'scroll-p-53'
  | 'scroll-p-56'
  | 'scroll-p-58'
  | 'scroll-p-60'
  | 'scroll-p-61'
  | 'scroll-p-64'
  | 'scroll-p-66'
  | 'scroll-p-70'
  | 'scroll-p-71'
  | 'scroll-p-72'
  | 'scroll-p-77'
  | 'scroll-p-80'
  | 'scroll-p-82'
  | 'scroll-p-85'
  | 'scroll-p-87'
  | 'scroll-p-88'
  | 'scroll-p-90'
  | 'scroll-p-95'
  | 'scroll-p-96'
  | 'scroll-p-100'
  | 'scroll-p-110'
  | 'scroll-p-112'
  | 'scroll-p-116'
  | 'scroll-p-120'
  | 'scroll-p-124'
  | 'scroll-p-126'
  | 'scroll-p-130'
  | 'scroll-p-132'
  | 'scroll-p-144'
  | 'scroll-p-150'
  | 'scroll-p-156'
  | 'scroll-p-160'
  | 'scroll-p-164'
  | 'scroll-p-170'
  | 'scroll-p-172'
  | 'scroll-p-178'
  | 'scroll-p-180'
  | 'scroll-p-182'
  | 'scroll-p-186'
  | 'scroll-p-190'
  | 'scroll-p-196'
  | 'scroll-p-200'
  | 'scroll-p-202'
  | 'scroll-p-216'
  | 'scroll-p-220'
  | 'scroll-p-222'
  | 'scroll-p-235'
  | 'scroll-p-248'
  | 'scroll-p-255'
  | 'scroll-p-258'
  | 'scroll-p-264'
  | 'scroll-p-270'
  | 'scroll-p-275'
  | 'scroll-p-280'
  | 'scroll-p-296'
  | 'scroll-p-300'
  | 'scroll-p-304'
  | 'scroll-p-310'
  | 'scroll-p-320'
  | 'scroll-p-324'
  | 'scroll-p-330'
  | 'scroll-p-336'
  | 'scroll-p-340'
  | 'scroll-p-350'
  | 'scroll-p-360'
  | 'scroll-p-370'
  | 'scroll-p-380'
  | 'scroll-p-390'
  | 'scroll-p-632'
  | 'scroll-p-680'
  | 'scroll-p-720'
  | 'scroll-p-fit'
  | 'scroll-p-max'
  | 'scroll-py-0'
  | 'scroll-py-1'
  | 'scroll-py-2'
  | 'scroll-py-3'
  | 'scroll-py-4'
  | 'scroll-py-5'
  | 'scroll-py-6'
  | 'scroll-py-7'
  | 'scroll-py-8'
  | 'scroll-py-9'
  | 'scroll-py-10'
  | 'scroll-py-11'
  | 'scroll-py-12'
  | 'scroll-py-13'
  | 'scroll-py-14'
  | 'scroll-py-15'
  | 'scroll-py-16'
  | 'scroll-py-17'
  | 'scroll-py-18'
  | 'scroll-py-19'
  | 'scroll-py-20'
  | 'scroll-py-21'
  | 'scroll-py-22'
  | 'scroll-py-23'
  | 'scroll-py-24'
  | 'scroll-py-25'
  | 'scroll-py-26'
  | 'scroll-py-27'
  | 'scroll-py-28'
  | 'scroll-py-29'
  | 'scroll-py-30'
  | 'scroll-py-32'
  | 'scroll-py-33'
  | 'scroll-py-34'
  | 'scroll-py-35'
  | 'scroll-py-36'
  | 'scroll-py-37'
  | 'scroll-py-38'
  | 'scroll-py-40'
  | 'scroll-py-41'
  | 'scroll-py-42'
  | 'scroll-py-44'
  | 'scroll-py-45'
  | 'scroll-py-46'
  | 'scroll-py-48'
  | 'scroll-py-50'
  | 'scroll-py-51'
  | 'scroll-py-52'
  | 'scroll-py-53'
  | 'scroll-py-56'
  | 'scroll-py-58'
  | 'scroll-py-60'
  | 'scroll-py-61'
  | 'scroll-py-64'
  | 'scroll-py-66'
  | 'scroll-py-70'
  | 'scroll-py-71'
  | 'scroll-py-72'
  | 'scroll-py-77'
  | 'scroll-py-80'
  | 'scroll-py-82'
  | 'scroll-py-85'
  | 'scroll-py-87'
  | 'scroll-py-88'
  | 'scroll-py-90'
  | 'scroll-py-95'
  | 'scroll-py-96'
  | 'scroll-py-100'
  | 'scroll-py-110'
  | 'scroll-py-112'
  | 'scroll-py-116'
  | 'scroll-py-120'
  | 'scroll-py-124'
  | 'scroll-py-126'
  | 'scroll-py-130'
  | 'scroll-py-132'
  | 'scroll-py-144'
  | 'scroll-py-150'
  | 'scroll-py-156'
  | 'scroll-py-160'
  | 'scroll-py-164'
  | 'scroll-py-170'
  | 'scroll-py-172'
  | 'scroll-py-178'
  | 'scroll-py-180'
  | 'scroll-py-182'
  | 'scroll-py-186'
  | 'scroll-py-190'
  | 'scroll-py-196'
  | 'scroll-py-200'
  | 'scroll-py-202'
  | 'scroll-py-216'
  | 'scroll-py-220'
  | 'scroll-py-222'
  | 'scroll-py-235'
  | 'scroll-py-248'
  | 'scroll-py-255'
  | 'scroll-py-258'
  | 'scroll-py-264'
  | 'scroll-py-270'
  | 'scroll-py-275'
  | 'scroll-py-280'
  | 'scroll-py-296'
  | 'scroll-py-300'
  | 'scroll-py-304'
  | 'scroll-py-310'
  | 'scroll-py-320'
  | 'scroll-py-324'
  | 'scroll-py-330'
  | 'scroll-py-336'
  | 'scroll-py-340'
  | 'scroll-py-350'
  | 'scroll-py-360'
  | 'scroll-py-370'
  | 'scroll-py-380'
  | 'scroll-py-390'
  | 'scroll-py-632'
  | 'scroll-py-680'
  | 'scroll-py-720'
  | 'scroll-py-fit'
  | 'scroll-py-max'
  | 'scroll-px-0'
  | 'scroll-px-1'
  | 'scroll-px-2'
  | 'scroll-px-3'
  | 'scroll-px-4'
  | 'scroll-px-5'
  | 'scroll-px-6'
  | 'scroll-px-7'
  | 'scroll-px-8'
  | 'scroll-px-9'
  | 'scroll-px-10'
  | 'scroll-px-11'
  | 'scroll-px-12'
  | 'scroll-px-13'
  | 'scroll-px-14'
  | 'scroll-px-15'
  | 'scroll-px-16'
  | 'scroll-px-17'
  | 'scroll-px-18'
  | 'scroll-px-19'
  | 'scroll-px-20'
  | 'scroll-px-21'
  | 'scroll-px-22'
  | 'scroll-px-23'
  | 'scroll-px-24'
  | 'scroll-px-25'
  | 'scroll-px-26'
  | 'scroll-px-27'
  | 'scroll-px-28'
  | 'scroll-px-29'
  | 'scroll-px-30'
  | 'scroll-px-32'
  | 'scroll-px-33'
  | 'scroll-px-34'
  | 'scroll-px-35'
  | 'scroll-px-36'
  | 'scroll-px-37'
  | 'scroll-px-38'
  | 'scroll-px-40'
  | 'scroll-px-41'
  | 'scroll-px-42'
  | 'scroll-px-44'
  | 'scroll-px-45'
  | 'scroll-px-46'
  | 'scroll-px-48'
  | 'scroll-px-50'
  | 'scroll-px-51'
  | 'scroll-px-52'
  | 'scroll-px-53'
  | 'scroll-px-56'
  | 'scroll-px-58'
  | 'scroll-px-60'
  | 'scroll-px-61'
  | 'scroll-px-64'
  | 'scroll-px-66'
  | 'scroll-px-70'
  | 'scroll-px-71'
  | 'scroll-px-72'
  | 'scroll-px-77'
  | 'scroll-px-80'
  | 'scroll-px-82'
  | 'scroll-px-85'
  | 'scroll-px-87'
  | 'scroll-px-88'
  | 'scroll-px-90'
  | 'scroll-px-95'
  | 'scroll-px-96'
  | 'scroll-px-100'
  | 'scroll-px-110'
  | 'scroll-px-112'
  | 'scroll-px-116'
  | 'scroll-px-120'
  | 'scroll-px-124'
  | 'scroll-px-126'
  | 'scroll-px-130'
  | 'scroll-px-132'
  | 'scroll-px-144'
  | 'scroll-px-150'
  | 'scroll-px-156'
  | 'scroll-px-160'
  | 'scroll-px-164'
  | 'scroll-px-170'
  | 'scroll-px-172'
  | 'scroll-px-178'
  | 'scroll-px-180'
  | 'scroll-px-182'
  | 'scroll-px-186'
  | 'scroll-px-190'
  | 'scroll-px-196'
  | 'scroll-px-200'
  | 'scroll-px-202'
  | 'scroll-px-216'
  | 'scroll-px-220'
  | 'scroll-px-222'
  | 'scroll-px-235'
  | 'scroll-px-248'
  | 'scroll-px-255'
  | 'scroll-px-258'
  | 'scroll-px-264'
  | 'scroll-px-270'
  | 'scroll-px-275'
  | 'scroll-px-280'
  | 'scroll-px-296'
  | 'scroll-px-300'
  | 'scroll-px-304'
  | 'scroll-px-310'
  | 'scroll-px-320'
  | 'scroll-px-324'
  | 'scroll-px-330'
  | 'scroll-px-336'
  | 'scroll-px-340'
  | 'scroll-px-350'
  | 'scroll-px-360'
  | 'scroll-px-370'
  | 'scroll-px-380'
  | 'scroll-px-390'
  | 'scroll-px-632'
  | 'scroll-px-680'
  | 'scroll-px-720'
  | 'scroll-px-fit'
  | 'scroll-px-max'
  | 'scroll-pt-0'
  | 'scroll-pt-1'
  | 'scroll-pt-2'
  | 'scroll-pt-3'
  | 'scroll-pt-4'
  | 'scroll-pt-5'
  | 'scroll-pt-6'
  | 'scroll-pt-7'
  | 'scroll-pt-8'
  | 'scroll-pt-9'
  | 'scroll-pt-10'
  | 'scroll-pt-11'
  | 'scroll-pt-12'
  | 'scroll-pt-13'
  | 'scroll-pt-14'
  | 'scroll-pt-15'
  | 'scroll-pt-16'
  | 'scroll-pt-17'
  | 'scroll-pt-18'
  | 'scroll-pt-19'
  | 'scroll-pt-20'
  | 'scroll-pt-21'
  | 'scroll-pt-22'
  | 'scroll-pt-23'
  | 'scroll-pt-24'
  | 'scroll-pt-25'
  | 'scroll-pt-26'
  | 'scroll-pt-27'
  | 'scroll-pt-28'
  | 'scroll-pt-29'
  | 'scroll-pt-30'
  | 'scroll-pt-32'
  | 'scroll-pt-33'
  | 'scroll-pt-34'
  | 'scroll-pt-35'
  | 'scroll-pt-36'
  | 'scroll-pt-37'
  | 'scroll-pt-38'
  | 'scroll-pt-40'
  | 'scroll-pt-41'
  | 'scroll-pt-42'
  | 'scroll-pt-44'
  | 'scroll-pt-45'
  | 'scroll-pt-46'
  | 'scroll-pt-48'
  | 'scroll-pt-50'
  | 'scroll-pt-51'
  | 'scroll-pt-52'
  | 'scroll-pt-53'
  | 'scroll-pt-56'
  | 'scroll-pt-58'
  | 'scroll-pt-60'
  | 'scroll-pt-61'
  | 'scroll-pt-64'
  | 'scroll-pt-66'
  | 'scroll-pt-70'
  | 'scroll-pt-71'
  | 'scroll-pt-72'
  | 'scroll-pt-77'
  | 'scroll-pt-80'
  | 'scroll-pt-82'
  | 'scroll-pt-85'
  | 'scroll-pt-87'
  | 'scroll-pt-88'
  | 'scroll-pt-90'
  | 'scroll-pt-95'
  | 'scroll-pt-96'
  | 'scroll-pt-100'
  | 'scroll-pt-110'
  | 'scroll-pt-112'
  | 'scroll-pt-116'
  | 'scroll-pt-120'
  | 'scroll-pt-124'
  | 'scroll-pt-126'
  | 'scroll-pt-130'
  | 'scroll-pt-132'
  | 'scroll-pt-144'
  | 'scroll-pt-150'
  | 'scroll-pt-156'
  | 'scroll-pt-160'
  | 'scroll-pt-164'
  | 'scroll-pt-170'
  | 'scroll-pt-172'
  | 'scroll-pt-178'
  | 'scroll-pt-180'
  | 'scroll-pt-182'
  | 'scroll-pt-186'
  | 'scroll-pt-190'
  | 'scroll-pt-196'
  | 'scroll-pt-200'
  | 'scroll-pt-202'
  | 'scroll-pt-216'
  | 'scroll-pt-220'
  | 'scroll-pt-222'
  | 'scroll-pt-235'
  | 'scroll-pt-248'
  | 'scroll-pt-255'
  | 'scroll-pt-258'
  | 'scroll-pt-264'
  | 'scroll-pt-270'
  | 'scroll-pt-275'
  | 'scroll-pt-280'
  | 'scroll-pt-296'
  | 'scroll-pt-300'
  | 'scroll-pt-304'
  | 'scroll-pt-310'
  | 'scroll-pt-320'
  | 'scroll-pt-324'
  | 'scroll-pt-330'
  | 'scroll-pt-336'
  | 'scroll-pt-340'
  | 'scroll-pt-350'
  | 'scroll-pt-360'
  | 'scroll-pt-370'
  | 'scroll-pt-380'
  | 'scroll-pt-390'
  | 'scroll-pt-632'
  | 'scroll-pt-680'
  | 'scroll-pt-720'
  | 'scroll-pt-fit'
  | 'scroll-pt-max'
  | 'scroll-pr-0'
  | 'scroll-pr-1'
  | 'scroll-pr-2'
  | 'scroll-pr-3'
  | 'scroll-pr-4'
  | 'scroll-pr-5'
  | 'scroll-pr-6'
  | 'scroll-pr-7'
  | 'scroll-pr-8'
  | 'scroll-pr-9'
  | 'scroll-pr-10'
  | 'scroll-pr-11'
  | 'scroll-pr-12'
  | 'scroll-pr-13'
  | 'scroll-pr-14'
  | 'scroll-pr-15'
  | 'scroll-pr-16'
  | 'scroll-pr-17'
  | 'scroll-pr-18'
  | 'scroll-pr-19'
  | 'scroll-pr-20'
  | 'scroll-pr-21'
  | 'scroll-pr-22'
  | 'scroll-pr-23'
  | 'scroll-pr-24'
  | 'scroll-pr-25'
  | 'scroll-pr-26'
  | 'scroll-pr-27'
  | 'scroll-pr-28'
  | 'scroll-pr-29'
  | 'scroll-pr-30'
  | 'scroll-pr-32'
  | 'scroll-pr-33'
  | 'scroll-pr-34'
  | 'scroll-pr-35'
  | 'scroll-pr-36'
  | 'scroll-pr-37'
  | 'scroll-pr-38'
  | 'scroll-pr-40'
  | 'scroll-pr-41'
  | 'scroll-pr-42'
  | 'scroll-pr-44'
  | 'scroll-pr-45'
  | 'scroll-pr-46'
  | 'scroll-pr-48'
  | 'scroll-pr-50'
  | 'scroll-pr-51'
  | 'scroll-pr-52'
  | 'scroll-pr-53'
  | 'scroll-pr-56'
  | 'scroll-pr-58'
  | 'scroll-pr-60'
  | 'scroll-pr-61'
  | 'scroll-pr-64'
  | 'scroll-pr-66'
  | 'scroll-pr-70'
  | 'scroll-pr-71'
  | 'scroll-pr-72'
  | 'scroll-pr-77'
  | 'scroll-pr-80'
  | 'scroll-pr-82'
  | 'scroll-pr-85'
  | 'scroll-pr-87'
  | 'scroll-pr-88'
  | 'scroll-pr-90'
  | 'scroll-pr-95'
  | 'scroll-pr-96'
  | 'scroll-pr-100'
  | 'scroll-pr-110'
  | 'scroll-pr-112'
  | 'scroll-pr-116'
  | 'scroll-pr-120'
  | 'scroll-pr-124'
  | 'scroll-pr-126'
  | 'scroll-pr-130'
  | 'scroll-pr-132'
  | 'scroll-pr-144'
  | 'scroll-pr-150'
  | 'scroll-pr-156'
  | 'scroll-pr-160'
  | 'scroll-pr-164'
  | 'scroll-pr-170'
  | 'scroll-pr-172'
  | 'scroll-pr-178'
  | 'scroll-pr-180'
  | 'scroll-pr-182'
  | 'scroll-pr-186'
  | 'scroll-pr-190'
  | 'scroll-pr-196'
  | 'scroll-pr-200'
  | 'scroll-pr-202'
  | 'scroll-pr-216'
  | 'scroll-pr-220'
  | 'scroll-pr-222'
  | 'scroll-pr-235'
  | 'scroll-pr-248'
  | 'scroll-pr-255'
  | 'scroll-pr-258'
  | 'scroll-pr-264'
  | 'scroll-pr-270'
  | 'scroll-pr-275'
  | 'scroll-pr-280'
  | 'scroll-pr-296'
  | 'scroll-pr-300'
  | 'scroll-pr-304'
  | 'scroll-pr-310'
  | 'scroll-pr-320'
  | 'scroll-pr-324'
  | 'scroll-pr-330'
  | 'scroll-pr-336'
  | 'scroll-pr-340'
  | 'scroll-pr-350'
  | 'scroll-pr-360'
  | 'scroll-pr-370'
  | 'scroll-pr-380'
  | 'scroll-pr-390'
  | 'scroll-pr-632'
  | 'scroll-pr-680'
  | 'scroll-pr-720'
  | 'scroll-pr-fit'
  | 'scroll-pr-max'
  | 'scroll-pb-0'
  | 'scroll-pb-1'
  | 'scroll-pb-2'
  | 'scroll-pb-3'
  | 'scroll-pb-4'
  | 'scroll-pb-5'
  | 'scroll-pb-6'
  | 'scroll-pb-7'
  | 'scroll-pb-8'
  | 'scroll-pb-9'
  | 'scroll-pb-10'
  | 'scroll-pb-11'
  | 'scroll-pb-12'
  | 'scroll-pb-13'
  | 'scroll-pb-14'
  | 'scroll-pb-15'
  | 'scroll-pb-16'
  | 'scroll-pb-17'
  | 'scroll-pb-18'
  | 'scroll-pb-19'
  | 'scroll-pb-20'
  | 'scroll-pb-21'
  | 'scroll-pb-22'
  | 'scroll-pb-23'
  | 'scroll-pb-24'
  | 'scroll-pb-25'
  | 'scroll-pb-26'
  | 'scroll-pb-27'
  | 'scroll-pb-28'
  | 'scroll-pb-29'
  | 'scroll-pb-30'
  | 'scroll-pb-32'
  | 'scroll-pb-33'
  | 'scroll-pb-34'
  | 'scroll-pb-35'
  | 'scroll-pb-36'
  | 'scroll-pb-37'
  | 'scroll-pb-38'
  | 'scroll-pb-40'
  | 'scroll-pb-41'
  | 'scroll-pb-42'
  | 'scroll-pb-44'
  | 'scroll-pb-45'
  | 'scroll-pb-46'
  | 'scroll-pb-48'
  | 'scroll-pb-50'
  | 'scroll-pb-51'
  | 'scroll-pb-52'
  | 'scroll-pb-53'
  | 'scroll-pb-56'
  | 'scroll-pb-58'
  | 'scroll-pb-60'
  | 'scroll-pb-61'
  | 'scroll-pb-64'
  | 'scroll-pb-66'
  | 'scroll-pb-70'
  | 'scroll-pb-71'
  | 'scroll-pb-72'
  | 'scroll-pb-77'
  | 'scroll-pb-80'
  | 'scroll-pb-82'
  | 'scroll-pb-85'
  | 'scroll-pb-87'
  | 'scroll-pb-88'
  | 'scroll-pb-90'
  | 'scroll-pb-95'
  | 'scroll-pb-96'
  | 'scroll-pb-100'
  | 'scroll-pb-110'
  | 'scroll-pb-112'
  | 'scroll-pb-116'
  | 'scroll-pb-120'
  | 'scroll-pb-124'
  | 'scroll-pb-126'
  | 'scroll-pb-130'
  | 'scroll-pb-132'
  | 'scroll-pb-144'
  | 'scroll-pb-150'
  | 'scroll-pb-156'
  | 'scroll-pb-160'
  | 'scroll-pb-164'
  | 'scroll-pb-170'
  | 'scroll-pb-172'
  | 'scroll-pb-178'
  | 'scroll-pb-180'
  | 'scroll-pb-182'
  | 'scroll-pb-186'
  | 'scroll-pb-190'
  | 'scroll-pb-196'
  | 'scroll-pb-200'
  | 'scroll-pb-202'
  | 'scroll-pb-216'
  | 'scroll-pb-220'
  | 'scroll-pb-222'
  | 'scroll-pb-235'
  | 'scroll-pb-248'
  | 'scroll-pb-255'
  | 'scroll-pb-258'
  | 'scroll-pb-264'
  | 'scroll-pb-270'
  | 'scroll-pb-275'
  | 'scroll-pb-280'
  | 'scroll-pb-296'
  | 'scroll-pb-300'
  | 'scroll-pb-304'
  | 'scroll-pb-310'
  | 'scroll-pb-320'
  | 'scroll-pb-324'
  | 'scroll-pb-330'
  | 'scroll-pb-336'
  | 'scroll-pb-340'
  | 'scroll-pb-350'
  | 'scroll-pb-360'
  | 'scroll-pb-370'
  | 'scroll-pb-380'
  | 'scroll-pb-390'
  | 'scroll-pb-632'
  | 'scroll-pb-680'
  | 'scroll-pb-720'
  | 'scroll-pb-fit'
  | 'scroll-pb-max'
  | 'scroll-pl-0'
  | 'scroll-pl-1'
  | 'scroll-pl-2'
  | 'scroll-pl-3'
  | 'scroll-pl-4'
  | 'scroll-pl-5'
  | 'scroll-pl-6'
  | 'scroll-pl-7'
  | 'scroll-pl-8'
  | 'scroll-pl-9'
  | 'scroll-pl-10'
  | 'scroll-pl-11'
  | 'scroll-pl-12'
  | 'scroll-pl-13'
  | 'scroll-pl-14'
  | 'scroll-pl-15'
  | 'scroll-pl-16'
  | 'scroll-pl-17'
  | 'scroll-pl-18'
  | 'scroll-pl-19'
  | 'scroll-pl-20'
  | 'scroll-pl-21'
  | 'scroll-pl-22'
  | 'scroll-pl-23'
  | 'scroll-pl-24'
  | 'scroll-pl-25'
  | 'scroll-pl-26'
  | 'scroll-pl-27'
  | 'scroll-pl-28'
  | 'scroll-pl-29'
  | 'scroll-pl-30'
  | 'scroll-pl-32'
  | 'scroll-pl-33'
  | 'scroll-pl-34'
  | 'scroll-pl-35'
  | 'scroll-pl-36'
  | 'scroll-pl-37'
  | 'scroll-pl-38'
  | 'scroll-pl-40'
  | 'scroll-pl-41'
  | 'scroll-pl-42'
  | 'scroll-pl-44'
  | 'scroll-pl-45'
  | 'scroll-pl-46'
  | 'scroll-pl-48'
  | 'scroll-pl-50'
  | 'scroll-pl-51'
  | 'scroll-pl-52'
  | 'scroll-pl-53'
  | 'scroll-pl-56'
  | 'scroll-pl-58'
  | 'scroll-pl-60'
  | 'scroll-pl-61'
  | 'scroll-pl-64'
  | 'scroll-pl-66'
  | 'scroll-pl-70'
  | 'scroll-pl-71'
  | 'scroll-pl-72'
  | 'scroll-pl-77'
  | 'scroll-pl-80'
  | 'scroll-pl-82'
  | 'scroll-pl-85'
  | 'scroll-pl-87'
  | 'scroll-pl-88'
  | 'scroll-pl-90'
  | 'scroll-pl-95'
  | 'scroll-pl-96'
  | 'scroll-pl-100'
  | 'scroll-pl-110'
  | 'scroll-pl-112'
  | 'scroll-pl-116'
  | 'scroll-pl-120'
  | 'scroll-pl-124'
  | 'scroll-pl-126'
  | 'scroll-pl-130'
  | 'scroll-pl-132'
  | 'scroll-pl-144'
  | 'scroll-pl-150'
  | 'scroll-pl-156'
  | 'scroll-pl-160'
  | 'scroll-pl-164'
  | 'scroll-pl-170'
  | 'scroll-pl-172'
  | 'scroll-pl-178'
  | 'scroll-pl-180'
  | 'scroll-pl-182'
  | 'scroll-pl-186'
  | 'scroll-pl-190'
  | 'scroll-pl-196'
  | 'scroll-pl-200'
  | 'scroll-pl-202'
  | 'scroll-pl-216'
  | 'scroll-pl-220'
  | 'scroll-pl-222'
  | 'scroll-pl-235'
  | 'scroll-pl-248'
  | 'scroll-pl-255'
  | 'scroll-pl-258'
  | 'scroll-pl-264'
  | 'scroll-pl-270'
  | 'scroll-pl-275'
  | 'scroll-pl-280'
  | 'scroll-pl-296'
  | 'scroll-pl-300'
  | 'scroll-pl-304'
  | 'scroll-pl-310'
  | 'scroll-pl-320'
  | 'scroll-pl-324'
  | 'scroll-pl-330'
  | 'scroll-pl-336'
  | 'scroll-pl-340'
  | 'scroll-pl-350'
  | 'scroll-pl-360'
  | 'scroll-pl-370'
  | 'scroll-pl-380'
  | 'scroll-pl-390'
  | 'scroll-pl-632'
  | 'scroll-pl-680'
  | 'scroll-pl-720'
  | 'scroll-pl-fit'
  | 'scroll-pl-max'

export type TScrollMargin =
  | 'scroll-m-0'
  | 'scroll-m-1'
  | 'scroll-m-2'
  | 'scroll-m-3'
  | 'scroll-m-4'
  | 'scroll-m-5'
  | 'scroll-m-6'
  | 'scroll-m-7'
  | 'scroll-m-8'
  | 'scroll-m-9'
  | 'scroll-m-10'
  | 'scroll-m-11'
  | 'scroll-m-12'
  | 'scroll-m-13'
  | 'scroll-m-14'
  | 'scroll-m-15'
  | 'scroll-m-16'
  | 'scroll-m-17'
  | 'scroll-m-18'
  | 'scroll-m-19'
  | 'scroll-m-20'
  | 'scroll-m-21'
  | 'scroll-m-22'
  | 'scroll-m-23'
  | 'scroll-m-24'
  | 'scroll-m-25'
  | 'scroll-m-26'
  | 'scroll-m-27'
  | 'scroll-m-28'
  | 'scroll-m-29'
  | 'scroll-m-30'
  | 'scroll-m-32'
  | 'scroll-m-33'
  | 'scroll-m-34'
  | 'scroll-m-35'
  | 'scroll-m-36'
  | 'scroll-m-37'
  | 'scroll-m-38'
  | 'scroll-m-40'
  | 'scroll-m-41'
  | 'scroll-m-42'
  | 'scroll-m-44'
  | 'scroll-m-45'
  | 'scroll-m-46'
  | 'scroll-m-48'
  | 'scroll-m-50'
  | 'scroll-m-51'
  | 'scroll-m-52'
  | 'scroll-m-53'
  | 'scroll-m-56'
  | 'scroll-m-58'
  | 'scroll-m-60'
  | 'scroll-m-61'
  | 'scroll-m-64'
  | 'scroll-m-66'
  | 'scroll-m-70'
  | 'scroll-m-71'
  | 'scroll-m-72'
  | 'scroll-m-77'
  | 'scroll-m-80'
  | 'scroll-m-82'
  | 'scroll-m-85'
  | 'scroll-m-87'
  | 'scroll-m-88'
  | 'scroll-m-90'
  | 'scroll-m-95'
  | 'scroll-m-96'
  | 'scroll-m-100'
  | 'scroll-m-110'
  | 'scroll-m-112'
  | 'scroll-m-116'
  | 'scroll-m-120'
  | 'scroll-m-124'
  | 'scroll-m-126'
  | 'scroll-m-130'
  | 'scroll-m-132'
  | 'scroll-m-144'
  | 'scroll-m-150'
  | 'scroll-m-156'
  | 'scroll-m-160'
  | 'scroll-m-164'
  | 'scroll-m-170'
  | 'scroll-m-172'
  | 'scroll-m-178'
  | 'scroll-m-180'
  | 'scroll-m-182'
  | 'scroll-m-186'
  | 'scroll-m-190'
  | 'scroll-m-196'
  | 'scroll-m-200'
  | 'scroll-m-202'
  | 'scroll-m-216'
  | 'scroll-m-220'
  | 'scroll-m-222'
  | 'scroll-m-235'
  | 'scroll-m-248'
  | 'scroll-m-255'
  | 'scroll-m-258'
  | 'scroll-m-264'
  | 'scroll-m-270'
  | 'scroll-m-275'
  | 'scroll-m-280'
  | 'scroll-m-296'
  | 'scroll-m-300'
  | 'scroll-m-304'
  | 'scroll-m-310'
  | 'scroll-m-320'
  | 'scroll-m-324'
  | 'scroll-m-330'
  | 'scroll-m-336'
  | 'scroll-m-340'
  | 'scroll-m-350'
  | 'scroll-m-360'
  | 'scroll-m-370'
  | 'scroll-m-380'
  | 'scroll-m-390'
  | 'scroll-m-632'
  | 'scroll-m-680'
  | 'scroll-m-720'
  | 'scroll-m-fit'
  | 'scroll-m-max'
  | 'scroll-my-0'
  | 'scroll-my-1'
  | 'scroll-my-2'
  | 'scroll-my-3'
  | 'scroll-my-4'
  | 'scroll-my-5'
  | 'scroll-my-6'
  | 'scroll-my-7'
  | 'scroll-my-8'
  | 'scroll-my-9'
  | 'scroll-my-10'
  | 'scroll-my-11'
  | 'scroll-my-12'
  | 'scroll-my-13'
  | 'scroll-my-14'
  | 'scroll-my-15'
  | 'scroll-my-16'
  | 'scroll-my-17'
  | 'scroll-my-18'
  | 'scroll-my-19'
  | 'scroll-my-20'
  | 'scroll-my-21'
  | 'scroll-my-22'
  | 'scroll-my-23'
  | 'scroll-my-24'
  | 'scroll-my-25'
  | 'scroll-my-26'
  | 'scroll-my-27'
  | 'scroll-my-28'
  | 'scroll-my-29'
  | 'scroll-my-30'
  | 'scroll-my-32'
  | 'scroll-my-33'
  | 'scroll-my-34'
  | 'scroll-my-35'
  | 'scroll-my-36'
  | 'scroll-my-37'
  | 'scroll-my-38'
  | 'scroll-my-40'
  | 'scroll-my-41'
  | 'scroll-my-42'
  | 'scroll-my-44'
  | 'scroll-my-45'
  | 'scroll-my-46'
  | 'scroll-my-48'
  | 'scroll-my-50'
  | 'scroll-my-51'
  | 'scroll-my-52'
  | 'scroll-my-53'
  | 'scroll-my-56'
  | 'scroll-my-58'
  | 'scroll-my-60'
  | 'scroll-my-61'
  | 'scroll-my-64'
  | 'scroll-my-66'
  | 'scroll-my-70'
  | 'scroll-my-71'
  | 'scroll-my-72'
  | 'scroll-my-77'
  | 'scroll-my-80'
  | 'scroll-my-82'
  | 'scroll-my-85'
  | 'scroll-my-87'
  | 'scroll-my-88'
  | 'scroll-my-90'
  | 'scroll-my-95'
  | 'scroll-my-96'
  | 'scroll-my-100'
  | 'scroll-my-110'
  | 'scroll-my-112'
  | 'scroll-my-116'
  | 'scroll-my-120'
  | 'scroll-my-124'
  | 'scroll-my-126'
  | 'scroll-my-130'
  | 'scroll-my-132'
  | 'scroll-my-144'
  | 'scroll-my-150'
  | 'scroll-my-156'
  | 'scroll-my-160'
  | 'scroll-my-164'
  | 'scroll-my-170'
  | 'scroll-my-172'
  | 'scroll-my-178'
  | 'scroll-my-180'
  | 'scroll-my-182'
  | 'scroll-my-186'
  | 'scroll-my-190'
  | 'scroll-my-196'
  | 'scroll-my-200'
  | 'scroll-my-202'
  | 'scroll-my-216'
  | 'scroll-my-220'
  | 'scroll-my-222'
  | 'scroll-my-235'
  | 'scroll-my-248'
  | 'scroll-my-255'
  | 'scroll-my-258'
  | 'scroll-my-264'
  | 'scroll-my-270'
  | 'scroll-my-275'
  | 'scroll-my-280'
  | 'scroll-my-296'
  | 'scroll-my-300'
  | 'scroll-my-304'
  | 'scroll-my-310'
  | 'scroll-my-320'
  | 'scroll-my-324'
  | 'scroll-my-330'
  | 'scroll-my-336'
  | 'scroll-my-340'
  | 'scroll-my-350'
  | 'scroll-my-360'
  | 'scroll-my-370'
  | 'scroll-my-380'
  | 'scroll-my-390'
  | 'scroll-my-632'
  | 'scroll-my-680'
  | 'scroll-my-720'
  | 'scroll-my-fit'
  | 'scroll-my-max'
  | 'scroll-mx-0'
  | 'scroll-mx-1'
  | 'scroll-mx-2'
  | 'scroll-mx-3'
  | 'scroll-mx-4'
  | 'scroll-mx-5'
  | 'scroll-mx-6'
  | 'scroll-mx-7'
  | 'scroll-mx-8'
  | 'scroll-mx-9'
  | 'scroll-mx-10'
  | 'scroll-mx-11'
  | 'scroll-mx-12'
  | 'scroll-mx-13'
  | 'scroll-mx-14'
  | 'scroll-mx-15'
  | 'scroll-mx-16'
  | 'scroll-mx-17'
  | 'scroll-mx-18'
  | 'scroll-mx-19'
  | 'scroll-mx-20'
  | 'scroll-mx-21'
  | 'scroll-mx-22'
  | 'scroll-mx-23'
  | 'scroll-mx-24'
  | 'scroll-mx-25'
  | 'scroll-mx-26'
  | 'scroll-mx-27'
  | 'scroll-mx-28'
  | 'scroll-mx-29'
  | 'scroll-mx-30'
  | 'scroll-mx-32'
  | 'scroll-mx-33'
  | 'scroll-mx-34'
  | 'scroll-mx-35'
  | 'scroll-mx-36'
  | 'scroll-mx-37'
  | 'scroll-mx-38'
  | 'scroll-mx-40'
  | 'scroll-mx-41'
  | 'scroll-mx-42'
  | 'scroll-mx-44'
  | 'scroll-mx-45'
  | 'scroll-mx-46'
  | 'scroll-mx-48'
  | 'scroll-mx-50'
  | 'scroll-mx-51'
  | 'scroll-mx-52'
  | 'scroll-mx-53'
  | 'scroll-mx-56'
  | 'scroll-mx-58'
  | 'scroll-mx-60'
  | 'scroll-mx-61'
  | 'scroll-mx-64'
  | 'scroll-mx-66'
  | 'scroll-mx-70'
  | 'scroll-mx-71'
  | 'scroll-mx-72'
  | 'scroll-mx-77'
  | 'scroll-mx-80'
  | 'scroll-mx-82'
  | 'scroll-mx-85'
  | 'scroll-mx-87'
  | 'scroll-mx-88'
  | 'scroll-mx-90'
  | 'scroll-mx-95'
  | 'scroll-mx-96'
  | 'scroll-mx-100'
  | 'scroll-mx-110'
  | 'scroll-mx-112'
  | 'scroll-mx-116'
  | 'scroll-mx-120'
  | 'scroll-mx-124'
  | 'scroll-mx-126'
  | 'scroll-mx-130'
  | 'scroll-mx-132'
  | 'scroll-mx-144'
  | 'scroll-mx-150'
  | 'scroll-mx-156'
  | 'scroll-mx-160'
  | 'scroll-mx-164'
  | 'scroll-mx-170'
  | 'scroll-mx-172'
  | 'scroll-mx-178'
  | 'scroll-mx-180'
  | 'scroll-mx-182'
  | 'scroll-mx-186'
  | 'scroll-mx-190'
  | 'scroll-mx-196'
  | 'scroll-mx-200'
  | 'scroll-mx-202'
  | 'scroll-mx-216'
  | 'scroll-mx-220'
  | 'scroll-mx-222'
  | 'scroll-mx-235'
  | 'scroll-mx-248'
  | 'scroll-mx-255'
  | 'scroll-mx-258'
  | 'scroll-mx-264'
  | 'scroll-mx-270'
  | 'scroll-mx-275'
  | 'scroll-mx-280'
  | 'scroll-mx-296'
  | 'scroll-mx-300'
  | 'scroll-mx-304'
  | 'scroll-mx-310'
  | 'scroll-mx-320'
  | 'scroll-mx-324'
  | 'scroll-mx-330'
  | 'scroll-mx-336'
  | 'scroll-mx-340'
  | 'scroll-mx-350'
  | 'scroll-mx-360'
  | 'scroll-mx-370'
  | 'scroll-mx-380'
  | 'scroll-mx-390'
  | 'scroll-mx-632'
  | 'scroll-mx-680'
  | 'scroll-mx-720'
  | 'scroll-mx-fit'
  | 'scroll-mx-max'
  | 'scroll-mt-0'
  | 'scroll-mt-1'
  | 'scroll-mt-2'
  | 'scroll-mt-3'
  | 'scroll-mt-4'
  | 'scroll-mt-5'
  | 'scroll-mt-6'
  | 'scroll-mt-7'
  | 'scroll-mt-8'
  | 'scroll-mt-9'
  | 'scroll-mt-10'
  | 'scroll-mt-11'
  | 'scroll-mt-12'
  | 'scroll-mt-13'
  | 'scroll-mt-14'
  | 'scroll-mt-15'
  | 'scroll-mt-16'
  | 'scroll-mt-17'
  | 'scroll-mt-18'
  | 'scroll-mt-19'
  | 'scroll-mt-20'
  | 'scroll-mt-21'
  | 'scroll-mt-22'
  | 'scroll-mt-23'
  | 'scroll-mt-24'
  | 'scroll-mt-25'
  | 'scroll-mt-26'
  | 'scroll-mt-27'
  | 'scroll-mt-28'
  | 'scroll-mt-29'
  | 'scroll-mt-30'
  | 'scroll-mt-32'
  | 'scroll-mt-33'
  | 'scroll-mt-34'
  | 'scroll-mt-35'
  | 'scroll-mt-36'
  | 'scroll-mt-37'
  | 'scroll-mt-38'
  | 'scroll-mt-40'
  | 'scroll-mt-41'
  | 'scroll-mt-42'
  | 'scroll-mt-44'
  | 'scroll-mt-45'
  | 'scroll-mt-46'
  | 'scroll-mt-48'
  | 'scroll-mt-50'
  | 'scroll-mt-51'
  | 'scroll-mt-52'
  | 'scroll-mt-53'
  | 'scroll-mt-56'
  | 'scroll-mt-58'
  | 'scroll-mt-60'
  | 'scroll-mt-61'
  | 'scroll-mt-64'
  | 'scroll-mt-66'
  | 'scroll-mt-70'
  | 'scroll-mt-71'
  | 'scroll-mt-72'
  | 'scroll-mt-77'
  | 'scroll-mt-80'
  | 'scroll-mt-82'
  | 'scroll-mt-85'
  | 'scroll-mt-87'
  | 'scroll-mt-88'
  | 'scroll-mt-90'
  | 'scroll-mt-95'
  | 'scroll-mt-96'
  | 'scroll-mt-100'
  | 'scroll-mt-110'
  | 'scroll-mt-112'
  | 'scroll-mt-116'
  | 'scroll-mt-120'
  | 'scroll-mt-124'
  | 'scroll-mt-126'
  | 'scroll-mt-130'
  | 'scroll-mt-132'
  | 'scroll-mt-144'
  | 'scroll-mt-150'
  | 'scroll-mt-156'
  | 'scroll-mt-160'
  | 'scroll-mt-164'
  | 'scroll-mt-170'
  | 'scroll-mt-172'
  | 'scroll-mt-178'
  | 'scroll-mt-180'
  | 'scroll-mt-182'
  | 'scroll-mt-186'
  | 'scroll-mt-190'
  | 'scroll-mt-196'
  | 'scroll-mt-200'
  | 'scroll-mt-202'
  | 'scroll-mt-216'
  | 'scroll-mt-220'
  | 'scroll-mt-222'
  | 'scroll-mt-235'
  | 'scroll-mt-248'
  | 'scroll-mt-255'
  | 'scroll-mt-258'
  | 'scroll-mt-264'
  | 'scroll-mt-270'
  | 'scroll-mt-275'
  | 'scroll-mt-280'
  | 'scroll-mt-296'
  | 'scroll-mt-300'
  | 'scroll-mt-304'
  | 'scroll-mt-310'
  | 'scroll-mt-320'
  | 'scroll-mt-324'
  | 'scroll-mt-330'
  | 'scroll-mt-336'
  | 'scroll-mt-340'
  | 'scroll-mt-350'
  | 'scroll-mt-360'
  | 'scroll-mt-370'
  | 'scroll-mt-380'
  | 'scroll-mt-390'
  | 'scroll-mt-632'
  | 'scroll-mt-680'
  | 'scroll-mt-720'
  | 'scroll-mt-fit'
  | 'scroll-mt-max'
  | 'scroll-mr-0'
  | 'scroll-mr-1'
  | 'scroll-mr-2'
  | 'scroll-mr-3'
  | 'scroll-mr-4'
  | 'scroll-mr-5'
  | 'scroll-mr-6'
  | 'scroll-mr-7'
  | 'scroll-mr-8'
  | 'scroll-mr-9'
  | 'scroll-mr-10'
  | 'scroll-mr-11'
  | 'scroll-mr-12'
  | 'scroll-mr-13'
  | 'scroll-mr-14'
  | 'scroll-mr-15'
  | 'scroll-mr-16'
  | 'scroll-mr-17'
  | 'scroll-mr-18'
  | 'scroll-mr-19'
  | 'scroll-mr-20'
  | 'scroll-mr-21'
  | 'scroll-mr-22'
  | 'scroll-mr-23'
  | 'scroll-mr-24'
  | 'scroll-mr-25'
  | 'scroll-mr-26'
  | 'scroll-mr-27'
  | 'scroll-mr-28'
  | 'scroll-mr-29'
  | 'scroll-mr-30'
  | 'scroll-mr-32'
  | 'scroll-mr-33'
  | 'scroll-mr-34'
  | 'scroll-mr-35'
  | 'scroll-mr-36'
  | 'scroll-mr-37'
  | 'scroll-mr-38'
  | 'scroll-mr-40'
  | 'scroll-mr-41'
  | 'scroll-mr-42'
  | 'scroll-mr-44'
  | 'scroll-mr-45'
  | 'scroll-mr-46'
  | 'scroll-mr-48'
  | 'scroll-mr-50'
  | 'scroll-mr-51'
  | 'scroll-mr-52'
  | 'scroll-mr-53'
  | 'scroll-mr-56'
  | 'scroll-mr-58'
  | 'scroll-mr-60'
  | 'scroll-mr-61'
  | 'scroll-mr-64'
  | 'scroll-mr-66'
  | 'scroll-mr-70'
  | 'scroll-mr-71'
  | 'scroll-mr-72'
  | 'scroll-mr-77'
  | 'scroll-mr-80'
  | 'scroll-mr-82'
  | 'scroll-mr-85'
  | 'scroll-mr-87'
  | 'scroll-mr-88'
  | 'scroll-mr-90'
  | 'scroll-mr-95'
  | 'scroll-mr-96'
  | 'scroll-mr-100'
  | 'scroll-mr-110'
  | 'scroll-mr-112'
  | 'scroll-mr-116'
  | 'scroll-mr-120'
  | 'scroll-mr-124'
  | 'scroll-mr-126'
  | 'scroll-mr-130'
  | 'scroll-mr-132'
  | 'scroll-mr-144'
  | 'scroll-mr-150'
  | 'scroll-mr-156'
  | 'scroll-mr-160'
  | 'scroll-mr-164'
  | 'scroll-mr-170'
  | 'scroll-mr-172'
  | 'scroll-mr-178'
  | 'scroll-mr-180'
  | 'scroll-mr-182'
  | 'scroll-mr-186'
  | 'scroll-mr-190'
  | 'scroll-mr-196'
  | 'scroll-mr-200'
  | 'scroll-mr-202'
  | 'scroll-mr-216'
  | 'scroll-mr-220'
  | 'scroll-mr-222'
  | 'scroll-mr-235'
  | 'scroll-mr-248'
  | 'scroll-mr-255'
  | 'scroll-mr-258'
  | 'scroll-mr-264'
  | 'scroll-mr-270'
  | 'scroll-mr-275'
  | 'scroll-mr-280'
  | 'scroll-mr-296'
  | 'scroll-mr-300'
  | 'scroll-mr-304'
  | 'scroll-mr-310'
  | 'scroll-mr-320'
  | 'scroll-mr-324'
  | 'scroll-mr-330'
  | 'scroll-mr-336'
  | 'scroll-mr-340'
  | 'scroll-mr-350'
  | 'scroll-mr-360'
  | 'scroll-mr-370'
  | 'scroll-mr-380'
  | 'scroll-mr-390'
  | 'scroll-mr-632'
  | 'scroll-mr-680'
  | 'scroll-mr-720'
  | 'scroll-mr-fit'
  | 'scroll-mr-max'
  | 'scroll-mb-0'
  | 'scroll-mb-1'
  | 'scroll-mb-2'
  | 'scroll-mb-3'
  | 'scroll-mb-4'
  | 'scroll-mb-5'
  | 'scroll-mb-6'
  | 'scroll-mb-7'
  | 'scroll-mb-8'
  | 'scroll-mb-9'
  | 'scroll-mb-10'
  | 'scroll-mb-11'
  | 'scroll-mb-12'
  | 'scroll-mb-13'
  | 'scroll-mb-14'
  | 'scroll-mb-15'
  | 'scroll-mb-16'
  | 'scroll-mb-17'
  | 'scroll-mb-18'
  | 'scroll-mb-19'
  | 'scroll-mb-20'
  | 'scroll-mb-21'
  | 'scroll-mb-22'
  | 'scroll-mb-23'
  | 'scroll-mb-24'
  | 'scroll-mb-25'
  | 'scroll-mb-26'
  | 'scroll-mb-27'
  | 'scroll-mb-28'
  | 'scroll-mb-29'
  | 'scroll-mb-30'
  | 'scroll-mb-32'
  | 'scroll-mb-33'
  | 'scroll-mb-34'
  | 'scroll-mb-35'
  | 'scroll-mb-36'
  | 'scroll-mb-37'
  | 'scroll-mb-38'
  | 'scroll-mb-40'
  | 'scroll-mb-41'
  | 'scroll-mb-42'
  | 'scroll-mb-44'
  | 'scroll-mb-45'
  | 'scroll-mb-46'
  | 'scroll-mb-48'
  | 'scroll-mb-50'
  | 'scroll-mb-51'
  | 'scroll-mb-52'
  | 'scroll-mb-53'
  | 'scroll-mb-56'
  | 'scroll-mb-58'
  | 'scroll-mb-60'
  | 'scroll-mb-61'
  | 'scroll-mb-64'
  | 'scroll-mb-66'
  | 'scroll-mb-70'
  | 'scroll-mb-71'
  | 'scroll-mb-72'
  | 'scroll-mb-77'
  | 'scroll-mb-80'
  | 'scroll-mb-82'
  | 'scroll-mb-85'
  | 'scroll-mb-87'
  | 'scroll-mb-88'
  | 'scroll-mb-90'
  | 'scroll-mb-95'
  | 'scroll-mb-96'
  | 'scroll-mb-100'
  | 'scroll-mb-110'
  | 'scroll-mb-112'
  | 'scroll-mb-116'
  | 'scroll-mb-120'
  | 'scroll-mb-124'
  | 'scroll-mb-126'
  | 'scroll-mb-130'
  | 'scroll-mb-132'
  | 'scroll-mb-144'
  | 'scroll-mb-150'
  | 'scroll-mb-156'
  | 'scroll-mb-160'
  | 'scroll-mb-164'
  | 'scroll-mb-170'
  | 'scroll-mb-172'
  | 'scroll-mb-178'
  | 'scroll-mb-180'
  | 'scroll-mb-182'
  | 'scroll-mb-186'
  | 'scroll-mb-190'
  | 'scroll-mb-196'
  | 'scroll-mb-200'
  | 'scroll-mb-202'
  | 'scroll-mb-216'
  | 'scroll-mb-220'
  | 'scroll-mb-222'
  | 'scroll-mb-235'
  | 'scroll-mb-248'
  | 'scroll-mb-255'
  | 'scroll-mb-258'
  | 'scroll-mb-264'
  | 'scroll-mb-270'
  | 'scroll-mb-275'
  | 'scroll-mb-280'
  | 'scroll-mb-296'
  | 'scroll-mb-300'
  | 'scroll-mb-304'
  | 'scroll-mb-310'
  | 'scroll-mb-320'
  | 'scroll-mb-324'
  | 'scroll-mb-330'
  | 'scroll-mb-336'
  | 'scroll-mb-340'
  | 'scroll-mb-350'
  | 'scroll-mb-360'
  | 'scroll-mb-370'
  | 'scroll-mb-380'
  | 'scroll-mb-390'
  | 'scroll-mb-632'
  | 'scroll-mb-680'
  | 'scroll-mb-720'
  | 'scroll-mb-fit'
  | 'scroll-mb-max'
  | 'scroll-ml-0'
  | 'scroll-ml-1'
  | 'scroll-ml-2'
  | 'scroll-ml-3'
  | 'scroll-ml-4'
  | 'scroll-ml-5'
  | 'scroll-ml-6'
  | 'scroll-ml-7'
  | 'scroll-ml-8'
  | 'scroll-ml-9'
  | 'scroll-ml-10'
  | 'scroll-ml-11'
  | 'scroll-ml-12'
  | 'scroll-ml-13'
  | 'scroll-ml-14'
  | 'scroll-ml-15'
  | 'scroll-ml-16'
  | 'scroll-ml-17'
  | 'scroll-ml-18'
  | 'scroll-ml-19'
  | 'scroll-ml-20'
  | 'scroll-ml-21'
  | 'scroll-ml-22'
  | 'scroll-ml-23'
  | 'scroll-ml-24'
  | 'scroll-ml-25'
  | 'scroll-ml-26'
  | 'scroll-ml-27'
  | 'scroll-ml-28'
  | 'scroll-ml-29'
  | 'scroll-ml-30'
  | 'scroll-ml-32'
  | 'scroll-ml-33'
  | 'scroll-ml-34'
  | 'scroll-ml-35'
  | 'scroll-ml-36'
  | 'scroll-ml-37'
  | 'scroll-ml-38'
  | 'scroll-ml-40'
  | 'scroll-ml-41'
  | 'scroll-ml-42'
  | 'scroll-ml-44'
  | 'scroll-ml-45'
  | 'scroll-ml-46'
  | 'scroll-ml-48'
  | 'scroll-ml-50'
  | 'scroll-ml-51'
  | 'scroll-ml-52'
  | 'scroll-ml-53'
  | 'scroll-ml-56'
  | 'scroll-ml-58'
  | 'scroll-ml-60'
  | 'scroll-ml-61'
  | 'scroll-ml-64'
  | 'scroll-ml-66'
  | 'scroll-ml-70'
  | 'scroll-ml-71'
  | 'scroll-ml-72'
  | 'scroll-ml-77'
  | 'scroll-ml-80'
  | 'scroll-ml-82'
  | 'scroll-ml-85'
  | 'scroll-ml-87'
  | 'scroll-ml-88'
  | 'scroll-ml-90'
  | 'scroll-ml-95'
  | 'scroll-ml-96'
  | 'scroll-ml-100'
  | 'scroll-ml-110'
  | 'scroll-ml-112'
  | 'scroll-ml-116'
  | 'scroll-ml-120'
  | 'scroll-ml-124'
  | 'scroll-ml-126'
  | 'scroll-ml-130'
  | 'scroll-ml-132'
  | 'scroll-ml-144'
  | 'scroll-ml-150'
  | 'scroll-ml-156'
  | 'scroll-ml-160'
  | 'scroll-ml-164'
  | 'scroll-ml-170'
  | 'scroll-ml-172'
  | 'scroll-ml-178'
  | 'scroll-ml-180'
  | 'scroll-ml-182'
  | 'scroll-ml-186'
  | 'scroll-ml-190'
  | 'scroll-ml-196'
  | 'scroll-ml-200'
  | 'scroll-ml-202'
  | 'scroll-ml-216'
  | 'scroll-ml-220'
  | 'scroll-ml-222'
  | 'scroll-ml-235'
  | 'scroll-ml-248'
  | 'scroll-ml-255'
  | 'scroll-ml-258'
  | 'scroll-ml-264'
  | 'scroll-ml-270'
  | 'scroll-ml-275'
  | 'scroll-ml-280'
  | 'scroll-ml-296'
  | 'scroll-ml-300'
  | 'scroll-ml-304'
  | 'scroll-ml-310'
  | 'scroll-ml-320'
  | 'scroll-ml-324'
  | 'scroll-ml-330'
  | 'scroll-ml-336'
  | 'scroll-ml-340'
  | 'scroll-ml-350'
  | 'scroll-ml-360'
  | 'scroll-ml-370'
  | 'scroll-ml-380'
  | 'scroll-ml-390'
  | 'scroll-ml-632'
  | 'scroll-ml-680'
  | 'scroll-ml-720'
  | 'scroll-ml-fit'
  | 'scroll-ml-max'

export type TInteractivity =
  | TAppearance
  | TPointerEvents
  | TResize
  | TUserSelect
  | TScrollSnap
  | TScrollBehavior
  | TTouchAction
  | TCursor
  | TCaretColor
  | TWillChange
  | TAccentColor
  | TScrollPadding
  | TScrollMargin

export type TDisplay =
  | 'block'
  | 'inline-block'
  | 'inline'
  | 'flex'
  | 'inline-flex'
  | 'grid'
  | 'inline-grid'
  | 'table'
  | 'inline-table'
  | 'table-row'
  | 'table-cell'
  | 'contents'
  | 'list-item'
  | 'hidden'

export type TBoxDecorationBreak =
  | 'box-decoration-slice'
  | 'box-decoration-clone'

export type TBoxSizing =
  | 'box-border'
  | 'box-content'

export type TContainer =
  | 'container'

export type TFloat =
  | 'float-right'
  | 'float-left'
  | 'float-none'

export type TClear =
  | 'clear-left'
  | 'clear-right'
  | 'clear-both'
  | 'clear-none'

export type TIsolation =
  | 'isolate'
  | 'isolation-auto'

export type TObjectFit =
  | 'object-contain'
  | 'object-cover'
  | 'object-fill'
  | 'object-none'
  | 'object-scale-down'

export type TOverflow =
  | 'overflow-auto'
  | 'overflow-clip'
  | 'overflow-hidden'
  | 'overflow-visible'
  | 'overflow-scroll'
  | 'overflow-x-auto'
  | 'overflow-y-auto'
  | 'overflow-x-clip'
  | 'overflow-y-clip'
  | 'overflow-x-hidden'
  | 'overflow-y-hidden'
  | 'overflow-x-visible'
  | 'overflow-y-visible'
  | 'overflow-x-scroll'
  | 'overflow-y-scroll'

export type TOverscrollBehavior =
  | 'overscroll-auto'
  | 'overscroll-contain'
  | 'overscroll-none'
  | 'overscroll-y-auto'
  | 'overscroll-y-contain'
  | 'overscroll-y-none'
  | 'overscroll-x-auto'
  | 'overscroll-x-contain'
  | 'overscroll-x-none'

export type TPosition =
  | 'static'
  | 'fixed'
  | 'absolute'
  | 'relative'
  | 'sticky'

export type TVisibility =
  | 'visible'
  | 'invisible'

export type TBreakBefore =
  | 'break-before-auto'
  | 'break-before-avoid'
  | 'break-before-all'
  | 'break-before-avoid-page'
  | 'break-before-page'
  | 'break-before-left'
  | 'break-before-right'
  | 'break-before-column'

export type TBreakInside =
  | 'break-inside-auto'
  | 'break-inside-avoid'
  | 'break-inside-avoid-page'
  | 'break-inside-avoid-column'

export type TBreakAfter =
  | 'break-after-auto'
  | 'break-after-avoid'
  | 'break-after-all'
  | 'break-after-avoid-page'
  | 'break-after-page'
  | 'break-after-left'
  | 'break-after-right'
  | 'break-after-column'

export type TObjectPosition =
  | 'object-bottom'
  | 'object-center'
  | 'object-left'
  | 'object-left-bottom'
  | 'object-left-top'
  | 'object-right'
  | 'object-right-bottom'
  | 'object-right-top'
  | 'object-top'

export type TInset =
  | 'inset-0'
  | 'inset-x-0'
  | 'inset-y-0'
  | 'top-0'
  | 'right-0'
  | 'bottom-0'
  | 'left-0'
  | 'inset-1'
  | 'inset-x-1'
  | 'inset-y-1'
  | 'top-1'
  | 'right-1'
  | 'bottom-1'
  | 'left-1'
  | 'inset-2'
  | 'inset-x-2'
  | 'inset-y-2'
  | 'top-2'
  | 'right-2'
  | 'bottom-2'
  | 'left-2'
  | 'inset-3'
  | 'inset-x-3'
  | 'inset-y-3'
  | 'top-3'
  | 'right-3'
  | 'bottom-3'
  | 'left-3'
  | 'inset-4'
  | 'inset-x-4'
  | 'inset-y-4'
  | 'top-4'
  | 'right-4'
  | 'bottom-4'
  | 'left-4'
  | 'inset-5'
  | 'inset-x-5'
  | 'inset-y-5'
  | 'top-5'
  | 'right-5'
  | 'bottom-5'
  | 'left-5'
  | 'inset-6'
  | 'inset-x-6'
  | 'inset-y-6'
  | 'top-6'
  | 'right-6'
  | 'bottom-6'
  | 'left-6'
  | 'inset-7'
  | 'inset-x-7'
  | 'inset-y-7'
  | 'top-7'
  | 'right-7'
  | 'bottom-7'
  | 'left-7'
  | 'inset-8'
  | 'inset-x-8'
  | 'inset-y-8'
  | 'top-8'
  | 'right-8'
  | 'bottom-8'
  | 'left-8'
  | 'inset-9'
  | 'inset-x-9'
  | 'inset-y-9'
  | 'top-9'
  | 'right-9'
  | 'bottom-9'
  | 'left-9'
  | 'inset-10'
  | 'inset-x-10'
  | 'inset-y-10'
  | 'top-10'
  | 'right-10'
  | 'bottom-10'
  | 'left-10'
  | 'inset-11'
  | 'inset-x-11'
  | 'inset-y-11'
  | 'top-11'
  | 'right-11'
  | 'bottom-11'
  | 'left-11'
  | 'inset-12'
  | 'inset-x-12'
  | 'inset-y-12'
  | 'top-12'
  | 'right-12'
  | 'bottom-12'
  | 'left-12'
  | 'inset-13'
  | 'inset-x-13'
  | 'inset-y-13'
  | 'top-13'
  | 'right-13'
  | 'bottom-13'
  | 'left-13'
  | 'inset-14'
  | 'inset-x-14'
  | 'inset-y-14'
  | 'top-14'
  | 'right-14'
  | 'bottom-14'
  | 'left-14'
  | 'inset-15'
  | 'inset-x-15'
  | 'inset-y-15'
  | 'top-15'
  | 'right-15'
  | 'bottom-15'
  | 'left-15'
  | 'inset-16'
  | 'inset-x-16'
  | 'inset-y-16'
  | 'top-16'
  | 'right-16'
  | 'bottom-16'
  | 'left-16'
  | 'inset-17'
  | 'inset-x-17'
  | 'inset-y-17'
  | 'top-17'
  | 'right-17'
  | 'bottom-17'
  | 'left-17'
  | 'inset-18'
  | 'inset-x-18'
  | 'inset-y-18'
  | 'top-18'
  | 'right-18'
  | 'bottom-18'
  | 'left-18'
  | 'inset-19'
  | 'inset-x-19'
  | 'inset-y-19'
  | 'top-19'
  | 'right-19'
  | 'bottom-19'
  | 'left-19'
  | 'inset-20'
  | 'inset-x-20'
  | 'inset-y-20'
  | 'top-20'
  | 'right-20'
  | 'bottom-20'
  | 'left-20'
  | 'inset-21'
  | 'inset-x-21'
  | 'inset-y-21'
  | 'top-21'
  | 'right-21'
  | 'bottom-21'
  | 'left-21'
  | 'inset-22'
  | 'inset-x-22'
  | 'inset-y-22'
  | 'top-22'
  | 'right-22'
  | 'bottom-22'
  | 'left-22'
  | 'inset-23'
  | 'inset-x-23'
  | 'inset-y-23'
  | 'top-23'
  | 'right-23'
  | 'bottom-23'
  | 'left-23'
  | 'inset-24'
  | 'inset-x-24'
  | 'inset-y-24'
  | 'top-24'
  | 'right-24'
  | 'bottom-24'
  | 'left-24'
  | 'inset-25'
  | 'inset-x-25'
  | 'inset-y-25'
  | 'top-25'
  | 'right-25'
  | 'bottom-25'
  | 'left-25'
  | 'inset-26'
  | 'inset-x-26'
  | 'inset-y-26'
  | 'top-26'
  | 'right-26'
  | 'bottom-26'
  | 'left-26'
  | 'inset-27'
  | 'inset-x-27'
  | 'inset-y-27'
  | 'top-27'
  | 'right-27'
  | 'bottom-27'
  | 'left-27'
  | 'inset-28'
  | 'inset-x-28'
  | 'inset-y-28'
  | 'top-28'
  | 'right-28'
  | 'bottom-28'
  | 'left-28'
  | 'inset-29'
  | 'inset-x-29'
  | 'inset-y-29'
  | 'top-29'
  | 'right-29'
  | 'bottom-29'
  | 'left-29'
  | 'inset-30'
  | 'inset-x-30'
  | 'inset-y-30'
  | 'top-30'
  | 'right-30'
  | 'bottom-30'
  | 'left-30'
  | 'inset-32'
  | 'inset-x-32'
  | 'inset-y-32'
  | 'top-32'
  | 'right-32'
  | 'bottom-32'
  | 'left-32'
  | 'inset-33'
  | 'inset-x-33'
  | 'inset-y-33'
  | 'top-33'
  | 'right-33'
  | 'bottom-33'
  | 'left-33'
  | 'inset-34'
  | 'inset-x-34'
  | 'inset-y-34'
  | 'top-34'
  | 'right-34'
  | 'bottom-34'
  | 'left-34'
  | 'inset-35'
  | 'inset-x-35'
  | 'inset-y-35'
  | 'top-35'
  | 'right-35'
  | 'bottom-35'
  | 'left-35'
  | 'inset-36'
  | 'inset-x-36'
  | 'inset-y-36'
  | 'top-36'
  | 'right-36'
  | 'bottom-36'
  | 'left-36'
  | 'inset-37'
  | 'inset-x-37'
  | 'inset-y-37'
  | 'top-37'
  | 'right-37'
  | 'bottom-37'
  | 'left-37'
  | 'inset-38'
  | 'inset-x-38'
  | 'inset-y-38'
  | 'top-38'
  | 'right-38'
  | 'bottom-38'
  | 'left-38'
  | 'inset-40'
  | 'inset-x-40'
  | 'inset-y-40'
  | 'top-40'
  | 'right-40'
  | 'bottom-40'
  | 'left-40'
  | 'inset-41'
  | 'inset-x-41'
  | 'inset-y-41'
  | 'top-41'
  | 'right-41'
  | 'bottom-41'
  | 'left-41'
  | 'inset-42'
  | 'inset-x-42'
  | 'inset-y-42'
  | 'top-42'
  | 'right-42'
  | 'bottom-42'
  | 'left-42'
  | 'inset-44'
  | 'inset-x-44'
  | 'inset-y-44'
  | 'top-44'
  | 'right-44'
  | 'bottom-44'
  | 'left-44'
  | 'inset-45'
  | 'inset-x-45'
  | 'inset-y-45'
  | 'top-45'
  | 'right-45'
  | 'bottom-45'
  | 'left-45'
  | 'inset-46'
  | 'inset-x-46'
  | 'inset-y-46'
  | 'top-46'
  | 'right-46'
  | 'bottom-46'
  | 'left-46'
  | 'inset-48'
  | 'inset-x-48'
  | 'inset-y-48'
  | 'top-48'
  | 'right-48'
  | 'bottom-48'
  | 'left-48'
  | 'inset-50'
  | 'inset-x-50'
  | 'inset-y-50'
  | 'top-50'
  | 'right-50'
  | 'bottom-50'
  | 'left-50'
  | 'inset-51'
  | 'inset-x-51'
  | 'inset-y-51'
  | 'top-51'
  | 'right-51'
  | 'bottom-51'
  | 'left-51'
  | 'inset-52'
  | 'inset-x-52'
  | 'inset-y-52'
  | 'top-52'
  | 'right-52'
  | 'bottom-52'
  | 'left-52'
  | 'inset-53'
  | 'inset-x-53'
  | 'inset-y-53'
  | 'top-53'
  | 'right-53'
  | 'bottom-53'
  | 'left-53'
  | 'inset-56'
  | 'inset-x-56'
  | 'inset-y-56'
  | 'top-56'
  | 'right-56'
  | 'bottom-56'
  | 'left-56'
  | 'inset-58'
  | 'inset-x-58'
  | 'inset-y-58'
  | 'top-58'
  | 'right-58'
  | 'bottom-58'
  | 'left-58'
  | 'inset-60'
  | 'inset-x-60'
  | 'inset-y-60'
  | 'top-60'
  | 'right-60'
  | 'bottom-60'
  | 'left-60'
  | 'inset-61'
  | 'inset-x-61'
  | 'inset-y-61'
  | 'top-61'
  | 'right-61'
  | 'bottom-61'
  | 'left-61'
  | 'inset-64'
  | 'inset-x-64'
  | 'inset-y-64'
  | 'top-64'
  | 'right-64'
  | 'bottom-64'
  | 'left-64'
  | 'inset-66'
  | 'inset-x-66'
  | 'inset-y-66'
  | 'top-66'
  | 'right-66'
  | 'bottom-66'
  | 'left-66'
  | 'inset-70'
  | 'inset-x-70'
  | 'inset-y-70'
  | 'top-70'
  | 'right-70'
  | 'bottom-70'
  | 'left-70'
  | 'inset-71'
  | 'inset-x-71'
  | 'inset-y-71'
  | 'top-71'
  | 'right-71'
  | 'bottom-71'
  | 'left-71'
  | 'inset-72'
  | 'inset-x-72'
  | 'inset-y-72'
  | 'top-72'
  | 'right-72'
  | 'bottom-72'
  | 'left-72'
  | 'inset-77'
  | 'inset-x-77'
  | 'inset-y-77'
  | 'top-77'
  | 'right-77'
  | 'bottom-77'
  | 'left-77'
  | 'inset-80'
  | 'inset-x-80'
  | 'inset-y-80'
  | 'top-80'
  | 'right-80'
  | 'bottom-80'
  | 'left-80'
  | 'inset-82'
  | 'inset-x-82'
  | 'inset-y-82'
  | 'top-82'
  | 'right-82'
  | 'bottom-82'
  | 'left-82'
  | 'inset-85'
  | 'inset-x-85'
  | 'inset-y-85'
  | 'top-85'
  | 'right-85'
  | 'bottom-85'
  | 'left-85'
  | 'inset-87'
  | 'inset-x-87'
  | 'inset-y-87'
  | 'top-87'
  | 'right-87'
  | 'bottom-87'
  | 'left-87'
  | 'inset-88'
  | 'inset-x-88'
  | 'inset-y-88'
  | 'top-88'
  | 'right-88'
  | 'bottom-88'
  | 'left-88'
  | 'inset-90'
  | 'inset-x-90'
  | 'inset-y-90'
  | 'top-90'
  | 'right-90'
  | 'bottom-90'
  | 'left-90'
  | 'inset-95'
  | 'inset-x-95'
  | 'inset-y-95'
  | 'top-95'
  | 'right-95'
  | 'bottom-95'
  | 'left-95'
  | 'inset-96'
  | 'inset-x-96'
  | 'inset-y-96'
  | 'top-96'
  | 'right-96'
  | 'bottom-96'
  | 'left-96'
  | 'inset-100'
  | 'inset-x-100'
  | 'inset-y-100'
  | 'top-100'
  | 'right-100'
  | 'bottom-100'
  | 'left-100'
  | 'inset-110'
  | 'inset-x-110'
  | 'inset-y-110'
  | 'top-110'
  | 'right-110'
  | 'bottom-110'
  | 'left-110'
  | 'inset-112'
  | 'inset-x-112'
  | 'inset-y-112'
  | 'top-112'
  | 'right-112'
  | 'bottom-112'
  | 'left-112'
  | 'inset-116'
  | 'inset-x-116'
  | 'inset-y-116'
  | 'top-116'
  | 'right-116'
  | 'bottom-116'
  | 'left-116'
  | 'inset-120'
  | 'inset-x-120'
  | 'inset-y-120'
  | 'top-120'
  | 'right-120'
  | 'bottom-120'
  | 'left-120'
  | 'inset-124'
  | 'inset-x-124'
  | 'inset-y-124'
  | 'top-124'
  | 'right-124'
  | 'bottom-124'
  | 'left-124'
  | 'inset-126'
  | 'inset-x-126'
  | 'inset-y-126'
  | 'top-126'
  | 'right-126'
  | 'bottom-126'
  | 'left-126'
  | 'inset-130'
  | 'inset-x-130'
  | 'inset-y-130'
  | 'top-130'
  | 'right-130'
  | 'bottom-130'
  | 'left-130'
  | 'inset-132'
  | 'inset-x-132'
  | 'inset-y-132'
  | 'top-132'
  | 'right-132'
  | 'bottom-132'
  | 'left-132'
  | 'inset-144'
  | 'inset-x-144'
  | 'inset-y-144'
  | 'top-144'
  | 'right-144'
  | 'bottom-144'
  | 'left-144'
  | 'inset-150'
  | 'inset-x-150'
  | 'inset-y-150'
  | 'top-150'
  | 'right-150'
  | 'bottom-150'
  | 'left-150'
  | 'inset-156'
  | 'inset-x-156'
  | 'inset-y-156'
  | 'top-156'
  | 'right-156'
  | 'bottom-156'
  | 'left-156'
  | 'inset-160'
  | 'inset-x-160'
  | 'inset-y-160'
  | 'top-160'
  | 'right-160'
  | 'bottom-160'
  | 'left-160'
  | 'inset-164'
  | 'inset-x-164'
  | 'inset-y-164'
  | 'top-164'
  | 'right-164'
  | 'bottom-164'
  | 'left-164'
  | 'inset-170'
  | 'inset-x-170'
  | 'inset-y-170'
  | 'top-170'
  | 'right-170'
  | 'bottom-170'
  | 'left-170'
  | 'inset-172'
  | 'inset-x-172'
  | 'inset-y-172'
  | 'top-172'
  | 'right-172'
  | 'bottom-172'
  | 'left-172'
  | 'inset-178'
  | 'inset-x-178'
  | 'inset-y-178'
  | 'top-178'
  | 'right-178'
  | 'bottom-178'
  | 'left-178'
  | 'inset-180'
  | 'inset-x-180'
  | 'inset-y-180'
  | 'top-180'
  | 'right-180'
  | 'bottom-180'
  | 'left-180'
  | 'inset-182'
  | 'inset-x-182'
  | 'inset-y-182'
  | 'top-182'
  | 'right-182'
  | 'bottom-182'
  | 'left-182'
  | 'inset-186'
  | 'inset-x-186'
  | 'inset-y-186'
  | 'top-186'
  | 'right-186'
  | 'bottom-186'
  | 'left-186'
  | 'inset-190'
  | 'inset-x-190'
  | 'inset-y-190'
  | 'top-190'
  | 'right-190'
  | 'bottom-190'
  | 'left-190'
  | 'inset-196'
  | 'inset-x-196'
  | 'inset-y-196'
  | 'top-196'
  | 'right-196'
  | 'bottom-196'
  | 'left-196'
  | 'inset-200'
  | 'inset-x-200'
  | 'inset-y-200'
  | 'top-200'
  | 'right-200'
  | 'bottom-200'
  | 'left-200'
  | 'inset-202'
  | 'inset-x-202'
  | 'inset-y-202'
  | 'top-202'
  | 'right-202'
  | 'bottom-202'
  | 'left-202'
  | 'inset-216'
  | 'inset-x-216'
  | 'inset-y-216'
  | 'top-216'
  | 'right-216'
  | 'bottom-216'
  | 'left-216'
  | 'inset-220'
  | 'inset-x-220'
  | 'inset-y-220'
  | 'top-220'
  | 'right-220'
  | 'bottom-220'
  | 'left-220'
  | 'inset-222'
  | 'inset-x-222'
  | 'inset-y-222'
  | 'top-222'
  | 'right-222'
  | 'bottom-222'
  | 'left-222'
  | 'inset-235'
  | 'inset-x-235'
  | 'inset-y-235'
  | 'top-235'
  | 'right-235'
  | 'bottom-235'
  | 'left-235'
  | 'inset-248'
  | 'inset-x-248'
  | 'inset-y-248'
  | 'top-248'
  | 'right-248'
  | 'bottom-248'
  | 'left-248'
  | 'inset-255'
  | 'inset-x-255'
  | 'inset-y-255'
  | 'top-255'
  | 'right-255'
  | 'bottom-255'
  | 'left-255'
  | 'inset-258'
  | 'inset-x-258'
  | 'inset-y-258'
  | 'top-258'
  | 'right-258'
  | 'bottom-258'
  | 'left-258'
  | 'inset-264'
  | 'inset-x-264'
  | 'inset-y-264'
  | 'top-264'
  | 'right-264'
  | 'bottom-264'
  | 'left-264'
  | 'inset-270'
  | 'inset-x-270'
  | 'inset-y-270'
  | 'top-270'
  | 'right-270'
  | 'bottom-270'
  | 'left-270'
  | 'inset-275'
  | 'inset-x-275'
  | 'inset-y-275'
  | 'top-275'
  | 'right-275'
  | 'bottom-275'
  | 'left-275'
  | 'inset-280'
  | 'inset-x-280'
  | 'inset-y-280'
  | 'top-280'
  | 'right-280'
  | 'bottom-280'
  | 'left-280'
  | 'inset-296'
  | 'inset-x-296'
  | 'inset-y-296'
  | 'top-296'
  | 'right-296'
  | 'bottom-296'
  | 'left-296'
  | 'inset-300'
  | 'inset-x-300'
  | 'inset-y-300'
  | 'top-300'
  | 'right-300'
  | 'bottom-300'
  | 'left-300'
  | 'inset-304'
  | 'inset-x-304'
  | 'inset-y-304'
  | 'top-304'
  | 'right-304'
  | 'bottom-304'
  | 'left-304'
  | 'inset-310'
  | 'inset-x-310'
  | 'inset-y-310'
  | 'top-310'
  | 'right-310'
  | 'bottom-310'
  | 'left-310'
  | 'inset-320'
  | 'inset-x-320'
  | 'inset-y-320'
  | 'top-320'
  | 'right-320'
  | 'bottom-320'
  | 'left-320'
  | 'inset-324'
  | 'inset-x-324'
  | 'inset-y-324'
  | 'top-324'
  | 'right-324'
  | 'bottom-324'
  | 'left-324'
  | 'inset-330'
  | 'inset-x-330'
  | 'inset-y-330'
  | 'top-330'
  | 'right-330'
  | 'bottom-330'
  | 'left-330'
  | 'inset-336'
  | 'inset-x-336'
  | 'inset-y-336'
  | 'top-336'
  | 'right-336'
  | 'bottom-336'
  | 'left-336'
  | 'inset-340'
  | 'inset-x-340'
  | 'inset-y-340'
  | 'top-340'
  | 'right-340'
  | 'bottom-340'
  | 'left-340'
  | 'inset-350'
  | 'inset-x-350'
  | 'inset-y-350'
  | 'top-350'
  | 'right-350'
  | 'bottom-350'
  | 'left-350'
  | 'inset-360'
  | 'inset-x-360'
  | 'inset-y-360'
  | 'top-360'
  | 'right-360'
  | 'bottom-360'
  | 'left-360'
  | 'inset-370'
  | 'inset-x-370'
  | 'inset-y-370'
  | 'top-370'
  | 'right-370'
  | 'bottom-370'
  | 'left-370'
  | 'inset-380'
  | 'inset-x-380'
  | 'inset-y-380'
  | 'top-380'
  | 'right-380'
  | 'bottom-380'
  | 'left-380'
  | 'inset-390'
  | 'inset-x-390'
  | 'inset-y-390'
  | 'top-390'
  | 'right-390'
  | 'bottom-390'
  | 'left-390'
  | 'inset-632'
  | 'inset-x-632'
  | 'inset-y-632'
  | 'top-632'
  | 'right-632'
  | 'bottom-632'
  | 'left-632'
  | 'inset-680'
  | 'inset-x-680'
  | 'inset-y-680'
  | 'top-680'
  | 'right-680'
  | 'bottom-680'
  | 'left-680'
  | 'inset-720'
  | 'inset-x-720'
  | 'inset-y-720'
  | 'top-720'
  | 'right-720'
  | 'bottom-720'
  | 'left-720'
  | 'inset-auto'
  | 'inset-x-auto'
  | 'inset-y-auto'
  | 'top-auto'
  | 'right-auto'
  | 'bottom-auto'
  | 'left-auto'
  | 'inset-fit'
  | 'inset-x-fit'
  | 'inset-y-fit'
  | 'top-fit'
  | 'right-fit'
  | 'bottom-fit'
  | 'left-fit'
  | 'inset-max'
  | 'inset-x-max'
  | 'inset-y-max'
  | 'top-max'
  | 'right-max'
  | 'bottom-max'
  | 'left-max'
  | 'inset-1/2'
  | 'inset-x-1/2'
  | 'inset-y-1/2'
  | 'top-1/2'
  | 'right-1/2'
  | 'bottom-1/2'
  | 'left-1/2'
  | 'inset-1/3'
  | 'inset-x-1/3'
  | 'inset-y-1/3'
  | 'top-1/3'
  | 'right-1/3'
  | 'bottom-1/3'
  | 'left-1/3'
  | 'inset-2/3'
  | 'inset-x-2/3'
  | 'inset-y-2/3'
  | 'top-2/3'
  | 'right-2/3'
  | 'bottom-2/3'
  | 'left-2/3'
  | 'inset-1/4'
  | 'inset-x-1/4'
  | 'inset-y-1/4'
  | 'top-1/4'
  | 'right-1/4'
  | 'bottom-1/4'
  | 'left-1/4'
  | 'inset-2/4'
  | 'inset-x-2/4'
  | 'inset-y-2/4'
  | 'top-2/4'
  | 'right-2/4'
  | 'bottom-2/4'
  | 'left-2/4'
  | 'inset-3/4'
  | 'inset-x-3/4'
  | 'inset-y-3/4'
  | 'top-3/4'
  | 'right-3/4'
  | 'bottom-3/4'
  | 'left-3/4'
  | 'inset-full'
  | 'inset-x-full'
  | 'inset-y-full'
  | 'top-full'
  | 'right-full'
  | 'bottom-full'
  | 'left-full'

export type TZIndex =
  | 'z-0'
  | 'z-10'
  | 'z-20'
  | 'z-30'
  | 'z-40'
  | 'z-50'

export type TAspectRatio =
  | 'aspect-auto'
  | 'aspect-square'
  | 'aspect-video'

export type TColumns =
  | 'columns-1'
  | 'columns-2'
  | 'columns-3'
  | 'columns-4'
  | 'columns-5'
  | 'columns-6'
  | 'columns-7'
  | 'columns-8'
  | 'columns-9'
  | 'columns-10'
  | 'columns-11'
  | 'columns-12'
  | 'columns-auto'
  | 'columns-3xs'
  | 'columns-2xs'
  | 'columns-xs'
  | 'columns-sm'
  | 'columns-md'
  | 'columns-lg'
  | 'columns-xl'
  | 'columns-2xl'
  | 'columns-3xl'
  | 'columns-4xl'
  | 'columns-5xl'
  | 'columns-6xl'
  | 'columns-7xl'

export type TLayout =
  | TDisplay
  | TBoxDecorationBreak
  | TBoxSizing
  | TContainer
  | TFloat
  | TClear
  | TIsolation
  | TObjectFit
  | TOverflow
  | TOverscrollBehavior
  | TPosition
  | TVisibility
  | TBreakBefore
  | TBreakInside
  | TBreakAfter
  | TObjectPosition
  | TInset
  | TZIndex
  | TAspectRatio
  | TColumns

export type TWidth =
  | 'w-0'
  | 'w-1'
  | 'w-2'
  | 'w-3'
  | 'w-4'
  | 'w-5'
  | 'w-6'
  | 'w-7'
  | 'w-8'
  | 'w-9'
  | 'w-10'
  | 'w-11'
  | 'w-12'
  | 'w-13'
  | 'w-14'
  | 'w-15'
  | 'w-16'
  | 'w-17'
  | 'w-18'
  | 'w-19'
  | 'w-20'
  | 'w-21'
  | 'w-22'
  | 'w-23'
  | 'w-24'
  | 'w-25'
  | 'w-26'
  | 'w-27'
  | 'w-28'
  | 'w-29'
  | 'w-30'
  | 'w-32'
  | 'w-33'
  | 'w-34'
  | 'w-35'
  | 'w-36'
  | 'w-37'
  | 'w-38'
  | 'w-40'
  | 'w-41'
  | 'w-42'
  | 'w-44'
  | 'w-45'
  | 'w-46'
  | 'w-48'
  | 'w-50'
  | 'w-51'
  | 'w-52'
  | 'w-53'
  | 'w-56'
  | 'w-58'
  | 'w-60'
  | 'w-61'
  | 'w-64'
  | 'w-66'
  | 'w-70'
  | 'w-71'
  | 'w-72'
  | 'w-77'
  | 'w-80'
  | 'w-82'
  | 'w-85'
  | 'w-87'
  | 'w-88'
  | 'w-90'
  | 'w-95'
  | 'w-96'
  | 'w-100'
  | 'w-110'
  | 'w-112'
  | 'w-116'
  | 'w-120'
  | 'w-124'
  | 'w-126'
  | 'w-130'
  | 'w-132'
  | 'w-144'
  | 'w-150'
  | 'w-156'
  | 'w-160'
  | 'w-164'
  | 'w-170'
  | 'w-172'
  | 'w-178'
  | 'w-180'
  | 'w-182'
  | 'w-186'
  | 'w-190'
  | 'w-196'
  | 'w-200'
  | 'w-202'
  | 'w-216'
  | 'w-220'
  | 'w-222'
  | 'w-235'
  | 'w-248'
  | 'w-255'
  | 'w-258'
  | 'w-264'
  | 'w-270'
  | 'w-275'
  | 'w-280'
  | 'w-296'
  | 'w-300'
  | 'w-304'
  | 'w-310'
  | 'w-320'
  | 'w-324'
  | 'w-330'
  | 'w-336'
  | 'w-340'
  | 'w-350'
  | 'w-360'
  | 'w-370'
  | 'w-380'
  | 'w-390'
  | 'w-632'
  | 'w-680'
  | 'w-720'
  | 'w-auto'
  | 'w-fit'
  | 'w-max'
  | 'w-1/2'
  | 'w-1/3'
  | 'w-2/3'
  | 'w-1/4'
  | 'w-2/4'
  | 'w-3/4'
  | 'w-1/5'
  | 'w-2/5'
  | 'w-3/5'
  | 'w-4/5'
  | 'w-1/6'
  | 'w-2/6'
  | 'w-3/6'
  | 'w-4/6'
  | 'w-5/6'
  | 'w-1/12'
  | 'w-2/12'
  | 'w-3/12'
  | 'w-4/12'
  | 'w-5/12'
  | 'w-6/12'
  | 'w-7/12'
  | 'w-8/12'
  | 'w-9/12'
  | 'w-10/12'
  | 'w-11/12'
  | 'w-full'
  | 'w-screen'
  | 'w-min'

export type TMinWidth =
  | 'min-w-0'
  | 'min-w-96'
  | 'min-w-120'
  | 'min-w-144'
  | 'min-w-160'
  | 'min-w-680'
  | 'min-w-full'
  | 'min-w-min'
  | 'min-w-max'
  | 'min-w-fit'

export type TMaxWidth =
  | 'max-w-0'
  | 'max-w-52'
  | 'max-w-108'
  | 'max-w-136'
  | 'max-w-147'
  | 'max-w-160'
  | 'max-w-175'
  | 'max-w-176'
  | 'max-w-190'
  | 'max-w-210'
  | 'max-w-220'
  | 'max-w-264'
  | 'max-w-272'
  | 'max-w-278'
  | 'max-w-300'
  | 'max-w-330'
  | 'max-w-386'
  | 'max-w-413'
  | 'max-w-664'
  | 'max-w-680'
  | 'max-w-696'
  | 'max-w-960'
  | 'max-w-1036'
  | 'max-w-1120'
  | 'max-w-1216'
  | 'max-w-none'
  | 'max-w-xs'
  | 'max-w-sm'
  | 'max-w-md'
  | 'max-w-lg'
  | 'max-w-xl'
  | 'max-w-2xl'
  | 'max-w-3xl'
  | 'max-w-4xl'
  | 'max-w-5xl'
  | 'max-w-6xl'
  | 'max-w-7xl'
  | 'max-w-full'
  | 'max-w-min'
  | 'max-w-max'
  | 'max-w-fit'
  | 'max-w-prose'
  | 'max-w-screen-md'
  | 'max-w-screen-lg'
  | 'max-w-screen-xl'

export type THeight =
  | 'h-0'
  | 'h-1'
  | 'h-2'
  | 'h-3'
  | 'h-4'
  | 'h-5'
  | 'h-6'
  | 'h-7'
  | 'h-8'
  | 'h-9'
  | 'h-10'
  | 'h-11'
  | 'h-12'
  | 'h-13'
  | 'h-14'
  | 'h-15'
  | 'h-16'
  | 'h-17'
  | 'h-18'
  | 'h-19'
  | 'h-20'
  | 'h-21'
  | 'h-22'
  | 'h-23'
  | 'h-24'
  | 'h-25'
  | 'h-26'
  | 'h-27'
  | 'h-28'
  | 'h-29'
  | 'h-30'
  | 'h-32'
  | 'h-33'
  | 'h-34'
  | 'h-35'
  | 'h-36'
  | 'h-37'
  | 'h-38'
  | 'h-40'
  | 'h-41'
  | 'h-42'
  | 'h-44'
  | 'h-45'
  | 'h-46'
  | 'h-48'
  | 'h-50'
  | 'h-51'
  | 'h-52'
  | 'h-53'
  | 'h-56'
  | 'h-58'
  | 'h-60'
  | 'h-61'
  | 'h-64'
  | 'h-66'
  | 'h-70'
  | 'h-71'
  | 'h-72'
  | 'h-77'
  | 'h-80'
  | 'h-82'
  | 'h-85'
  | 'h-87'
  | 'h-88'
  | 'h-90'
  | 'h-95'
  | 'h-96'
  | 'h-100'
  | 'h-110'
  | 'h-112'
  | 'h-116'
  | 'h-120'
  | 'h-124'
  | 'h-126'
  | 'h-130'
  | 'h-132'
  | 'h-144'
  | 'h-150'
  | 'h-156'
  | 'h-160'
  | 'h-164'
  | 'h-170'
  | 'h-172'
  | 'h-178'
  | 'h-180'
  | 'h-182'
  | 'h-186'
  | 'h-190'
  | 'h-196'
  | 'h-200'
  | 'h-202'
  | 'h-216'
  | 'h-220'
  | 'h-222'
  | 'h-235'
  | 'h-248'
  | 'h-255'
  | 'h-258'
  | 'h-264'
  | 'h-270'
  | 'h-275'
  | 'h-280'
  | 'h-296'
  | 'h-300'
  | 'h-304'
  | 'h-310'
  | 'h-320'
  | 'h-324'
  | 'h-330'
  | 'h-336'
  | 'h-340'
  | 'h-350'
  | 'h-360'
  | 'h-370'
  | 'h-380'
  | 'h-390'
  | 'h-632'
  | 'h-680'
  | 'h-720'
  | 'h-auto'
  | 'h-fit'
  | 'h-max'
  | 'h-1/2'
  | 'h-1/3'
  | 'h-2/3'
  | 'h-1/4'
  | 'h-2/4'
  | 'h-3/4'
  | 'h-1/5'
  | 'h-2/5'
  | 'h-3/5'
  | 'h-4/5'
  | 'h-1/6'
  | 'h-2/6'
  | 'h-3/6'
  | 'h-4/6'
  | 'h-5/6'
  | 'h-full'
  | 'h-screen'
  | 'h-min'

export type TMinHeight =
  | 'min-h-0'
  | 'min-h-50'
  | 'min-h-71'
  | 'min-h-100'
  | 'min-h-120'
  | 'min-h-full'
  | 'min-h-screen'
  | 'min-h-min'
  | 'min-h-max'
  | 'min-h-fit'

export type TMaxHeight =
  | 'max-h-0'
  | 'max-h-1'
  | 'max-h-2'
  | 'max-h-3'
  | 'max-h-4'
  | 'max-h-5'
  | 'max-h-6'
  | 'max-h-7'
  | 'max-h-8'
  | 'max-h-9'
  | 'max-h-10'
  | 'max-h-11'
  | 'max-h-12'
  | 'max-h-13'
  | 'max-h-14'
  | 'max-h-15'
  | 'max-h-16'
  | 'max-h-17'
  | 'max-h-18'
  | 'max-h-19'
  | 'max-h-20'
  | 'max-h-21'
  | 'max-h-22'
  | 'max-h-23'
  | 'max-h-24'
  | 'max-h-25'
  | 'max-h-26'
  | 'max-h-27'
  | 'max-h-28'
  | 'max-h-29'
  | 'max-h-30'
  | 'max-h-31'
  | 'max-h-32'
  | 'max-h-33'
  | 'max-h-34'
  | 'max-h-35'
  | 'max-h-36'
  | 'max-h-37'
  | 'max-h-38'
  | 'max-h-39'
  | 'max-h-40'
  | 'max-h-41'
  | 'max-h-42'
  | 'max-h-44'
  | 'max-h-45'
  | 'max-h-46'
  | 'max-h-47'
  | 'max-h-48'
  | 'max-h-50'
  | 'max-h-51'
  | 'max-h-52'
  | 'max-h-53'
  | 'max-h-56'
  | 'max-h-58'
  | 'max-h-60'
  | 'max-h-61'
  | 'max-h-63'
  | 'max-h-64'
  | 'max-h-65'
  | 'max-h-66'
  | 'max-h-70'
  | 'max-h-71'
  | 'max-h-72'
  | 'max-h-74'
  | 'max-h-75'
  | 'max-h-77'
  | 'max-h-80'
  | 'max-h-82'
  | 'max-h-85'
  | 'max-h-87'
  | 'max-h-88'
  | 'max-h-90'
  | 'max-h-95'
  | 'max-h-96'
  | 'max-h-100'
  | 'max-h-110'
  | 'max-h-112'
  | 'max-h-116'
  | 'max-h-120'
  | 'max-h-124'
  | 'max-h-126'
  | 'max-h-130'
  | 'max-h-132'
  | 'max-h-144'
  | 'max-h-150'
  | 'max-h-156'
  | 'max-h-160'
  | 'max-h-164'
  | 'max-h-170'
  | 'max-h-172'
  | 'max-h-178'
  | 'max-h-180'
  | 'max-h-182'
  | 'max-h-186'
  | 'max-h-190'
  | 'max-h-196'
  | 'max-h-200'
  | 'max-h-202'
  | 'max-h-216'
  | 'max-h-220'
  | 'max-h-222'
  | 'max-h-235'
  | 'max-h-248'
  | 'max-h-255'
  | 'max-h-258'
  | 'max-h-264'
  | 'max-h-270'
  | 'max-h-275'
  | 'max-h-280'
  | 'max-h-296'
  | 'max-h-300'
  | 'max-h-304'
  | 'max-h-310'
  | 'max-h-320'
  | 'max-h-324'
  | 'max-h-330'
  | 'max-h-336'
  | 'max-h-340'
  | 'max-h-350'
  | 'max-h-360'
  | 'max-h-370'
  | 'max-h-380'
  | 'max-h-390'
  | 'max-h-632'
  | 'max-h-680'
  | 'max-h-720'
  | 'max-h-fit'
  | 'max-h-max'
  | 'max-h-full'
  | 'max-h-screen'
  | 'max-h-min'

export type TSizing =
  | TWidth
  | TMinWidth
  | TMaxWidth
  | THeight
  | TMinHeight
  | TMaxHeight

export type TFill =
  | 'fill-inherit'
  | 'fill-transparent'
  | 'fill-current'
  | 'fill-black'
  | 'fill-white'
  | 'fill-brand-primary-1'
  | 'fill-brand-primary-2'
  | 'fill-brand-primary-3'
  | 'fill-brand-primary-4'
  | 'fill-brand-primary-5'
  | 'fill-brand-secondary-1'
  | 'fill-brand-secondary-2'
  | 'fill-brand-secondary-3'
  | 'fill-brand-secondary-4'
  | 'fill-brand-secondary-5'
  | 'fill-brand-tertiary-1'
  | 'fill-brand-tertiary-2'
  | 'fill-brand-tertiary-3'
  | 'fill-brand-tertiary-4'
  | 'fill-brand-tertiary-5'
  | 'fill-gray-1'
  | 'fill-gray-2'
  | 'fill-gray-3'
  | 'fill-gray-4'
  | 'fill-gray-5'
  | 'fill-gray-6'
  | 'fill-gray-7'
  | 'fill-gray-8'
  | 'fill-gray-9'
  | 'fill-social-linkedin'
  | 'fill-social-facebook'
  | 'fill-social-twitter'
  | 'fill-social-instagram'
  | 'fill-informa-bar'
  | 'fill-danger'
  | 'fill-error'

export type TStroke =
  | 'stroke-inherit'
  | 'stroke-transparent'
  | 'stroke-current'
  | 'stroke-black'
  | 'stroke-white'
  | 'stroke-brand-primary-1'
  | 'stroke-brand-primary-2'
  | 'stroke-brand-primary-3'
  | 'stroke-brand-primary-4'
  | 'stroke-brand-primary-5'
  | 'stroke-brand-secondary-1'
  | 'stroke-brand-secondary-2'
  | 'stroke-brand-secondary-3'
  | 'stroke-brand-secondary-4'
  | 'stroke-brand-secondary-5'
  | 'stroke-brand-tertiary-1'
  | 'stroke-brand-tertiary-2'
  | 'stroke-brand-tertiary-3'
  | 'stroke-brand-tertiary-4'
  | 'stroke-brand-tertiary-5'
  | 'stroke-gray-1'
  | 'stroke-gray-2'
  | 'stroke-gray-3'
  | 'stroke-gray-4'
  | 'stroke-gray-5'
  | 'stroke-gray-6'
  | 'stroke-gray-7'
  | 'stroke-gray-8'
  | 'stroke-gray-9'
  | 'stroke-social-linkedin'
  | 'stroke-social-facebook'
  | 'stroke-social-twitter'
  | 'stroke-social-instagram'
  | 'stroke-informa-bar'
  | 'stroke-danger'
  | 'stroke-error'

export type TStrokeWidth =
  | 'stroke-0'
  | 'stroke-1'
  | 'stroke-2'

export type TSVG =
  | TFill
  | TStroke
  | TStrokeWidth

export type THardwareAcceleration =
  | 'transform-gpu'

export type TScale =
  | 'scale-0'
  | 'scale-50'
  | 'scale-75'
  | 'scale-90'
  | 'scale-95'
  | 'scale-100'
  | 'scale-105'
  | 'scale-110'
  | 'scale-125'
  | 'scale-150'
  | 'scale-x-0'
  | 'scale-x-50'
  | 'scale-x-75'
  | 'scale-x-90'
  | 'scale-x-95'
  | 'scale-x-100'
  | 'scale-x-105'
  | 'scale-x-110'
  | 'scale-x-125'
  | 'scale-x-150'
  | 'scale-y-0'
  | 'scale-y-50'
  | 'scale-y-75'
  | 'scale-y-90'
  | 'scale-y-95'
  | 'scale-y-100'
  | 'scale-y-105'
  | 'scale-y-110'
  | 'scale-y-125'
  | 'scale-y-150'

export type TRotate =
  | 'rotate-0'
  | 'rotate-1'
  | 'rotate-2'
  | 'rotate-3'
  | 'rotate-6'
  | 'rotate-12'
  | 'rotate-45'
  | 'rotate-90'
  | 'rotate-180'

export type TTranslate =
  | 'translate-x-0'
  | 'translate-x-1'
  | 'translate-x-2'
  | 'translate-x-3'
  | 'translate-x-4'
  | 'translate-x-5'
  | 'translate-x-6'
  | 'translate-x-7'
  | 'translate-x-8'
  | 'translate-x-9'
  | 'translate-x-10'
  | 'translate-x-11'
  | 'translate-x-12'
  | 'translate-x-13'
  | 'translate-x-14'
  | 'translate-x-15'
  | 'translate-x-16'
  | 'translate-x-17'
  | 'translate-x-18'
  | 'translate-x-19'
  | 'translate-x-20'
  | 'translate-x-21'
  | 'translate-x-22'
  | 'translate-x-23'
  | 'translate-x-24'
  | 'translate-x-25'
  | 'translate-x-26'
  | 'translate-x-27'
  | 'translate-x-28'
  | 'translate-x-29'
  | 'translate-x-30'
  | 'translate-x-32'
  | 'translate-x-33'
  | 'translate-x-34'
  | 'translate-x-35'
  | 'translate-x-36'
  | 'translate-x-37'
  | 'translate-x-38'
  | 'translate-x-40'
  | 'translate-x-41'
  | 'translate-x-42'
  | 'translate-x-44'
  | 'translate-x-45'
  | 'translate-x-46'
  | 'translate-x-48'
  | 'translate-x-50'
  | 'translate-x-51'
  | 'translate-x-52'
  | 'translate-x-53'
  | 'translate-x-56'
  | 'translate-x-58'
  | 'translate-x-60'
  | 'translate-x-61'
  | 'translate-x-64'
  | 'translate-x-66'
  | 'translate-x-70'
  | 'translate-x-71'
  | 'translate-x-72'
  | 'translate-x-77'
  | 'translate-x-80'
  | 'translate-x-82'
  | 'translate-x-85'
  | 'translate-x-87'
  | 'translate-x-88'
  | 'translate-x-90'
  | 'translate-x-95'
  | 'translate-x-96'
  | 'translate-x-100'
  | 'translate-x-110'
  | 'translate-x-112'
  | 'translate-x-116'
  | 'translate-x-120'
  | 'translate-x-124'
  | 'translate-x-126'
  | 'translate-x-130'
  | 'translate-x-132'
  | 'translate-x-144'
  | 'translate-x-150'
  | 'translate-x-156'
  | 'translate-x-160'
  | 'translate-x-164'
  | 'translate-x-170'
  | 'translate-x-172'
  | 'translate-x-178'
  | 'translate-x-180'
  | 'translate-x-182'
  | 'translate-x-186'
  | 'translate-x-190'
  | 'translate-x-196'
  | 'translate-x-200'
  | 'translate-x-202'
  | 'translate-x-216'
  | 'translate-x-220'
  | 'translate-x-222'
  | 'translate-x-235'
  | 'translate-x-248'
  | 'translate-x-255'
  | 'translate-x-258'
  | 'translate-x-264'
  | 'translate-x-270'
  | 'translate-x-275'
  | 'translate-x-280'
  | 'translate-x-296'
  | 'translate-x-300'
  | 'translate-x-304'
  | 'translate-x-310'
  | 'translate-x-320'
  | 'translate-x-324'
  | 'translate-x-330'
  | 'translate-x-336'
  | 'translate-x-340'
  | 'translate-x-350'
  | 'translate-x-360'
  | 'translate-x-370'
  | 'translate-x-380'
  | 'translate-x-390'
  | 'translate-x-632'
  | 'translate-x-680'
  | 'translate-x-720'
  | 'translate-x-fit'
  | 'translate-x-max'
  | 'translate-x-1/2'
  | 'translate-x-1/3'
  | 'translate-x-2/3'
  | 'translate-x-1/4'
  | 'translate-x-2/4'
  | 'translate-x-3/4'
  | 'translate-x-full'
  | 'translate-y-0'
  | 'translate-y-1'
  | 'translate-y-2'
  | 'translate-y-3'
  | 'translate-y-4'
  | 'translate-y-5'
  | 'translate-y-6'
  | 'translate-y-7'
  | 'translate-y-8'
  | 'translate-y-9'
  | 'translate-y-10'
  | 'translate-y-11'
  | 'translate-y-12'
  | 'translate-y-13'
  | 'translate-y-14'
  | 'translate-y-15'
  | 'translate-y-16'
  | 'translate-y-17'
  | 'translate-y-18'
  | 'translate-y-19'
  | 'translate-y-20'
  | 'translate-y-21'
  | 'translate-y-22'
  | 'translate-y-23'
  | 'translate-y-24'
  | 'translate-y-25'
  | 'translate-y-26'
  | 'translate-y-27'
  | 'translate-y-28'
  | 'translate-y-29'
  | 'translate-y-30'
  | 'translate-y-32'
  | 'translate-y-33'
  | 'translate-y-34'
  | 'translate-y-35'
  | 'translate-y-36'
  | 'translate-y-37'
  | 'translate-y-38'
  | 'translate-y-40'
  | 'translate-y-41'
  | 'translate-y-42'
  | 'translate-y-44'
  | 'translate-y-45'
  | 'translate-y-46'
  | 'translate-y-48'
  | 'translate-y-50'
  | 'translate-y-51'
  | 'translate-y-52'
  | 'translate-y-53'
  | 'translate-y-56'
  | 'translate-y-58'
  | 'translate-y-60'
  | 'translate-y-61'
  | 'translate-y-64'
  | 'translate-y-66'
  | 'translate-y-70'
  | 'translate-y-71'
  | 'translate-y-72'
  | 'translate-y-77'
  | 'translate-y-80'
  | 'translate-y-82'
  | 'translate-y-85'
  | 'translate-y-87'
  | 'translate-y-88'
  | 'translate-y-90'
  | 'translate-y-95'
  | 'translate-y-96'
  | 'translate-y-100'
  | 'translate-y-110'
  | 'translate-y-112'
  | 'translate-y-116'
  | 'translate-y-120'
  | 'translate-y-124'
  | 'translate-y-126'
  | 'translate-y-130'
  | 'translate-y-132'
  | 'translate-y-144'
  | 'translate-y-150'
  | 'translate-y-156'
  | 'translate-y-160'
  | 'translate-y-164'
  | 'translate-y-170'
  | 'translate-y-172'
  | 'translate-y-178'
  | 'translate-y-180'
  | 'translate-y-182'
  | 'translate-y-186'
  | 'translate-y-190'
  | 'translate-y-196'
  | 'translate-y-200'
  | 'translate-y-202'
  | 'translate-y-216'
  | 'translate-y-220'
  | 'translate-y-222'
  | 'translate-y-235'
  | 'translate-y-248'
  | 'translate-y-255'
  | 'translate-y-258'
  | 'translate-y-264'
  | 'translate-y-270'
  | 'translate-y-275'
  | 'translate-y-280'
  | 'translate-y-296'
  | 'translate-y-300'
  | 'translate-y-304'
  | 'translate-y-310'
  | 'translate-y-320'
  | 'translate-y-324'
  | 'translate-y-330'
  | 'translate-y-336'
  | 'translate-y-340'
  | 'translate-y-350'
  | 'translate-y-360'
  | 'translate-y-370'
  | 'translate-y-380'
  | 'translate-y-390'
  | 'translate-y-632'
  | 'translate-y-680'
  | 'translate-y-720'
  | 'translate-y-fit'
  | 'translate-y-max'
  | 'translate-y-1/2'
  | 'translate-y-1/3'
  | 'translate-y-2/3'
  | 'translate-y-1/4'
  | 'translate-y-2/4'
  | 'translate-y-3/4'
  | 'translate-y-full'

export type TSkew =
  | 'skew-x-0'
  | 'skew-x-1'
  | 'skew-x-2'
  | 'skew-x-3'
  | 'skew-x-6'
  | 'skew-x-12'
  | 'skew-y-0'
  | 'skew-y-1'
  | 'skew-y-2'
  | 'skew-y-3'
  | 'skew-y-6'
  | 'skew-y-12'

export type TTransformOrigin =
  | 'origin-center'
  | 'origin-top'
  | 'origin-top-right'
  | 'origin-right'
  | 'origin-bottom-right'
  | 'origin-bottom'
  | 'origin-bottom-left'
  | 'origin-left'
  | 'origin-top-left'

export type TTransforms =
  | THardwareAcceleration
  | TScale
  | TRotate
  | TTranslate
  | TSkew
  | TTransformOrigin

export type TFontSmoothing =
  | 'antialiased'
  | 'subpixel-antialiased'

export type TFontStyle =
  | 'italic'
  | 'non-italic'

export type TFontVariantNumeric =
  | 'normal-nums'
  | 'ordinal'
  | 'slashed-zero'
  | 'lining-nums'
  | 'oldstyle-nums'
  | 'proportional-nums'
  | 'tabular-nums'
  | 'diagonal-fractions'
  | 'stacked-fractions'

export type TListStylePosition =
  | 'list-inside'
  | 'list-outside'

export type TTextAlign =
  | 'text-left'
  | 'text-center'
  | 'text-right'
  | 'text-justify'
  | 'text-start'
  | 'text-end'

export type TTextDecoration =
  | 'underline'
  | 'line-through'
  | 'no-underline'

export type TTextTransform =
  | 'uppercase'
  | 'lowercase'
  | 'capitalize'
  | 'normal-case'

export type TTextOverflow =
  | 'truncate'
  | 'text-ellipsis'
  | 'text-clip'

export type TVerticalAlign =
  | 'align-baseline'
  | 'align-top'
  | 'align-middle'
  | 'align-bottom'
  | 'align-text-top'
  | 'align-text-bottom'
  | 'align-sub'
  | 'align-super'

export type TWhitespace =
  | 'whitespace-normal'
  | 'whitespace-nowrap'
  | 'whitespace-pre'
  | 'whitespace-pre-line'
  | 'whitespace-pre-wrap'

export type TWordBreak =
  | 'break-normal'
  | 'break-words'
  | 'break-all'

export type TTextDecorationStyle =
  | 'decoration-solid'
  | 'decoration-double'
  | 'decoration-dotted'
  | 'decoration-dashed'
  | 'decoration-wavy'

export type TFontFamily =
  | 'font-primary'
  | 'font-secondary'
  | 'font-mono'

export type TFontSize =
  | 'text-tx10'
  | 'text-tx12'
  | 'text-tx14'
  | 'text-tx16'
  | 'text-tx18'
  | 'text-tx20'
  | 'text-tx22'
  | 'text-tx26'
  | 'text-tx30'
  | 'text-tx36'
  | 'text-tx40'
  | 'text-tx48'
  | 'text-tx56'
  | 'text-tx64'

export type TFontWeight =
  | 'font-thin'
  | 'font-extralight'
  | 'font-light'
  | 'font-normal'
  | 'font-medium'
  | 'font-semibold'
  | 'font-bold'
  | 'font-extrabold'
  | 'font-black'

export type TLetterSpacing =
  | 'tracking-tighter'
  | 'tracking-tight'
  | 'tracking-normal'
  | 'tracking-wide'
  | 'tracking-wider'
  | 'tracking-widest'

export type TLineHeight =
  | 'leading-3'
  | 'leading-4'
  | 'leading-5'
  | 'leading-6'
  | 'leading-7'
  | 'leading-8'
  | 'leading-9'
  | 'leading-10'
  | 'leading-none'
  | 'leading-tight'
  | 'leading-snug'
  | 'leading-normal'
  | 'leading-relaxed'
  | 'leading-loose'

export type TListStyleType =
  | 'list-none'
  | 'list-disc'
  | 'list-decimal'

export type TPlaceholderColor =
  | 'placeholder-inherit'
  | 'placeholder-transparent'
  | 'placeholder-current'
  | 'placeholder-black'
  | 'placeholder-white'
  | 'placeholder-brand-primary-1'
  | 'placeholder-brand-primary-2'
  | 'placeholder-brand-primary-3'
  | 'placeholder-brand-primary-4'
  | 'placeholder-brand-primary-5'
  | 'placeholder-brand-secondary-1'
  | 'placeholder-brand-secondary-2'
  | 'placeholder-brand-secondary-3'
  | 'placeholder-brand-secondary-4'
  | 'placeholder-brand-secondary-5'
  | 'placeholder-brand-tertiary-1'
  | 'placeholder-brand-tertiary-2'
  | 'placeholder-brand-tertiary-3'
  | 'placeholder-brand-tertiary-4'
  | 'placeholder-brand-tertiary-5'
  | 'placeholder-gray-1'
  | 'placeholder-gray-2'
  | 'placeholder-gray-3'
  | 'placeholder-gray-4'
  | 'placeholder-gray-5'
  | 'placeholder-gray-6'
  | 'placeholder-gray-7'
  | 'placeholder-gray-8'
  | 'placeholder-gray-9'
  | 'placeholder-social-linkedin'
  | 'placeholder-social-facebook'
  | 'placeholder-social-twitter'
  | 'placeholder-social-instagram'
  | 'placeholder-informa-bar'
  | 'placeholder-danger'
  | 'placeholder-error'

export type TPlaceholderOpacity =
  | 'placeholder-opacity-0'
  | 'placeholder-opacity-5'
  | 'placeholder-opacity-10'
  | 'placeholder-opacity-20'
  | 'placeholder-opacity-25'
  | 'placeholder-opacity-30'
  | 'placeholder-opacity-40'
  | 'placeholder-opacity-50'
  | 'placeholder-opacity-60'
  | 'placeholder-opacity-70'
  | 'placeholder-opacity-75'
  | 'placeholder-opacity-80'
  | 'placeholder-opacity-90'
  | 'placeholder-opacity-95'
  | 'placeholder-opacity-100'

export type TTextColor =
  | 'text-inherit'
  | 'text-transparent'
  | 'text-current'
  | 'text-black'
  | 'text-white'
  | 'text-brand-primary-1'
  | 'text-brand-primary-2'
  | 'text-brand-primary-3'
  | 'text-brand-primary-4'
  | 'text-brand-primary-5'
  | 'text-brand-secondary-1'
  | 'text-brand-secondary-2'
  | 'text-brand-secondary-3'
  | 'text-brand-secondary-4'
  | 'text-brand-secondary-5'
  | 'text-brand-tertiary-1'
  | 'text-brand-tertiary-2'
  | 'text-brand-tertiary-3'
  | 'text-brand-tertiary-4'
  | 'text-brand-tertiary-5'
  | 'text-gray-1'
  | 'text-gray-2'
  | 'text-gray-3'
  | 'text-gray-4'
  | 'text-gray-5'
  | 'text-gray-6'
  | 'text-gray-7'
  | 'text-gray-8'
  | 'text-gray-9'
  | 'text-social-linkedin'
  | 'text-social-facebook'
  | 'text-social-twitter'
  | 'text-social-instagram'
  | 'text-informa-bar'
  | 'text-danger'
  | 'text-error'

export type TTextOpacity =
  | 'text-opacity-0'
  | 'text-opacity-5'
  | 'text-opacity-10'
  | 'text-opacity-20'
  | 'text-opacity-25'
  | 'text-opacity-30'
  | 'text-opacity-40'
  | 'text-opacity-50'
  | 'text-opacity-60'
  | 'text-opacity-70'
  | 'text-opacity-75'
  | 'text-opacity-80'
  | 'text-opacity-90'
  | 'text-opacity-95'
  | 'text-opacity-100'

export type TContent =
  | 'content-none'

export type TTextIndent =
  | 'indent-0'
  | 'indent-1'
  | 'indent-2'
  | 'indent-3'
  | 'indent-4'
  | 'indent-5'
  | 'indent-6'
  | 'indent-7'
  | 'indent-8'
  | 'indent-9'
  | 'indent-10'
  | 'indent-11'
  | 'indent-12'
  | 'indent-13'
  | 'indent-14'
  | 'indent-15'
  | 'indent-16'
  | 'indent-17'
  | 'indent-18'
  | 'indent-19'
  | 'indent-20'
  | 'indent-21'
  | 'indent-22'
  | 'indent-23'
  | 'indent-24'
  | 'indent-25'
  | 'indent-26'
  | 'indent-27'
  | 'indent-28'
  | 'indent-29'
  | 'indent-30'
  | 'indent-32'
  | 'indent-33'
  | 'indent-34'
  | 'indent-35'
  | 'indent-36'
  | 'indent-37'
  | 'indent-38'
  | 'indent-40'
  | 'indent-41'
  | 'indent-42'
  | 'indent-44'
  | 'indent-45'
  | 'indent-46'
  | 'indent-48'
  | 'indent-50'
  | 'indent-51'
  | 'indent-52'
  | 'indent-53'
  | 'indent-56'
  | 'indent-58'
  | 'indent-60'
  | 'indent-61'
  | 'indent-64'
  | 'indent-66'
  | 'indent-70'
  | 'indent-71'
  | 'indent-72'
  | 'indent-77'
  | 'indent-80'
  | 'indent-82'
  | 'indent-85'
  | 'indent-87'
  | 'indent-88'
  | 'indent-90'
  | 'indent-95'
  | 'indent-96'
  | 'indent-100'
  | 'indent-110'
  | 'indent-112'
  | 'indent-116'
  | 'indent-120'
  | 'indent-124'
  | 'indent-126'
  | 'indent-130'
  | 'indent-132'
  | 'indent-144'
  | 'indent-150'
  | 'indent-156'
  | 'indent-160'
  | 'indent-164'
  | 'indent-170'
  | 'indent-172'
  | 'indent-178'
  | 'indent-180'
  | 'indent-182'
  | 'indent-186'
  | 'indent-190'
  | 'indent-196'
  | 'indent-200'
  | 'indent-202'
  | 'indent-216'
  | 'indent-220'
  | 'indent-222'
  | 'indent-235'
  | 'indent-248'
  | 'indent-255'
  | 'indent-258'
  | 'indent-264'
  | 'indent-270'
  | 'indent-275'
  | 'indent-280'
  | 'indent-296'
  | 'indent-300'
  | 'indent-304'
  | 'indent-310'
  | 'indent-320'
  | 'indent-324'
  | 'indent-330'
  | 'indent-336'
  | 'indent-340'
  | 'indent-350'
  | 'indent-360'
  | 'indent-370'
  | 'indent-380'
  | 'indent-390'
  | 'indent-632'
  | 'indent-680'
  | 'indent-720'
  | 'indent-fit'
  | 'indent-max'

export type TTextDecorationColor =
  | 'decoration-inherit'
  | 'decoration-transparent'
  | 'decoration-current'
  | 'decoration-black'
  | 'decoration-white'
  | 'decoration-brand-primary-1'
  | 'decoration-brand-primary-2'
  | 'decoration-brand-primary-3'
  | 'decoration-brand-primary-4'
  | 'decoration-brand-primary-5'
  | 'decoration-brand-secondary-1'
  | 'decoration-brand-secondary-2'
  | 'decoration-brand-secondary-3'
  | 'decoration-brand-secondary-4'
  | 'decoration-brand-secondary-5'
  | 'decoration-brand-tertiary-1'
  | 'decoration-brand-tertiary-2'
  | 'decoration-brand-tertiary-3'
  | 'decoration-brand-tertiary-4'
  | 'decoration-brand-tertiary-5'
  | 'decoration-gray-1'
  | 'decoration-gray-2'
  | 'decoration-gray-3'
  | 'decoration-gray-4'
  | 'decoration-gray-5'
  | 'decoration-gray-6'
  | 'decoration-gray-7'
  | 'decoration-gray-8'
  | 'decoration-gray-9'
  | 'decoration-social-linkedin'
  | 'decoration-social-facebook'
  | 'decoration-social-twitter'
  | 'decoration-social-instagram'
  | 'decoration-informa-bar'
  | 'decoration-danger'
  | 'decoration-error'

export type TTextDecorationThickness =
  | 'decoration-0'
  | 'decoration-1'
  | 'decoration-2'
  | 'decoration-4'
  | 'decoration-8'
  | 'decoration-auto'
  | 'decoration-from-font'

export type TTextUnderlineOffset =
  | 'underline-offset-0'
  | 'underline-offset-1'
  | 'underline-offset-2'
  | 'underline-offset-4'
  | 'underline-offset-8'
  | 'underline-offset-auto'

export type TTypography =
  | TFontSmoothing
  | TFontStyle
  | TFontVariantNumeric
  | TListStylePosition
  | TTextAlign
  | TTextDecoration
  | TTextTransform
  | TTextOverflow
  | TVerticalAlign
  | TWhitespace
  | TWordBreak
  | TTextDecorationStyle
  | TFontFamily
  | TFontSize
  | TFontWeight
  | TLetterSpacing
  | TLineHeight
  | TListStyleType
  | TPlaceholderColor
  | TPlaceholderOpacity
  | TTextColor
  | TTextOpacity
  | TContent
  | TTextIndent
  | TTextDecorationColor
  | TTextDecorationThickness
  | TTextUnderlineOffset


export type TPseudoClassVariants =
  | 'motion-safe:'
  | 'motion-reduce:'
  | 'first:'
  | 'last:'
  | 'odd:'
  | 'even:'
  | 'visited:'
  | 'checked:'
  | 'group:'
  | 'group-hover:'
  | 'group-focus:'
  | 'focus-within:'
  | 'hover:'
  | 'focus:'
  | 'focus-visible:'
  | 'active:'
  | 'disabled:'
  | 'enabled:'
  | 'backdrop:'
  | 'contrast-more:'
  | 'contrast-less:'
  | 'only:'
  | 'first-of-type:'
  | 'last-of-type:'
  | 'only-of-type:'
  | 'target:'
  | 'default:'
  | 'indeterminate:'
  | 'placeholder-shown:'
  | 'autofill:'
  | 'optional:'
  | 'required:'
  | 'valid:'
  | 'invalid:'
  | 'in-range:'
  | 'out-of-range:'
  | 'peer:'
  | 'peer-hover:'
  | 'peer-checked:'
  | 'peer-focus:'
  | 'selection:'
  | 'marker:'
  | 'before:'
  | 'after:'
  | 'portrait:'
  | 'landscape:'
  | 'open:'
  | 'file:'
  | 'placeholder:'
  | 'print:'
  | 'ltr:'
  | 'rtl:'
  | 'md:'
  | 'lg:'
  | 'xl:'
  | 'dark:'

//////////// Utility Function generic type

type TUtilityFunction<T extends string> = (
  ...args: Array<
    | T
    | `!${T}`
    | `${TPseudoClassVariants}${T}`
    | `${TPseudoClassVariants}!${T}`
    | null
    | undefined
    | {[key in T | `${TPseudoClassVariants}${T}` | `!${T}` | `${TPseudoClassVariants}!${T}` | TTailwindString]?: boolean}
    | TTailwindString
  >
) => TTailwindString;

//////////// Accessibility Utility functions

export const screenReaders: TUtilityFunction<TScreenReaders> = classnamesLib as any

//////////// Backgrounds Utility functions

export const backgroundAttachment: TUtilityFunction<TBackgroundAttachment> = classnamesLib as any
export const backgroundClip: TUtilityFunction<TBackgroundClip> = classnamesLib as any
export const backgroundOrigin: TUtilityFunction<TBackgroundOrigin> = classnamesLib as any
export const backgroundRepeat: TUtilityFunction<TBackgroundRepeat> = classnamesLib as any
export const backgroundOpacity: TUtilityFunction<TBackgroundOpacity> = classnamesLib as any
export const backgroundColor: TUtilityFunction<TBackgroundColor> = classnamesLib as any
export const backgroundPosition: TUtilityFunction<TBackgroundPosition> = classnamesLib as any
export const backgroundSize: TUtilityFunction<TBackgroundSize> = classnamesLib as any
export const backgroundImage: TUtilityFunction<TBackgroundImage> = classnamesLib as any
export const gradientColorStops: TUtilityFunction<TGradientColorStops> = classnamesLib as any

//////////// Borders Utility functions

export const borderStyle: TUtilityFunction<TBorderStyle> = classnamesLib as any
export const divideStyle: TUtilityFunction<TDivideStyle> = classnamesLib as any
export const outlineStyle: TUtilityFunction<TOutlineStyle> = classnamesLib as any
export const borderColor: TUtilityFunction<TBorderColor> = classnamesLib as any
export const borderOpacity: TUtilityFunction<TBorderOpacity> = classnamesLib as any
export const borderRadius: TUtilityFunction<TBorderRadius> = classnamesLib as any
export const borderWidth: TUtilityFunction<TBorderWidth> = classnamesLib as any
export const divideColor: TUtilityFunction<TDivideColor> = classnamesLib as any
export const divideOpacity: TUtilityFunction<TDivideOpacity> = classnamesLib as any
export const divideWidth: TUtilityFunction<TDivideWidth> = classnamesLib as any
export const ringColor: TUtilityFunction<TRingColor> = classnamesLib as any
export const ringWidth: TUtilityFunction<TRingWidth> = classnamesLib as any
export const ringOpacity: TUtilityFunction<TRingOpacity> = classnamesLib as any
export const ringOffsetColor: TUtilityFunction<TRingOffsetColor> = classnamesLib as any
export const ringOffsetWidth: TUtilityFunction<TRingOffsetWidth> = classnamesLib as any
export const outlineOffset: TUtilityFunction<TOutlineOffset> = classnamesLib as any
export const outlineWidth: TUtilityFunction<TOutlineWidth> = classnamesLib as any
export const outlineColor: TUtilityFunction<TOutlineColor> = classnamesLib as any

//////////// Tables Utility functions

export const borderCollapse: TUtilityFunction<TBorderCollapse> = classnamesLib as any
export const tableLayout: TUtilityFunction<TTableLayout> = classnamesLib as any
export const borderSpacing: TUtilityFunction<TBorderSpacing> = classnamesLib as any

//////////// Effects Utility functions

export const mixBlendMode: TUtilityFunction<TMixBlendMode> = classnamesLib as any
export const backgroundBlendMode: TUtilityFunction<TBackgroundBlendMode> = classnamesLib as any
export const boxShadow: TUtilityFunction<TBoxShadow> = classnamesLib as any
export const boxShadowColor: TUtilityFunction<TBoxShadowColor> = classnamesLib as any
export const opacity: TUtilityFunction<TOpacity> = classnamesLib as any

//////////// TransitionsAndAnimations Utility functions

export const transitionProperty: TUtilityFunction<TTransitionProperty> = classnamesLib as any
export const transitionDuration: TUtilityFunction<TTransitionDuration> = classnamesLib as any
export const transitionTimingFunction: TUtilityFunction<TTransitionTimingFunction> = classnamesLib as any
export const transitionDelay: TUtilityFunction<TTransitionDelay> = classnamesLib as any
export const animation: TUtilityFunction<TAnimation> = classnamesLib as any

//////////// Filters Utility functions

export const filter: TUtilityFunction<TFilter> = classnamesLib as any
export const backdropFilter: TUtilityFunction<TBackdropFilter> = classnamesLib as any
export const blur: TUtilityFunction<TBlur> = classnamesLib as any
export const brightness: TUtilityFunction<TBrightness> = classnamesLib as any
export const contrast: TUtilityFunction<TContrast> = classnamesLib as any
export const dropShadow: TUtilityFunction<TDropShadow> = classnamesLib as any
export const grayscale: TUtilityFunction<TGrayscale> = classnamesLib as any
export const hueRotate: TUtilityFunction<THueRotate> = classnamesLib as any
export const invert: TUtilityFunction<TInvert> = classnamesLib as any
export const saturate: TUtilityFunction<TSaturate> = classnamesLib as any
export const sepia: TUtilityFunction<TSepia> = classnamesLib as any
export const backdropBlur: TUtilityFunction<TBackdropBlur> = classnamesLib as any
export const backdropBrightness: TUtilityFunction<TBackdropBrightness> = classnamesLib as any
export const backdropContrast: TUtilityFunction<TBackdropContrast> = classnamesLib as any
export const backdropGrayscale: TUtilityFunction<TBackdropGrayscale> = classnamesLib as any
export const backdropHueRotate: TUtilityFunction<TBackdropHueRotate> = classnamesLib as any
export const backdropInvert: TUtilityFunction<TBackdropInvert> = classnamesLib as any
export const backdropOpacity: TUtilityFunction<TBackdropOpacity> = classnamesLib as any
export const backdropSaturate: TUtilityFunction<TBackdropSaturate> = classnamesLib as any
export const backdropSepia: TUtilityFunction<TBackdropSepia> = classnamesLib as any

//////////// FlexBox Utility functions

export const flexDirection: TUtilityFunction<TFlexDirection> = classnamesLib as any
export const flexWrap: TUtilityFunction<TFlexWrap> = classnamesLib as any
export const alignItems: TUtilityFunction<TAlignItems> = classnamesLib as any
export const alignContent: TUtilityFunction<TAlignContent> = classnamesLib as any
export const alignSelf: TUtilityFunction<TAlignSelf> = classnamesLib as any
export const placeContent: TUtilityFunction<TPlaceContent> = classnamesLib as any
export const placeItems: TUtilityFunction<TPlaceItems> = classnamesLib as any
export const placeSelf: TUtilityFunction<TPlaceSelf> = classnamesLib as any
export const justifyContent: TUtilityFunction<TJustifyContent> = classnamesLib as any
export const justifyItems: TUtilityFunction<TJustifyItems> = classnamesLib as any
export const justifySelf: TUtilityFunction<TJustifySelf> = classnamesLib as any
export const flex: TUtilityFunction<TFlex> = classnamesLib as any
export const flexBasis: TUtilityFunction<TFlexBasis> = classnamesLib as any
export const flexGrow: TUtilityFunction<TFlexGrow> = classnamesLib as any
export const flexShrink: TUtilityFunction<TFlexShrink> = classnamesLib as any
export const order: TUtilityFunction<TOrder> = classnamesLib as any

//////////// Grid Utility functions

export const gridAutoFlow: TUtilityFunction<TGridAutoFlow> = classnamesLib as any
export const gridTemplateColumns: TUtilityFunction<TGridTemplateColumns> = classnamesLib as any
export const gridAutoColumns: TUtilityFunction<TGridAutoColumns> = classnamesLib as any
export const gridColumn: TUtilityFunction<TGridColumn> = classnamesLib as any
export const gridColumnStart: TUtilityFunction<TGridColumnStart> = classnamesLib as any
export const gridColumnEnd: TUtilityFunction<TGridColumnEnd> = classnamesLib as any
export const gridTemplateRows: TUtilityFunction<TGridTemplateRows> = classnamesLib as any
export const gridAutoRows: TUtilityFunction<TGridAutoRows> = classnamesLib as any
export const gridRow: TUtilityFunction<TGridRow> = classnamesLib as any
export const gridRowStart: TUtilityFunction<TGridRowStart> = classnamesLib as any
export const gridRowEnd: TUtilityFunction<TGridRowEnd> = classnamesLib as any
export const gap: TUtilityFunction<TGap> = classnamesLib as any

//////////// Grouping Utility functions

export const group: TUtilityFunction<TGroup> = classnamesLib as any
export const peer: TUtilityFunction<TPeer> = classnamesLib as any

//////////// Spacing Utility functions

export const padding: TUtilityFunction<TPadding> = classnamesLib as any
export const margin: TUtilityFunction<TMargin> = classnamesLib as any
export const space: TUtilityFunction<TSpace> = classnamesLib as any

//////////// Interactivity Utility functions

export const appearance: TUtilityFunction<TAppearance> = classnamesLib as any
export const pointerEvents: TUtilityFunction<TPointerEvents> = classnamesLib as any
export const resize: TUtilityFunction<TResize> = classnamesLib as any
export const userSelect: TUtilityFunction<TUserSelect> = classnamesLib as any
export const scrollSnap: TUtilityFunction<TScrollSnap> = classnamesLib as any
export const scrollBehavior: TUtilityFunction<TScrollBehavior> = classnamesLib as any
export const touchAction: TUtilityFunction<TTouchAction> = classnamesLib as any
export const cursor: TUtilityFunction<TCursor> = classnamesLib as any
export const caretColor: TUtilityFunction<TCaretColor> = classnamesLib as any
export const willChange: TUtilityFunction<TWillChange> = classnamesLib as any
export const accentColor: TUtilityFunction<TAccentColor> = classnamesLib as any
export const scrollPadding: TUtilityFunction<TScrollPadding> = classnamesLib as any
export const scrollMargin: TUtilityFunction<TScrollMargin> = classnamesLib as any

//////////// Layout Utility functions

export const display: TUtilityFunction<TDisplay> = classnamesLib as any
export const boxDecorationBreak: TUtilityFunction<TBoxDecorationBreak> = classnamesLib as any
export const boxSizing: TUtilityFunction<TBoxSizing> = classnamesLib as any
export const container: TUtilityFunction<TContainer> = classnamesLib as any
export const float: TUtilityFunction<TFloat> = classnamesLib as any
export const clear: TUtilityFunction<TClear> = classnamesLib as any
export const isolation: TUtilityFunction<TIsolation> = classnamesLib as any
export const objectFit: TUtilityFunction<TObjectFit> = classnamesLib as any
export const overflow: TUtilityFunction<TOverflow> = classnamesLib as any
export const overscrollBehavior: TUtilityFunction<TOverscrollBehavior> = classnamesLib as any
export const position: TUtilityFunction<TPosition> = classnamesLib as any
export const visibility: TUtilityFunction<TVisibility> = classnamesLib as any
export const breakBefore: TUtilityFunction<TBreakBefore> = classnamesLib as any
export const breakInside: TUtilityFunction<TBreakInside> = classnamesLib as any
export const breakAfter: TUtilityFunction<TBreakAfter> = classnamesLib as any
export const objectPosition: TUtilityFunction<TObjectPosition> = classnamesLib as any
export const inset: TUtilityFunction<TInset> = classnamesLib as any
export const zIndex: TUtilityFunction<TZIndex> = classnamesLib as any
export const aspectRatio: TUtilityFunction<TAspectRatio> = classnamesLib as any
export const columns: TUtilityFunction<TColumns> = classnamesLib as any

//////////// Sizing Utility functions

export const width: TUtilityFunction<TWidth> = classnamesLib as any
export const minWidth: TUtilityFunction<TMinWidth> = classnamesLib as any
export const maxWidth: TUtilityFunction<TMaxWidth> = classnamesLib as any
export const height: TUtilityFunction<THeight> = classnamesLib as any
export const minHeight: TUtilityFunction<TMinHeight> = classnamesLib as any
export const maxHeight: TUtilityFunction<TMaxHeight> = classnamesLib as any

//////////// SVG Utility functions

export const fill: TUtilityFunction<TFill> = classnamesLib as any
export const stroke: TUtilityFunction<TStroke> = classnamesLib as any
export const strokeWidth: TUtilityFunction<TStrokeWidth> = classnamesLib as any

//////////// Transforms Utility functions

export const hardwareAcceleration: TUtilityFunction<THardwareAcceleration> = classnamesLib as any
export const scale: TUtilityFunction<TScale> = classnamesLib as any
export const rotate: TUtilityFunction<TRotate> = classnamesLib as any
export const translate: TUtilityFunction<TTranslate> = classnamesLib as any
export const skew: TUtilityFunction<TSkew> = classnamesLib as any
export const transformOrigin: TUtilityFunction<TTransformOrigin> = classnamesLib as any

//////////// Typography Utility functions

export const fontSmoothing: TUtilityFunction<TFontSmoothing> = classnamesLib as any
export const fontStyle: TUtilityFunction<TFontStyle> = classnamesLib as any
export const fontVariantNumeric: TUtilityFunction<TFontVariantNumeric> = classnamesLib as any
export const listStylePosition: TUtilityFunction<TListStylePosition> = classnamesLib as any
export const textAlign: TUtilityFunction<TTextAlign> = classnamesLib as any
export const textDecoration: TUtilityFunction<TTextDecoration> = classnamesLib as any
export const textTransform: TUtilityFunction<TTextTransform> = classnamesLib as any
export const textOverflow: TUtilityFunction<TTextOverflow> = classnamesLib as any
export const verticalAlign: TUtilityFunction<TVerticalAlign> = classnamesLib as any
export const whitespace: TUtilityFunction<TWhitespace> = classnamesLib as any
export const wordBreak: TUtilityFunction<TWordBreak> = classnamesLib as any
export const textDecorationStyle: TUtilityFunction<TTextDecorationStyle> = classnamesLib as any
export const fontFamily: TUtilityFunction<TFontFamily> = classnamesLib as any
export const fontSize: TUtilityFunction<TFontSize> = classnamesLib as any
export const fontWeight: TUtilityFunction<TFontWeight> = classnamesLib as any
export const letterSpacing: TUtilityFunction<TLetterSpacing> = classnamesLib as any
export const lineHeight: TUtilityFunction<TLineHeight> = classnamesLib as any
export const listStyleType: TUtilityFunction<TListStyleType> = classnamesLib as any
export const placeholderColor: TUtilityFunction<TPlaceholderColor> = classnamesLib as any
export const placeholderOpacity: TUtilityFunction<TPlaceholderOpacity> = classnamesLib as any
export const textColor: TUtilityFunction<TTextColor> = classnamesLib as any
export const textOpacity: TUtilityFunction<TTextOpacity> = classnamesLib as any
export const content: TUtilityFunction<TContent> = classnamesLib as any
export const textIndent: TUtilityFunction<TTextIndent> = classnamesLib as any
export const textDecorationColor: TUtilityFunction<TTextDecorationColor> = classnamesLib as any
export const textDecorationThickness: TUtilityFunction<TTextDecorationThickness> = classnamesLib as any
export const textUnderlineOffset: TUtilityFunction<TTextUnderlineOffset> = classnamesLib as any

//////////// Accessibility Utility functions

export const accessibility: TUtilityFunction<TAccessibility> = classnamesLib as any


//////////// Backgrounds Utility functions

export const backgrounds: TUtilityFunction<TBackgrounds> = classnamesLib as any


//////////// Borders Utility functions

export const borders: TUtilityFunction<TBorders> = classnamesLib as any


//////////// Tables Utility functions

export const tables: TUtilityFunction<TTables> = classnamesLib as any


//////////// Effects Utility functions

export const effects: TUtilityFunction<TEffects> = classnamesLib as any


//////////// TransitionsAndAnimations Utility functions

export const transitionsAndAnimations: TUtilityFunction<TTransitionsAndAnimations> = classnamesLib as any


//////////// Filters Utility functions

export const filters: TUtilityFunction<TFilters> = classnamesLib as any


//////////// FlexBox Utility functions

export const flexBox: TUtilityFunction<TFlexBox> = classnamesLib as any


//////////// Grid Utility functions

export const grid: TUtilityFunction<TGrid> = classnamesLib as any


//////////// Grouping Utility functions

export const grouping: TUtilityFunction<TGrouping> = classnamesLib as any


//////////// Spacing Utility functions

export const spacing: TUtilityFunction<TSpacing> = classnamesLib as any


//////////// Interactivity Utility functions

export const interactivity: TUtilityFunction<TInteractivity> = classnamesLib as any


//////////// Layout Utility functions

export const layout: TUtilityFunction<TLayout> = classnamesLib as any


//////////// Sizing Utility functions

export const sizing: TUtilityFunction<TSizing> = classnamesLib as any


//////////// SVG Utility functions

export const svg: TUtilityFunction<TSVG> = classnamesLib as any


//////////// Transforms Utility functions

export const transforms: TUtilityFunction<TTransforms> = classnamesLib as any


//////////// Typography Utility functions

export const typography: TUtilityFunction<TTypography> = classnamesLib as any

export const TW = {
  screenReaders,
  backgroundAttachment,
  backgroundClip,
  backgroundOrigin,
  backgroundRepeat,
  backgroundOpacity,
  backgroundColor,
  backgroundPosition,
  backgroundSize,
  backgroundImage,
  gradientColorStops,
  borderStyle,
  divideStyle,
  outlineStyle,
  borderColor,
  borderOpacity,
  borderRadius,
  borderWidth,
  divideColor,
  divideOpacity,
  divideWidth,
  ringColor,
  ringWidth,
  ringOpacity,
  ringOffsetColor,
  ringOffsetWidth,
  outlineOffset,
  outlineWidth,
  outlineColor,
  borderCollapse,
  tableLayout,
  borderSpacing,
  mixBlendMode,
  backgroundBlendMode,
  boxShadow,
  boxShadowColor,
  opacity,
  transitionProperty,
  transitionDuration,
  transitionTimingFunction,
  transitionDelay,
  animation,
  filter,
  backdropFilter,
  blur,
  brightness,
  contrast,
  dropShadow,
  grayscale,
  hueRotate,
  invert,
  saturate,
  sepia,
  backdropBlur,
  backdropBrightness,
  backdropContrast,
  backdropGrayscale,
  backdropHueRotate,
  backdropInvert,
  backdropOpacity,
  backdropSaturate,
  backdropSepia,
  flexDirection,
  flexWrap,
  alignItems,
  alignContent,
  alignSelf,
  placeContent,
  placeItems,
  placeSelf,
  justifyContent,
  justifyItems,
  justifySelf,
  flex,
  flexBasis,
  flexGrow,
  flexShrink,
  order,
  gridAutoFlow,
  gridTemplateColumns,
  gridAutoColumns,
  gridColumn,
  gridColumnStart,
  gridColumnEnd,
  gridTemplateRows,
  gridAutoRows,
  gridRow,
  gridRowStart,
  gridRowEnd,
  gap,
  group,
  peer,
  padding,
  margin,
  space,
  appearance,
  pointerEvents,
  resize,
  userSelect,
  scrollSnap,
  scrollBehavior,
  touchAction,
  cursor,
  caretColor,
  willChange,
  accentColor,
  scrollPadding,
  scrollMargin,
  display,
  boxDecorationBreak,
  boxSizing,
  container,
  float,
  clear,
  isolation,
  objectFit,
  overflow,
  overscrollBehavior,
  position,
  visibility,
  breakBefore,
  breakInside,
  breakAfter,
  objectPosition,
  inset,
  zIndex,
  aspectRatio,
  columns,
  width,
  minWidth,
  maxWidth,
  height,
  minHeight,
  maxHeight,
  fill,
  stroke,
  strokeWidth,
  hardwareAcceleration,
  scale,
  rotate,
  translate,
  skew,
  transformOrigin,
  fontSmoothing,
  fontStyle,
  fontVariantNumeric,
  listStylePosition,
  textAlign,
  textDecoration,
  textTransform,
  textOverflow,
  verticalAlign,
  whitespace,
  wordBreak,
  textDecorationStyle,
  fontFamily,
  fontSize,
  fontWeight,
  letterSpacing,
  lineHeight,
  listStyleType,
  placeholderColor,
  placeholderOpacity,
  textColor,
  textOpacity,
  content,
  textIndent,
  textDecorationColor,
  textDecorationThickness,
  textUnderlineOffset
}

export const CN = {
  accessibility,
  backgrounds,
  borders,
  tables,
  effects,
  transitionsAndAnimations,
  filters,
  flexBox,
  grid,
  grouping,
  spacing,
  interactivity,
  layout,
  sizing,
  svg,
  transforms,
  typography
}

export const mainCategoriesUtilityFunctions = CN;

export const subCategoriesUtilityFunctions = TW;

export type TTailwindString = "TAILWIND_STRING"

export type TArg =
| null
| undefined
| 'dark'
| TTailwindString
| TCustomClassesFromExternalFile

export type TTailwind = (...args: TArg[]) => TTailwindString

export const classnames: TTailwind = classnamesLib as any

export default classnames