import camelCase from 'lodash/camelCase'
import { FC } from 'react'

import { Bem } from '@scm/ui-core'

import { TypographyProps } from './type'

const typography = Bem('Typography')

const elementMapper: Record<TypographyProps['variant'], string> = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  h6: 'h6',
  'body-small': 'p',
  'body-normal': 'p',
  deck: 'span',
}

export const Typography: FC<TypographyProps> = ({
  variant,
  className,
  primary,
  children,
  automationId,
}) => {
  const Element = elementMapper[variant]

  return (
    // @ts-expect-error
    <Element
      data-component="typography"
      data-testid={
        automationId === undefined ? 'typography_element' : automationId
      }
      className={typography(
        {
          variant: camelCase(variant),
          font: primary ? 'primary' : 'secondary',
        },
        [className],
      )}
    >
      {children}
    </Element>
  )
}
