import type { TArg, TTailwindString } from './theme.generated'
import { classnames as twBase } from './theme.generated'

export { default as tokens } from './tokens.json'
export * from './useScreen'

export type ClassName = string

export const tw: (...args: Array<TArg | string>) => TTailwindString =
  twBase as any
