import { FC } from 'react'

import { Ads } from '@scm/ads'
import { Bem } from '@scm/ui-core'
import { ClassName, tw } from '@scm/ui-tailwind'
import { SubscribeBannerProps } from '@scm/ui-types'

const subscribeBanner = Bem('SubscribeBanner')

export const SubscribeBanner: FC<SubscribeBannerProps> = ({
  title,
  message,
  children,
  className,
  variant,
  mode,
  automationId,
}) => {
  const wallpaperStatus = Ads.hooks.useIsAdRender('wallpaper_v')
  const isWallPaperActive = wallpaperStatus === 'show'

  return (
    <div
      className={tw(
        subscribeBanner({ mode, variant, isWallPaperActive }) as ClassName,
        className,
      )}
    >
      <div className={subscribeBanner('Wrapper')}>
        <div
          className={subscribeBanner('Title')}
          data-testid={
            automationId === undefined ? 'subscribeBanner_title' : automationId
          }
        >
          {title}
        </div>

        <div
          className={subscribeBanner('Message')}
          data-testid={
            automationId === undefined
              ? 'subscribeBanner_message'
              : automationId
          }
        >
          {message}
        </div>

        <div className={subscribeBanner('Button')}>{children}</div>
      </div>
    </div>
  )
}
