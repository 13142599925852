import { FC } from 'react'

import { Bem } from '@scm/ui-core'
import { SubscribeBannerProps } from '@scm/ui-types'

const subscribeBannerTopicPage = Bem('SubscribeBannerTopicPage')

export const SubscribeBannerTopicPage: FC<SubscribeBannerProps> = ({
  title,
  message,
  children,
  className,
  variant,
  mode,
  automationId,
}) => {
  return (
    <div className={subscribeBannerTopicPage({ mode, variant }, [className])}>
      <div className={subscribeBannerTopicPage('Content')}>
        <div
          className={subscribeBannerTopicPage('Title')}
          data-testid={
            automationId === undefined
              ? 'subscribeBannerTopicPage_title'
              : automationId
          }
        >
          {title}
        </div>

        <div
          className={subscribeBannerTopicPage('Message')}
          data-testid={
            automationId === undefined
              ? 'subscribeBannerTopicPage_message'
              : automationId
          }
        >
          {message}
        </div>

        <div
          className={subscribeBannerTopicPage('Button')}
          data-testid="subscribe-banner-topic-page-button"
        >
          {children}
        </div>
      </div>
    </div>
  )
}
