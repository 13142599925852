import { FC } from 'react'

import { Icon } from '@scm/ui-component-icon'
import { Bem } from '@scm/ui-core'
import { tokens, useScreenPixel } from '@scm/ui-tailwind'

import { NewsletterUI } from './newsletter'
import { ModalNewsletterProps } from './type'

const newsletterModal = Bem('SignupNewsletterModal')

export const ModalNewsletter: FC<ModalNewsletterProps> = ({
  onClose,
  skipOption,
  newsletterId,
  subBrandId,
}) => {
  const convertToNumber = (pxValue: string) =>
    parseInt(pxValue.replace('px', ''), 10)

  const getStyles = (screenWidth: number) => {
    if (screenWidth >= convertToNumber(tokens.screens.xl)) {
      // -50% based on grid scale
      return {
        width: 'fit-content',
        maxHeight: '90%',
        maxWidth: '80%',
        transform: 'translate(-50%,-50%)',
      }
    }
  }

  const screenWidth = useScreenPixel()

  return (
    <div className={newsletterModal()} data-testid="newsletter-modal">
      <div
        style={getStyles(screenWidth)}
        className={newsletterModal('Wrapper')}
      >
        <div
          className={newsletterModal('Header')}
          role="presentation"
          onClick={onClose}
        >
          <Icon icon="Close" className={newsletterModal('Close')} />
        </div>

        <NewsletterUI
          onClose={onClose}
          newsletterId={newsletterId}
          subBrandId={subBrandId}
        />

        {skipOption && (
          <div className={newsletterModal('Footer')}>
            <button className={newsletterModal('Button')} onClick={onClose}>
              No, thank you
            </button>
          </div>
        )}
      </div>
    </div>
  )
}
