import {
  FC,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'

interface Screens {
  md: number
  lg: number
  xl: number
}

// @ts-expect-error
const Context = createContext<Screens>({})

const convertToNumber = (pxValue: string) =>
  parseInt(pxValue.replace('px', ''), 10)

interface ScreenProviderProps {
  screens: {
    md: string
    lg: string
    xl: string
  }
  children?: any
}

export const ScreenProvider: FC<ScreenProviderProps> = props => {
  const screens = useMemo(
    () => ({
      md: convertToNumber(props.screens.md),
      lg: convertToNumber(props.screens.lg),
      xl: convertToNumber(props.screens.xl),
    }),
    [props.screens],
  )

  if (!screens.md || !screens.lg || !screens.xl) {
    throw new Error('Cant parse screen tokens')
  }

  return <Context.Provider value={screens}>{props.children}</Context.Provider>
}

export type Screen = null | 'md' | 'lg' | 'xl'

const getCorrectScreen = (screens: Screens) => {
  if (typeof window === 'undefined') return null

  switch (true) {
    case window.innerWidth >= screens.xl:
      return 'xl'
    case window.innerWidth >= screens.lg:
      return 'lg'
    case window.innerWidth >= screens.md:
      return 'md'
    default:
      return null
  }
}

export const useScreen = (): Screen => {
  const screens = useContext(Context)
  const [screen, setScreen] = useState<Screen>(null)

  useEffect(() => {
    const handleResize = () => setScreen(getCorrectScreen(screens))

    if (typeof window !== 'undefined') {
      window.addEventListener('resize', handleResize)
      setScreen(getCorrectScreen(screens))
    }

    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('resize', handleResize)
      }
    }
  }, [screens])

  return screen
}

export const useScreenPixel = (): number => {
  const [screenPixel, setScreenPixel] = useState(0)

  useEffect(() => {
    const handleResize = () => setScreenPixel(window.innerWidth)

    if (typeof window !== 'undefined') {
      window.addEventListener('resize', handleResize)
      setScreenPixel(window.innerWidth)
    }

    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('resize', handleResize)
      }
    }
  }, [])

  return screenPixel
}
